import React, {useState, useEffect, useRef} from 'react';
import {
  HStack,
  FormControl,
  FormLabel,
  Input,
  InputRightElement,
  InputGroup,
  Icon,
  Select,
  Tooltip,
  Text,
  Box,
  Button,
} from '@chakra-ui/react';
import {FiSearch} from 'react-icons/fi';
import {equals} from 'ramda';
import {useDebounce} from 'utils/react';
import {sToTime} from 'utils/time';
import effs from 'modules/reportages/effects';
import {parseNullableNumber, formatNullableNumber} from 'utils/fields';
import {Link} from 'react-router-dom';

const SitesTabFilters = ({
  query = {},
  updateQuery = () => {},
  siteEntries,
  sites,
  siteReportage,
  siteReportageLoading,
}) => {
  const querySearch = query.name || '';
  const [search, setSearch] = useState(querySearch);
  const debouncedSearch = useDebounce(search, 500);
  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }

    if (!equals(debouncedSearch, querySearch)) {
      updateQuery({name: debouncedSearch});
    }
  }, [debouncedSearch]);

  useEffect(() => {
    if (querySearch !== search) {
      setSearch(querySearch);
    }
  }, [querySearch]);

  const totalHours = siteEntries
    .map((item) => item.totalTime.total)
    .reduce((prev, curr) => prev + curr, 0);

  return (
    <HStack bg="gray.50" p={4} marginTop={4} justifyContent="space-between">
      <HStack display="flex" flexDirection="row" alignItems="center">
        <FormControl w="auto">
          <FormLabel>Vapaahaku</FormLabel>
          <InputGroup>
            <Input
              type="text"
              placeholder="Nimi, osoite..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <InputRightElement pointerEvents="none" color="gray.600">
              <Icon as={FiSearch} />
            </InputRightElement>
          </InputGroup>
        </FormControl>
        <FormControl w="auto">
          <FormLabel>Työmaa</FormLabel>
          <Select
            bgColor="white"
            fontSize="sm"
            borderRadius="0.25rem"
            size="lg"
            placeholder="Valitse..."
            variant="withLabel"
            value={formatNullableNumber(query.siteId)}
            onChange={(e) => updateQuery({siteId: parseNullableNumber(e.target.value)})}
          >
            {sites.map((s) => (
              <option key={s.id} value={s.id}>
                {s.name}
              </option>
            ))}
          </Select>
        </FormControl>
      </HStack>
      <Box display="flex" flexDirection="column" alignItems="flex-end">
        <Box display="flex" flexDirection="row" alignItems="flex-end">
          {!siteReportage ? (
            <Tooltip hasArrow label="Luo raportin valitulta aikaväliltä" bg="blue.500">
              <Button
                colorScheme="blue"
                variant="solid"
                disabled={!siteEntries.length}
                isLoading={siteReportageLoading}
                mr="6"
                onClick={() => effs.generateReportageModalOpen(true)}
              >
                Luo raportti
              </Button>
            </Tooltip>
          ) : (
            <Link to={{pathname: siteReportage}} download target="_top">
              <Button variant="primary" mr="6" isLoading={siteReportageLoading}>
                <span>Lataa raportti</span>
              </Button>
            </Link>
          )}
          <Box display="flex" flexDirection="column" alignItems="flex-end">
            <Text fontSize="xs" fontWeight="bold" opacity={1} color="gray.500">
              Kirjatut tunnit
            </Text>
            <Text fontSize="sm" fontWeight="bold">
              {sToTime(totalHours)}
            </Text>
          </Box>
        </Box>
      </Box>
    </HStack>
  );
};

export default SitesTabFilters;
