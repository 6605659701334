const colors = {
  transparent: 'transparent',
  current: 'currentColor',
  black: '#000',
  white: '#fff',

  whiteAlpha: {
    50: 'rgba(255, 255, 255, 0.04)',
    100: 'rgba(255, 255, 255, 0.06)',
    200: 'rgba(255, 255, 255, 0.08)',
    300: 'rgba(255, 255, 255, 0.16)',
    400: 'rgba(255, 255, 255, 0.24)',
    500: 'rgba(255, 255, 255, 0.36)',
    600: 'rgba(255, 255, 255, 0.48)',
    700: 'rgba(255, 255, 255, 0.64)',
    800: 'rgba(255, 255, 255, 0.80)',
    900: 'rgba(255, 255, 255, 0.92)',
  },

  blackAlpha: {
    50: 'rgba(0, 0, 0, 0.04)',
    100: 'rgba(0, 0, 0, 0.06)',
    200: 'rgba(0, 0, 0, 0.08)',
    300: 'rgba(0, 0, 0, 0.16)',
    400: 'rgba(0, 0, 0, 0.24)',
    500: 'rgba(0, 0, 0, 0.36)',
    600: 'rgba(0, 0, 0, 0.48)',
    700: 'rgba(0, 0, 0, 0.64)',
    800: 'rgba(0, 0, 0, 0.80)',
    900: 'rgba(0, 0, 0, 0.92)',
  },

  blue: {
    100: '#e4ecfb',
    200: '#CEDCF8',
    300: '#83a7ed',
    400: '#5788e6',
    500: '#2a69df',
    600: '#1c53bb',
    700: '#153f8e',
    800: '#0f2c62',
    900: '#081835',
  },

  gray: {
    50: '#f7f8f8',
    100: '#eef0f1',
    200: '#cdd3d6',
    300: '#b0babf',
    400: '#94a1a8',
    500: '#788891',
    600: '#606e76',
    700: '#49545a',
    800: '#323a3e',
    900: '#1c2022',
  },
};

export default colors;
