import namespace from './namespace';
import {scopedStore} from 'utils/redux';
import {emptyPagination} from 'constants/pagination';
import {startOfMonth, endOfMonth} from 'date-fns';
import {includes, reject, append, equals} from 'ramda';

export const initialState = {
  query: {
    page: 1,
    sort: 'name',
    'filter[name]': '',
    include: 'subContractors,users',
  },
  contractSitesQuery: {
    page: 1,
    sort: 'name',
    'filter[contractsitename]': '',
  },
  processing: false,
  loading: false,
  contractSitesLoading: false,
  siteModalOpen: false,
  sites: [],
  contractSites: [],
  pagination: emptyPagination,
  contractSitesPagination: emptyPagination,
  contractNoticeModalOpen: false,
  siteSelection: [],
  contractNoticeRows: [],
  contractNoticeLoading: false,
  contractNoticeDateRange: {
    startDate: startOfMonth(new Date()),
    endDate: endOfMonth(new Date()),
  },
  contractNoticeUrl: null,
  employeeNoticeModalOpen: false,
  employeeSelection: [],
  employeeNoticeRows: [],
  employeeNoticeLoading: false,
  employeeNoticeQuery: {
    startDate: startOfMonth(new Date()),
    endDate: endOfMonth(new Date()),
  },
  employeeNoticeUrl: null,
};

export const cases = {
  updateQuery: (st, all, payload) => {
    return {
      ...st,
      query: {...st.query, ...payload},
      loading: true,
    };
  },

  updateContractSitesQuery: (st, all, payload) => {
    return {
      ...st,
      contractSitesQuery: {...st.contractSitesQuery, ...payload},
      contractSitesLoading: true,
    };
  },

  setSites: (st, all, payload) => {
    return {
      ...st,
      sites: payload.data,
      pagination: payload.pagination,
      loading: false,
    };
  },

  setContractSites: (st, all, payload) => {
    return {
      ...st,
      contractSites: payload.data,
      contractSitesPagination: payload.contractSitesPagination,
      contractSitesLoading: false,
    };
  },

  toggleSiteModal: (st) => {
    return {...st, siteModalOpen: !st.siteModalOpen};
  },

  setProcessing: (st, all, processing) => {
    return {...st, processing};
  },

  setLoading: (st, all, loading) => {
    return {...st, loading};
  },

  setContractSitesLoading: (st, all, contractSitesLoading) => {
    return {...st, contractSitesLoading};
  },

  setEmployeeNoticeUrl: (st, all, url) => {
    return {...st, employeeNoticeUrl: url};
  },

  openEmployeeNoticeModal: (st, all) => {
    return {...st, employeeNoticeModalOpen: true, employeeNoticeLoading: true};
  },

  closeEmployeeNoticeModal: (st, all) => {
    return {
      ...st,
      employeeNoticeModalOpen: false,
      employeeNoticeLoading: false,
      employeeSelection: initialState.employeeSelection,
      employeeNoticeRows: initialState.employeeNoticeRows,
      employeeNoticeQuery: initialState.employeeNoticeQuery,
      employeeNoticeUrl: initialState.employeeNoticeUrl,
    };
  },

  setEmployeeNoticeRows: (st, all, employeeNoticeRows) => {
    return {...st, employeeNoticeRows, employeeNoticeLoading: false};
  },

  selectEmployees: (st, all, employees) => {
    let selection = st.employeeSelection;

    employees.forEach((e) => {
      if (includes(e.id, selection)) {
        selection = !e.selected ? reject(equals(e.id), selection) : selection;
      } else {
        selection = e.selected ? append(e.id, selection) : selection;
      }
    });

    return {...st, employeeSelection: selection};
  },

  clearEmployeeSelection: (st) => {
    return {...st, employeeSelection: initialState.employeeSelection};
  },

  updateEmployeeNoticeQuery: (st, all, payload) => {
    return {
      ...st,
      employeeNoticeQuery: {...st.employeeNoticeQuery, ...payload},
      employeeNoticeLoading: true,
    };
  },

  selectSubcontractors: (st, all, subContractors) => {
    let selection = st.subContractorSelection;

    subContractors.forEach((s) => {
      if (includes(s.id, selection)) {
        selection = !s.selected ? reject(equals(s.id), selection) : selection;
      } else {
        selection = s.selected ? append(s.id, selection) : selection;
      }
    });

    return {...st, subContractorSelection: selection};
  },

  setContractNoticeRows: (st, all, contractNoticeRows) => {
    return {...st, contractNoticeRows, contractNoticeLoading: false};
  },

  clearSiteSelection: (st) => {
    return {...st, siteSelection: []};
  },

  updateContractNoticeDateRange: (st, all, payload) => {
    return {
      ...st,
      contractNoticeDateRange: {...st.contractNoticeDateRange, ...payload},
      contractNoticeLoading: true,
    };
  },

  selectSites: (st, all, sites) => {
    let selection = st.siteSelection;

    sites.forEach((e) => {
      if (includes(e.id, selection)) {
        selection = !e.selected ? reject(equals(e.id), selection) : selection;
      } else {
        selection = e.selected ? append(e.id, selection) : selection;
      }
    });

    return {...st, siteSelection: selection};
  },

  openContractNoticeModal: (st, all) => {
    return {...st, contractNoticeModalOpen: true, contractNoticeLoading: true};
  },

  setContractNoticeUrl: (st, all, url) => {
    return {...st, contractNoticeUrl: url};
  },

  closeContractNoticeModal: (st, all) => {
    return {
      ...st,
      contractNoticeModalOpen: false,
      contractNoticeLoading: false,
      subContractorSelection: [],
      contractNoticeRows: initialState.contractNoticeRows,
      contractNoticeDateRange: initialState.contractNoticeDateRange,
    };
  },

  reset: (st) => {
    return initialState;
  },
};

export const {actions, reducer} = scopedStore(namespace, cases, initialState);
