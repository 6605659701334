export const nodeEnv = process.env.REACT_APP_NODE_ENV;
export const inProd = nodeEnv === 'production';
export const inDev = !inProd;

const getOrFail = (name) => {
  const val = process.env[name];
  if (!val) {
    throw new Error(`Required environment variable "${name}" not set`);
  }
  return val;
};

export const apiUrl = getOrFail('REACT_APP_API_URL');
