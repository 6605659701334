import namespace from './namespace';
import {scopedStore} from 'utils/redux';

export const initialState = {
  show: false,
  title: '',
  message: '',
  cancelText: '',
  okText: '',
  okStyle: '',
  okDisabled: false,
  dangerText: '',
};

const ensureTranslations = (fullState) => (state) => {
  return {
    ...state,
    title: 'Vahvista',
    message: 'Suorittamasi toiminto vaatii vahvistuksen',
    cancelText: 'Peruuta',
    okText: 'Vahvista',
  };
};

export const cases = {
  show: (st, all, conf) => {
    const baseState = ensureTranslations(all)(initialState);
    return {...baseState, ...conf, show: true};
  },
  reset: (st) => {
    return initialState;
  },
};

export const {actions, reducer} = scopedStore(namespace, cases, initialState);
