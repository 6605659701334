const Input = {
  parts: ['field'],
  sizes: {
    lg: {
      field: {
        fontSize: 'sm',
        pl: 4,
        pr: 4,
        h: 12,
      },
    },
    sm: {
      field: {
        borderRadius: 'base',
      },
    },
  },
  variants: {
    outline: {
      field: {
        pt: 4,
        borderColor: 'gray.200',
        bgColor: 'white',
        color: 'gray.800',
        _placeholder: {
          color: 'gray.400',
        },
        _hover: {
          borderColor: 'gray.500',
        },
        _focus: {
          borderColor: 'gray.600',
          bgColor: 'gray.50',
          boxShadow: 'md',
        },
      },
    },
  },
  defaultProps: {
    size: 'lg',
    variants: 'outline',
  },
};

export default Input;
