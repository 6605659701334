import React, {useEffect, useState} from 'react';
import {HStack, FormControl, FormLabel, Select} from '@chakra-ui/react';
import {ReactDatepicker} from 'components';

const TimeEntryFilters = ({
  siteIdFilter,
  dateRange,
  sites,
  updateSiteIdFilter,
  updateDateRange,
}) => {
  const [endDate, setEndDate] = useState(dateRange.endDate);
  const [startDate, setStartDate] = useState(dateRange.startDate);

  useEffect(() => {
    if (!startDate && !endDate) {
      return;
    }
    updateDateRange({startDate, endDate});
  }, [startDate, endDate]);

  return (
    <HStack>
      <FormControl>
        <FormLabel>Alkaen</FormLabel>
        <ReactDatepicker
          name="startDate"
          standalone
          selected={dateRange.startDate}
          maxDate={endDate}
          onChange={(date) => setStartDate(date)}
        />
      </FormControl>
      <FormControl>
        <FormLabel>Päättyen</FormLabel>
        <ReactDatepicker
          name="endDate"
          standalone
          selected={dateRange.endDate}
          minDate={startDate}
          onChange={(date) => setEndDate(date)}
        />
      </FormControl>

      <FormControl>
        <FormLabel>Työmaa</FormLabel>
        <Select
          bgColor="white"
          fontSize="sm"
          borderRadius="0.25rem"
          size="lg"
          placeholder={'Valitse...'}
          variant="withLabel"
          onChange={(e) => updateSiteIdFilter(e.target.value)}
          value={siteIdFilter}
        >
          {sites.map((s) => (
            <option key={s.id} value={s.id}>
              {s.name}
            </option>
          ))}
        </Select>
      </FormControl>
    </HStack>
  );
};

export default TimeEntryFilters;
