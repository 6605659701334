import namespace from './namespace';
import {scopedStore} from 'utils/redux';

export const initialState = {
  loading: true,
  processing: false,
  organizationModalOpen: false,
  organization: [],
  sites: [],
  users: [],
  contractSites: [],
};

export const cases = {
  setLoading: (st, all, value) => {
    return {...st, loading: value};
  },

  setOrganization: (st, all, payload) => {
    const {sites, users, contractSites, ...organization} = payload;
    return {
      ...st,
      organization,
      sites,
      contractSites,
      users,
      loading: false,
    };
  },

  updateOrganization: (st, all, payload) => {
    return {
      ...st,
      organization: {...st.organization, ...payload},
    };
  },

  openOrganizationModal: (st, all, value) => {
    return {...st, organizationModalOpen: true};
  },

  closeOrganizationModal: (st, all, value) => {
    return {...st, organizationModalOpen: false};
  },

  setProcessing: (st, all, value) => {
    return {...st, processing: value};
  },

  reset: (st) => {
    return initialState;
  },
};

export const {actions, reducer} = scopedStore(namespace, cases, initialState);
