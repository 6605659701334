import cookies from 'js-cookie';
import * as Sentry from '@sentry/react';
import {createEffects} from 'utils/events';
import {P} from 'utils/types';
import {isApiTokenError} from 'utils/app';
import {decorateWithNotificationsEff} from 'io/app';
import services from 'services';
import {guardHandled, logInfo, handleAsFatal} from 'io/errors';
import namespace from './namespace';
import _acts from './boundActions';
import {getUser, logout, getOrganizations} from './io';

let acts;
_acts.then((x) => (acts = x));

let effects = {};
let types = {};

const clearStoredLogin = () => {
  cookies.remove('api_token', {domain: window.location.hostname});
};

effects.fetchOrganizations = async () => {
  const organizations = await decorateWithNotificationsEff(
    {id: 'get-orgs', failureStyle: 'warning'},
    getOrganizations(),
  );
  acts.setOrganizations(organizations);
};

const afterApiTokenReceivalEff = guardHandled(async (apiToken) => {
  try {
    const user = await getUser();
    acts.setUser(user);
    Sentry.setUser({id: user.id, username: user.name, email: user.email});
  } catch (e) {
    // keep from crashing on invalid api token, handling is done in an api hook
    if (isApiTokenError(e)) {
      logInfo(e);
      throw e;
    }
    return handleAsFatal(e);
  }

  effects.fetchOrganizations();
});

effects.initialize = guardHandled(async () => {
  const apiToken = cookies.get('api_token');
  acts.setApiToken(apiToken);

  if (apiToken) {
    afterApiTokenReceivalEff(apiToken);
  }
});

effects.afterLogin = async (apiToken) => {
  acts.setApiToken(apiToken);
  cookies.set('api_token', apiToken, {
    domain: window.location.hostname,
    expires: 365,
  });
  afterApiTokenReceivalEff(apiToken);
};
types.afterLogin = P.String;

effects.logout = guardHandled(async () => {
  try {
    await logout();
  } catch (e) {
    // fail silently
    logInfo(e);
  }
  acts.clearLogin();
  clearStoredLogin();
});

export default createEffects(namespace, services.get('channel').dispatch, types, effects);
