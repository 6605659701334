import services from 'services';
import {describeThrow} from 'utils/errors';
import {prop} from 'ramda';
import msgs from 'dicts/messages';

let httpJson = null;
services.waitFor('api').then((x) => (httpJson = x.httpJson));

export const getOrganization = (id) =>
  httpJson(
    'get',
    `/organizations/${id}`,
    {
      include:
        'sites, users, contractSites, contractSitesCount, sites.users, contractSites.users',
    },
    {},
  )
    .catch(describeThrow(msgs.fetchFailed))
    .then(prop('data'));

export const updateOrganization = (organization) =>
  httpJson('put', `/organizations/${organization.id}`, {}, {body: organization})
    .catch(describeThrow(msgs.saveFailed))
    .then(prop('data'));
