import namespace from './namespace';
import {scopedStore} from 'utils/redux';

export const initialState = {
  query: {
    page: 1,
    'filter[name]': '',
    include: 'contractSites,sites',
  },
  organizationModalOpen: false,
  processing: false,
  organizations: [],
  pagination: null,
  loading: false,
};

export const cases = {
  reset: (st) => {
    return initialState;
  },

  updateQuery: (st, all, payload) => {
    return {
      ...st,
      query: {...st.query, ...payload},
      loading: true,
    };
  },

  setOrganizations: (st, all, payload) => {
    return {
      ...st,
      organizations: payload.data,
      pagination: payload.pagination,
      loading: false,
    };
  },

  setLoading: (st, all, loading) => {
    return {...st, loading};
  },

  openOrganizationModal: (st, all, value) => {
    return {...st, organizationModalOpen: true};
  },

  closeOrganizationModal: (st, all, value) => {
    return {...st, organizationModalOpen: false};
  },

  setProcessing: (st, all, value) => {
    return {...st, processing: value};
  },
};

export const {actions, reducer} = scopedStore(namespace, cases, initialState);
