import React from 'react';
import {Box, Flex, Text, Spacer} from '@chakra-ui/react';

const CardHeader = ({isMain = false, title, action, children}) => {
  return (
    <Box
      as="header"
      borderBottom={isMain ? '' : '1px solid'}
      borderBottomColor="gray.100"
      py={isMain ? 6 : 4}
      px={5}
    >
      <Flex alignItems="center">
        {title ? (
          <Text
            as="h2"
            textStyle={isMain ? 'h2' : 'subheading'}
            color={isMain ? 'gray.800' : 'gray.600'}
            textTransform={isMain ? 'none' : 'uppercase'}
            whiteSpace="nowrap"
            mr={6}
          >
            {title}
          </Text>
        ) : null}
        {children}
        <Spacer />
        <Box ml={6}>{action}</Box>
      </Flex>
    </Box>
  );
};

export default CardHeader;
