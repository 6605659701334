import React, {useEffect, useState, useRef} from 'react';
import {
  HStack,
  FormControl,
  FormLabel,
  Select,
  Input,
  InputRightElement,
  InputGroup,
  Icon,
} from '@chakra-ui/react';
import {FiSearch} from 'react-icons/fi';
import {equals} from 'ramda';
import {useDebounce} from 'utils/react';

const EmployeeFilters = ({query = {}, updateQuery = () => {}, organizations = []}) => {
  const querySearch = query['filter[name]'] || '';
  const [search, setSearch] = useState(querySearch);
  const debouncedSearch = useDebounce(search, 500);
  const isInitialMount = useRef(true);

  // update filter to query debounced to prevent flooding API with requests
  // value also kept in local state that is updated immediately
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else if (!equals(debouncedSearch, querySearch)) {
      updateQuery({'filter[name]': debouncedSearch});
    }
  }, [debouncedSearch]);

  useEffect(() => {
    if (querySearch !== search) {
      setSearch(querySearch);
    }
  }, [querySearch]);

  return (
    <HStack bg="gray.50" p={4}>
      <FormControl w="auto">
        <FormLabel>Vapaahaku</FormLabel>
        <InputGroup>
          <Input
            type="text"
            placeholder="Nimi, osoite.."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <InputRightElement pointerEvents="none" color="gray.600">
            <Icon as={FiSearch} />
          </InputRightElement>
        </InputGroup>
      </FormControl>

      {/* TODO: Add this back once API has support for such filter

      <FormControl w="auto">
        <FormLabel>Työsuhde</FormLabel>
        <Select
          bgColor="white"
          fontSize="sm"
          size="lg"
          borderRadius="0.25rem"
          placeholder="Valitse..."
          variant="withLabel"
        >
          <option value="Palkkasuhde">Palkkasuhde</option>
          <option value="Alihankinta">Alihankinta</option>
        </Select>
      </FormControl>

      */}

      <FormControl w="auto">
        <FormLabel>Yritys</FormLabel>
        <Select
          bgColor="white"
          fontSize="sm"
          borderRadius="0.25rem"
          size="lg"
          placeholder="Valitse..."
          variant="withLabel"
          onChange={(e) => updateQuery({'filter[organizationId]': e.target.value})}
          value={query['filter[organizationId]']}
        >
          {organizations.map((o) => (
            <option key={o.id} value={o.id}>
              {o.name}
            </option>
          ))}
        </Select>
      </FormControl>
    </HStack>
  );
};

export default EmployeeFilters;
