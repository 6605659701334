import React, {memo} from 'react';
import {includes, all} from 'ramda';
import {Table, Thead, Tbody, Tr, Th, Td, Checkbox} from '@chakra-ui/react';
import {sToTime} from 'utils/time';

const EmployeeRow = memo(function EmployeeRow({employee, isSelected, select = () => {}}) {
  // how many unique time entry dates (ignoring hours) employee has
  const totalDays = employee.timeEntries
    .map((te) => te.startDate.split('T')[0])
    .filter((s, i, a) => a.indexOf(s) === i).length;

  return (
    <Tr>
      <Td>
        <Checkbox
          isChecked={isSelected}
          onClick={(e) => e.stopPropagation()}
          onChange={(e) => select([{id: employee.id, selected: e.target.checked}])}
        />
      </Td>
      <Td>
        <strong>{employee.name}</strong>
      </Td>
      <Td>{employee.organizationName}</Td>
      <Td>{employee.birthday}</Td>
      <Td>{employee.vatNumber}</Td>
      <Td>{sToTime(employee.totalTime.total)}</Td>
      <Td>{totalDays}</Td>
    </Tr>
  );
});

const EmployeeNoticeTable = ({
  employees = [],
  selection = [],
  selectEmployees = () => {},
  clearSelection = () => {},
}) => {
  const handleSelectAll = (selected) => {
    if (selected) {
      selectEmployees(employees.map((e) => ({id: e.id, selected})));
    } else {
      clearSelection();
    }
  };

  return (
    <>
      <Table overflow="auto" textStyle="small">
        <Thead>
          <Tr>
            <Th>
              <Checkbox
                isChecked={
                  !!employees.length &&
                  all((employee) => includes(employee.id, selection), employees)
                }
                onClick={(e) => e.stopPropagation()}
                onChange={(e) => handleSelectAll(e.target.checked)}
              />
            </Th>
            <Th>Nimi</Th>
            <Th>Yritys</Th>
            <Th>Syntymäaika</Th>
            <Th>Veronumero</Th>
            <Th>Työaika</Th>
            <Th>Työpäivät</Th>
          </Tr>
        </Thead>
        <Tbody>
          {employees.map((e) => (
            <EmployeeRow
              key={e.id}
              employee={e}
              select={selectEmployees}
              isSelected={includes(e.id, selection)}
            />
          ))}
        </Tbody>
      </Table>
    </>
  );
};

export default EmployeeNoticeTable;
