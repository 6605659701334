import {pipeReducers} from 'utils/redux';
import notifications from './notifications/reducer';
import confirmer from './confirmer/reducer';
import common from './common/reducer';
import login from './login/reducer';
import sites from './sites/reducer';
import employees from './employees/reducer';
import organizations from './organizations/reducer';
import reportages from './reportages/reducer';
import timeEntries from './timeEntries/reducer';

export default pipeReducers(
  notifications,
  confirmer,
  common,
  login,
  sites,
  employees,
  organizations,
  reportages,
  timeEntries,
);
