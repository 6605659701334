import React, {useState, useEffect} from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  HStack,
  FormControl,
  FormLabel,
  Checkbox,
  Select,
  Text,
  Spinner,
  Center,
  Input,
  FormErrorMessage,
} from '@chakra-ui/react';
import {useForm, useFieldArray} from 'react-hook-form';

import {ReactDatepicker} from 'components';

export const TimeEntriesModal = ({isOpen, onClose, defaultValues, onSubmit, siteId}) => {
  const [renderEntries, setRenderEntries] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setRenderEntries(true);
    }, 500);
  }, []);

  const {
    handleSubmit,
    formState: {errors},
    register,
    control,
  } = useForm({defaultValues});

  const submit = (data) => {
    onSubmit({data, siteId});
  };

  const {fields} = useFieldArray({control, name: 'timeEntries'});

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="4xl"
      closeOnOverlayClick={false}
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent minHeight={540}>
        <ModalHeader>Muokkaa työaikakirjauksia</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {!renderEntries ? (
            <Center p={6}>
              <Spinner size="xl" />
            </Center>
          ) : (
            <form onSubmit={handleSubmit(submit)} id="timeEntry-form">
              {fields.map((item, index) => (
                <HStack key={item.id} spacing={2} my={4}>
                  <FormControl isInvalid={errors.timeEntries?.[index]?.type} flex={1}>
                    <FormLabel>Tila</FormLabel>
                    <Select
                      size="lg"
                      variant="withLabel"
                      fontSize="sm"
                      type="text"
                      {...register(`timeEntries.${index}.type`)}
                      defaultValue={item.type}
                    >
                      <option value="present">Paikalla</option>
                      <option value="pickup">Nouto</option>
                      <option value="break">Tauko</option>
                      <option value="transition">Siirtymä</option>
                    </Select>
                  </FormControl>
                  <FormControl
                    isInvalid={errors.timeEntries?.[index]?.startDate}
                    flex={2}
                  >
                    <FormLabel>Aloitusajankohta</FormLabel>
                    <ReactDatepicker
                      name={`timeEntries.${index}.startDate`}
                      control={control}
                      timeInputLabel="Aloitusajankohta:"
                      dateFormat="MM/dd/yyyy H:mm"
                      showTimeInput
                      rules={{required: 'Pakollinen kenttä'}}
                      defaultValue={item.startDate}
                    />
                    <FormErrorMessage>
                      {errors.startDate && errors.startDate.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={errors.timeEntries?.[index]?.endDate} flex={2}>
                    <FormLabel>Päättymisajankohta</FormLabel>
                    <ReactDatepicker
                      name={`timeEntries.${index}.endDate`}
                      control={control}
                      timeInputLabel="Päättymisajankohta:"
                      dateFormat="MM/dd/yyyy H:mm "
                      showTimeInput
                      rules={{required: 'Pakollinen kenttä'}}
                      defaultValue={item.endDate}
                    />
                    <FormErrorMessage>
                      {errors.endDate && errors.endDate.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    flex={2}
                    isInvalid={errors.timeEntries?.[index]?.description}
                  >
                    <FormLabel>Lisätiedot</FormLabel>
                    <Input
                      type="text"
                      defaultValue={item.description}
                      {...register(`timeEntries.${index}.description`)}
                    />
                  </FormControl>
                  <FormErrorMessage>
                    {errors.description && errors.description.message}
                  </FormErrorMessage>
                  <input
                    {...register(`timeEntries.${index}.id`)}
                    defaultValue={item.id}
                    hidden
                  />
                  <FormControl
                    alignItems="center"
                    display="flex"
                    flex={1}
                    justifyContent="center"
                  >
                    <Checkbox
                      pr={1}
                      colorScheme="red"
                      {...register(`timeEntries.${index}.delete`)}
                    ></Checkbox>
                    <Text>Poista</Text>
                  </FormControl>
                </HStack>
              ))}
            </form>
          )}
        </ModalBody>
        <ModalFooter borderTopWidth={1}>
          <Button onClick={onClose} mr={3}>
            Peruuta
          </Button>
          <Button type="submit" form="timeEntry-form" variant="primary">
            <span>Tallenna</span>
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
