const Badge = {
  // The styles all button have in common
  baseStyle: {
    fontWeight: 'bold',
    textTransform: 'none',
  },
  // Two sizes: sm and md
  sizes: {
    md: {
      fontSize: 'xs',
      borderRadius: 'full',
      h: 6,
      px: 3,
      lineHeight: 2,
    },
  },
  // Two variants: outline and solid
  variants: {
    primary: {
      bg: 'blue.100',
      color: 'blue.600',
    },
    warning: {
      bg: 'orange.100',
      color: 'orange.800',
    },
    danger: {
      bg: 'red.100',
      color: 'red.800',
    },
    success: {
      bg: 'green.100',
      color: 'green.800',
    },
    muted: {
      bg: 'gray.100',
      color: 'gray.800',
    },
  },
  // The default size and variant values
  defaultProps: {
    size: 'md',
    variant: 'primary',
  },
};

export default Badge;
