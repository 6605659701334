import React from 'react';
import {Link as RouterLink, withRouter} from 'react-router-dom';
import {Breadcrumb, BreadcrumbItem, BreadcrumbLink} from '@chakra-ui/react';
import {getReferrerUrl, getReferrerTitle} from 'utils/url';

// Simple breadcrumbs component, max 2 levels (prev + active) based on referrerUrl
const Breadcrumbs = ({location, activeCrumbName}) => {
  const referrerUrl = getReferrerUrl(location.search);
  const referrerTitle = getReferrerTitle(location.search);

  return (
    <Breadcrumb fontWeight="bold" fontSize="sm">
      {!!referrerUrl && !!referrerTitle && (
        <BreadcrumbItem>
          <BreadcrumbLink
            as={RouterLink}
            to={referrerUrl}
            color="blue.600"
            textDecor="underline"
          >
            {referrerTitle}
          </BreadcrumbLink>
        </BreadcrumbItem>
      )}
      <BreadcrumbItem isCurrentPage>
        <BreadcrumbLink>{activeCrumbName}</BreadcrumbLink>
      </BreadcrumbItem>
    </Breadcrumb>
  );
};

export default withRouter(Breadcrumbs);
