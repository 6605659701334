import React, {useEffect, useState} from 'react';
import {
  HStack,
  Select,
  Input,
  InputRightElement,
  InputGroup,
  Icon,
} from '@chakra-ui/react';
import {FiSearch} from 'react-icons/fi';

const SiteEmployeeFilters = ({updateQuery, siteOrganizations}) => {
  const [nameFilter, setNameFilter] = useState('');
  const [stateFilter, setStateFilter] = useState('');
  const [orgFilter, setOrgFilter] = useState('');

  useEffect(() => {
    updateQuery({
      name: nameFilter,
      state: stateFilter,
      organization: orgFilter !== '' ? Number(orgFilter) : null,
    });
  }, [nameFilter, stateFilter, orgFilter]);

  return (
    <HStack>
      <InputGroup size="sm">
        <Input
          type="text"
          paddingTop="0"
          placeholder="Nimi"
          value={nameFilter}
          onChange={(e) => setNameFilter(e.target.value)}
          minWidth="180px"
        />
        <InputRightElement pointerEvents="none" color="gray.600">
          <Icon as={FiSearch} />
        </InputRightElement>
      </InputGroup>
      <Select
        borderRadius="0.25rem"
        size="sm"
        placeholder="Tila"
        onChange={(e) => setStateFilter(e.target.value)}
      >
        <option value="present">Paikalla</option>
        <option value="away">Poissa</option>
        <option value="break">Tauko</option>
        <option value="pickup">Nouto</option>
      </Select>
      <Select
        borderRadius="0.25rem"
        size="sm"
        placeholder="Yritys"
        value={orgFilter}
        onChange={(e) => setOrgFilter(e.target.value)}
      >
        {siteOrganizations.map((x) => (
          <option key={x.id} value={x.id}>
            {x.name}
          </option>
        ))}
      </Select>
    </HStack>
  );
};

export default SiteEmployeeFilters;
