import React from 'react';
import {Box, Spinner} from '@chakra-ui/react';

const PageLoader = () => {
  return (
    <Box bg="gray.100" display="flex" pt={10} justifyContent="center">
      <Spinner size="xl" />
    </Box>
  );
};

export default PageLoader;
