import React, {useEffect} from 'react';
import {HStack, Box, Spacer, Button, Text, Spinner, Center} from '@chakra-ui/react';
import {FiPlusCircle} from 'react-icons/fi';
import {pick} from 'ramda';
import {connect} from 'react-redux';

import {applyState} from 'utils/redux';
import {encodeQuery, createReferrerUrl} from 'utils/url';
import effs from 'modules/organizations/organizationsPage/effects';
import sels from 'modules/organizations/organizationsPage/selectors';

import {Content, Card} from 'components';
import OrganizationsTable from './components/organizations-table';
import OrganizationsFilters from './components/organizations-filters';
import OrganizationModal from './components/organization-modal';

const Organizations = ({
  history,
  location,
  processing,
  loading,
  pagination,
  query,
  organizations,
  organizationModalOpen,
}) => {
  useEffect(() => {
    effs.initialize();

    return () => {
      effs.destroy();
    };
  }, []);

  const referrerUrl = createReferrerUrl(location);

  const openOrganization = (id) => {
    history.push(
      `/organizations/${id}${encodeQuery({
        referrerUrl,
        referrerTitle: 'Organisaatiot',
      })}`,
    );
  };

  return (
    <Content>
      <HStack spacing={6}>
        <Card w="full">
          <HStack px={6} py={8} verticalAlign="space-between">
            <Text textStyle="h1">Organisaatiot</Text>
            <Spacer />
            <Button
              onClick={() => effs.openOrganizationModal()}
              leftIcon={<FiPlusCircle />}
            >
              Lisää
            </Button>
          </HStack>

          <OrganizationsFilters query={query} updateQuery={effs.updateQuery} />

          {loading ? (
            <Center p={6}>
              <Spinner size="xl" />
            </Center>
          ) : !organizations.length ? (
            <Box p={6}>Ei organisaatioita</Box>
          ) : (
            <OrganizationsTable
              organizations={organizations}
              pagination={pagination}
              processing={processing}
              onPageSelect={(page) => effs.updateQuery({page})}
              openOrganization={openOrganization}
              referrerUrl={referrerUrl}
              removeOrganization={effs.removeOrganization}
            />
          )}
        </Card>
      </HStack>
      {organizationModalOpen && (
        <OrganizationModal
          isOpen={organizationModalOpen}
          isLoading={processing}
          onClose={() => effs.closeOrganizationModal()}
          onSubmit={effs.createOrganization}
          isNew={true}
        />
      )}
    </Content>
  );
};

export default connect(
  applyState(
    pick(
      [
        'processing',
        'loading',
        'query',
        'pagination',
        'organizations',
        'organizationModalOpen',
      ],
      sels,
    ),
  ),
)(Organizations);
