import namespace from './namespace';
import {scopedStore} from 'utils/redux';
import {emptyPagination} from 'constants/pagination';

export const initialState = {
  query: {
    page: 1,
    sort: 'name',
    'filter[name]': '',
    'filter[organizationId]': '',
  },
  processing: false,
  pagination: emptyPagination,
  loading: false,
  employees: [],
  activeEmployees: [],
  employeeModalOpen: false,
  organizations: [],
};

export const cases = {
  updateQuery: (st, all, payload) => {
    return {
      ...st,
      query: {...st.query, ...payload},
      loading: true,
    };
  },

  setEmployees: (st, all, payload) => {
    return {
      ...st,
      employees: payload.data,
      pagination: payload.pagination,
      loading: false,
    };
  },

  setActiveEmployees: (st, all, data) => {
    return {
      ...st,
      activeEmployees: data,
    };
  },

  setOrganizations: (st, all, organizations) => {
    return {...st, organizations, loading: false};
  },

  setLoading: (st, all, loading) => {
    return {...st, loading};
  },

  setProcessing: (st, all, processing) => {
    return {...st, processing};
  },

  openEmployeeModal: (st) => {
    return {...st, employeeModalOpen: true};
  },

  closeEmployeeModal: (st) => {
    return {...st, employeeModalOpen: false};
  },

  reset: (st) => {
    return initialState;
  },
};

export const {actions, reducer} = scopedStore(namespace, cases, initialState);
