export default {
  cancel: 'Peruuta',
  save: 'Tallenna',
  processing: 'Käsitellään',
  deleting: 'Poistetaan',
  loading: 'Ladataan',
  saving: 'Tallennetaan',
  fetchFailed: 'Sisällön lataus epäonnistui. Kokeile päivittää sivu',
  opFailed: 'Toimenpide epäonnistui',
  opSuccess: 'Toimenpide onnistui',
  saveFailed: 'Tallennus epäonnistui',
  saveSuccess: 'Tallennettu',
  deleteFailed: 'Poisto epäonnistui',
  selectorPrompt: 'Valitse...',
};
