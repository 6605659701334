import React from 'react';
import {Controller} from 'react-hook-form';
import ReactDatepicker from 'react-datepicker';
import {Input, InputGroup, InputRightElement, Icon} from '@chakra-ui/react';
import {FiCalendar} from 'react-icons/fi';
import fi from 'date-fns/locale/fi';
import 'react-datepicker/dist/react-datepicker.css';
import 'styles/react-datepicker.css';

// eslint-disable-next-line react/display-name
const CustomInput = React.forwardRef(({isClearable, ...inputProps}, ref) => (
  <InputGroup>
    <Input ref={ref} {...inputProps} pr={4} autoComplete="off" />
    <InputRightElement
      pointerEvents="none"
      color="gray.600"
      hidden={isClearable && !!inputProps.value}
    >
      <Icon as={FiCalendar} />
    </InputRightElement>
  </InputGroup>
));

const Datepicker = ({isClearable, ...props}) => {
  return (
    <ReactDatepicker
      dateFormat="dd.MM.yyyy"
      timeFormat="HH:mm"
      timeCaption="Klo"
      timeIntervals={5}
      locale={fi}
      customInput={<CustomInput isClearable={isClearable} />}
      isClearable={isClearable}
      popperModifiers={{
        preventOverflow: {
          enabled: true,
          escapeWithReference: false,
          boundariesElement: 'viewport',
        },
      }}
      {...props}
    />
  );
};

// react-hook-form compatible react-datepicker that fits stylistically with chakra-ui
const DatepickerContainer = ({
  name,
  control,
  rules,
  defaultValue,
  standalone = !control,
  ...props
}) => {
  // use standalone prop when using datepicker outside react-hook-form
  if (standalone) {
    return <Datepicker {...props} />;
  }

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={({field: {onChange, onBlur, value}}) => (
        <Datepicker {...props} selected={value} onChange={onChange} onBlur={onBlur} />
      )}
    />
  );
};

export default DatepickerContainer;
