import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalHeader,
  ModalCloseButton,
  ModalFooter,
  Stack,
  Input,
  FormControl,
  FormLabel,
  Button,
  FormErrorMessage,
  HStack,
} from '@chakra-ui/react';
import {useForm} from 'react-hook-form';

const OrganizationModal = ({
  isOpen,
  isLoading,
  onClose = () => {},
  onSubmit = () => {},
  defaultValues = {},
  isNew = true,
}) => {
  const {
    handleSubmit,
    formState: {errors},
    register,
  } = useForm({defaultValues});

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {isNew ? 'Lisää organisaatio' : 'Muokkaa organisaatiota'}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form onSubmit={handleSubmit(onSubmit)} id="organization-form">
            <Stack spacing={4}>
              <FormControl isInvalid={errors.name} isRequired>
                <FormLabel>Nimi</FormLabel>
                <Input
                  type="text"
                  {...register('name', {required: 'Pakollinen kenttä'})}
                />
                <FormErrorMessage>{errors.name && errors.name.message}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.address} isRequired>
                <FormLabel>Osoite</FormLabel>
                <Input
                  type="text"
                  {...register('address', {required: 'Pakollinen kenttä'})}
                />
                <FormErrorMessage>
                  {errors.address && errors.address.message}
                </FormErrorMessage>
              </FormControl>

              <HStack spacing={2} align="flex-start">
                <FormControl isInvalid={errors.postcode} isRequired>
                  <FormLabel>Postinumero</FormLabel>
                  <Input
                    type="text"
                    {...register('postcode', {required: 'Pakollinen kenttä'})}
                  />
                  <FormErrorMessage>
                    {errors.postcode && errors.postcode.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={errors.city} isRequired>
                  <FormLabel>Kaupunki</FormLabel>
                  <Input
                    type="text"
                    {...register('city', {required: 'Pakollinen kenttä'})}
                  />
                  <FormErrorMessage>
                    {errors.city && errors.city.message}
                  </FormErrorMessage>
                </FormControl>
              </HStack>

              <HStack spacing={2} align="flex-start">
                <FormControl isInvalid={errors.contact}>
                  <FormLabel>Yhteyshenkilö</FormLabel>
                  <Input type="text" {...register('contact')} />
                  <FormErrorMessage>
                    {errors.contact && errors.contact.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={errors.email} isRequired>
                  <FormLabel>Sähköposti</FormLabel>
                  <Input
                    type="text"
                    {...register('email', {required: 'Pakollinen kenttä'})}
                  />
                  <FormErrorMessage>
                    {errors.email && errors.email.message}
                  </FormErrorMessage>
                </FormControl>
              </HStack>
              <HStack spacing={2} align="flex-start">
                <FormControl isInvalid={errors.industry}>
                  <FormLabel>Toimiala</FormLabel>
                  <Input type="text" {...register('industry')} />
                  <FormErrorMessage>
                    {errors.industry && errors.industry.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={errors.businessId} isRequired>
                  <FormLabel>Yritysnumero</FormLabel>
                  <Input
                    type="text"
                    {...register('businessId', {required: 'Pakollinen kenttä'})}
                  />
                  <FormErrorMessage>
                    {errors.businessId && errors.businessId.message}
                  </FormErrorMessage>
                </FormControl>
              </HStack>
              <FormControl isInvalid={errors.phone} isRequired>
                <FormLabel>Puh.</FormLabel>
                <Input
                  type="text"
                  {...register('phone', {required: 'Pakollinen kenttä'})}
                />
                <FormErrorMessage>
                  {errors.phone && errors.phone.message}
                </FormErrorMessage>
              </FormControl>
            </Stack>
          </form>
        </ModalBody>

        <ModalFooter>
          <Button onClick={onClose} mr={3}>
            Peruuta
          </Button>
          <Button
            type="submit"
            form="organization-form"
            variant="primary"
            isLoading={isLoading}
          >
            <span>Tallenna</span>
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default OrganizationModal;
