import namespace from './namespace';
import {scopedStore} from 'utils/redux';
import {update} from 'ramda';

export const initialState = [];

export const cases = {
  adjust: (st, all, spec) => {
    const iExisting = st.findIndex((n) => n.id === spec.id);
    const stNew = iExisting >= 0 ? update(iExisting, spec, st) : [...st, spec];
    return stNew;
  },

  remove: (st, all, id) => {
    return st.filter((n) => n.id !== id);
  },
};

export const {actions, reducer} = scopedStore(namespace, cases, initialState);
