import namespace from './namespace';
import {scopedSelectors} from 'utils/redux';
import {createSelector} from 'reselect';
import {formatOrganizationDefaultVals} from './utils';

let sels = {};

sels.organization = (st) => st.organization;

sels.loading = (st) => st.loading;

sels.processing = (st) => st.processing;

sels.users = (st) => st.users;

sels.sites = (st) => st.sites;

sels.contractSites = (st) => st.contractSites;

sels.organizationModalOpen = (st) => st.organizationModalOpen;

sels.organizationDefaultVals = createSelector(sels.organization, (organization) =>
  formatOrganizationDefaultVals(organization),
);

sels.query = (st) => st.query;

export default scopedSelectors(namespace, sels);
