import React, {useEffect} from 'react';
import {
  SimpleGrid,
  Stack,
  Text,
  Center,
  Spinner,
  IconButton,
  Icon,
  Box,
} from '@chakra-ui/react';
import {pick} from 'ramda';
import {connect} from 'react-redux';
import {FiEdit} from 'react-icons/fi';
import {encodeQuery, createReferrerUrl} from 'utils/url';

import {applyState} from 'utils/redux';
import effs from 'modules/organizations/organizationPage/effects';
import sels from 'modules/organizations/organizationPage/selectors';
import SitesTable from './components/sites-table';
import EmployeesTable from './components/employees-table';
import ContractSitesTable from './components/contractSites-table';
import OrganizationModal from './components/organization-modal';

import {Content, PageHeader, Card, CardHeader, List, ListItem} from 'components';

const Organization = ({
  match: {
    params: {organizationId},
  },
  processing,
  sites,
  organization,
  loading,
  users,
  location,
  history,
  contractSites,
  organizationModalOpen,
  organizationDefaultVals,
}) => {
  useEffect(() => {
    effs.initialize(organizationId);

    return () => {
      effs.destroy();
    };
  }, []);

  const referrerUrl = createReferrerUrl(location);

  const openEmployee = (id) => {
    history.push(
      `/employees/${id}${encodeQuery({referrerUrl, referrerTitle: 'Työntekijät'})}`,
    );
  };

  const openSite = (id) => {
    history.push(`/sites/${id}${encodeQuery({referrerUrl, referrerTitle: 'Työmaat'})}`);
  };

  return (
    <Content>
      <PageHeader activeCrumbName={organization.name} />

      {loading ? (
        <Center>
          <Spinner size="xl" />
        </Center>
      ) : (
        <SimpleGrid columns={{md: 2}} templateColumns="1fr 2fr" spacing="5">
          <Stack spacing={6}>
            <Card>
              <CardHeader
                title={organization.name}
                isMain
                action={
                  <IconButton
                    onClick={() => effs.openOrganizationModal()}
                    size="sm"
                    icon={<Icon as={FiEdit} />}
                  />
                }
              />
              <List marginBottom="5">
                <ListItem title="Osoite" display="flex" justifyContent="space-between">
                  <Text textAlign="right">{organization.address}</Text>
                </ListItem>
                <ListItem title="Toimiala" display="flex" justifyContent="space-between">
                  <Text textAlign="right">{organization.industry}</Text>
                </ListItem>
                <ListItem
                  title="Yhteyshenkilö"
                  display="flex"
                  justifyContent="space-between"
                >
                  <Text textAlign="right">{organization.contact}</Text>
                </ListItem>
                <ListItem
                  title="Sähköposti"
                  display="flex"
                  justifyContent="space-between"
                >
                  <Text textAlign="right">{organization.email}</Text>
                </ListItem>
                <ListItem title="Puh." display="flex" justifyContent="space-between">
                  <Text textAlign="right">{organization.phone}</Text>
                </ListItem>
              </List>
            </Card>
          </Stack>
          <Stack spacing={6}>
            <Card>
              <CardHeader
                title={`Omat Työmaat (${sites.length})`}
                action={
                  <IconButton onClick={() => {}} size="sm" icon={<Icon as={FiEdit} />} />
                }
              />
              {loading ? (
                <Center p={6}>
                  <Spinner size="xl" />
                </Center>
              ) : !sites.length ? (
                <Box p={6}>Ei työmaita</Box>
              ) : (
                <SitesTable
                  sites={sites}
                  processing={processing}
                  onSiteClick={openSite}
                />
              )}
            </Card>
            <Card>
              <CardHeader
                title={`Alihankinta työmaat (${contractSites.length})`}
                action={
                  <IconButton onClick={() => {}} size="sm" icon={<Icon as={FiEdit} />} />
                }
              />
              {loading ? (
                <Center p={6}>
                  <Spinner size="xl" />
                </Center>
              ) : !contractSites.length ? (
                <Box p={6}>Ei työmaita</Box>
              ) : (
                <ContractSitesTable
                  sites={contractSites}
                  processing={processing}
                  onSiteClick={openSite}
                />
              )}
            </Card>
            <Card>
              <CardHeader
                title={`Työntekijät (${users.length})`}
                action={
                  <IconButton onClick={() => {}} size="sm" icon={<Icon as={FiEdit} />} />
                }
              />
              {loading ? (
                <Center p={6}>
                  <Spinner size="xl" />
                </Center>
              ) : !users.length ? (
                <Box p={6}>Ei työntekijöitä</Box>
              ) : (
                <EmployeesTable
                  users={users}
                  processing={processing}
                  onEmployeeClick={openEmployee}
                />
              )}
            </Card>
          </Stack>
        </SimpleGrid>
      )}
      {organizationModalOpen && (
        <OrganizationModal
          isOpen={organizationModalOpen}
          isLoading={processing}
          onClose={() => effs.closeOrganizationModal()}
          onSubmit={effs.updateOrganization}
          defaultValues={organizationDefaultVals}
          isNew={true}
        />
      )}
    </Content>
  );
};

export default connect(
  applyState(
    pick(
      [
        'loading',
        'processing',
        'organization',
        'sites',
        'users',
        'contractSites',
        'organizationModalOpen',
        'organizationDefaultVals',
      ],
      sels,
    ),
  ),
)(Organization);
