import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalHeader,
  ModalCloseButton,
  ModalFooter,
  Stack,
  Input,
  FormControl,
  FormLabel,
  Button,
  FormErrorMessage,
} from '@chakra-ui/react';
import {useForm} from 'react-hook-form';

const DocumentEditorModal = ({
  isOpen,
  isLoading,
  onClose = () => {},
  onSubmit = () => {},
  defaultValues = {},
}) => {
  const {
    handleSubmit,
    formState: {errors},
    register,
  } = useForm({defaultValues});

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Tiedosto</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form onSubmit={handleSubmit(onSubmit)} id="document-editor-form">
            <Stack spacing={4}>
              <FormControl isInvalid={errors.name}>
                <FormLabel>Nimi</FormLabel>
                <Input
                  type="text"
                  {...register('name', {required: 'Pakollinen kenttä'})}
                />
                <FormErrorMessage>{errors.name && errors.name.message}</FormErrorMessage>
              </FormControl>
            </Stack>
          </form>
        </ModalBody>

        <ModalFooter>
          <Button onClick={onClose} mr={3}>
            Peruuta
          </Button>
          <Button
            type="submit"
            form="document-editor-form"
            variant="primary"
            isLoading={isLoading}
          >
            <span>Tallenna</span>
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DocumentEditorModal;
