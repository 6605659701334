import React from 'react';
import {HStack, FormControl, Select, FormLabel} from '@chakra-ui/react';
import {ReactDatepicker} from 'components';

const EmployeeNoticeFilters = ({
  updateQuery = () => {},
  query = {},
  organizations = [],
}) => {
  return (
    <HStack>
      <FormControl>
        <FormLabel>Alkaen</FormLabel>
        <ReactDatepicker
          name="startDate"
          standalone
          selected={query.startDate}
          onChange={(date) => updateQuery({startDate: date})}
        />
      </FormControl>
      <FormControl>
        <FormLabel>Päättyen</FormLabel>
        <ReactDatepicker
          name="endDate"
          standalone
          selected={query.endDate}
          onChange={(date) => updateQuery({endDate: date})}
        />
      </FormControl>
      <FormControl>
        <FormLabel>Yritys</FormLabel>
        <Select
          value={query.organizationId}
          onChange={(e) => updateQuery({organizationId: e.target.value})}
          size="lg"
          variant="withLabel"
          placeholder="Valitse..."
        >
          {organizations.map((o) => (
            <option key={o.id} value={o.id}>
              {o.name}
            </option>
          ))}
        </Select>
      </FormControl>
    </HStack>
  );
};

export default EmployeeNoticeFilters;
