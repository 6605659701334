import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalHeader,
  ModalCloseButton,
  ModalFooter,
  Stack,
  Input,
  FormControl,
  FormLabel,
  Button,
  FormErrorMessage,
  HStack,
} from '@chakra-ui/react';
import {useForm} from 'react-hook-form';
import {ReactDatepicker} from 'components';

const WorkPermitModal = ({
  isOpen,
  isLoading,
  onClose = () => {},
  onSubmit = () => {},
  defaultValues = {},
  isNew = true,
}) => {
  const {
    handleSubmit,
    formState: {errors},
    register,
    control,
  } = useForm({defaultValues});

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Työlupa</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form onSubmit={handleSubmit(onSubmit)} id="workPermit-form">
            <Stack spacing={4}>
              <HStack>
                <FormControl isInvalid={errors.name} isRequired>
                  <FormLabel>Nimi</FormLabel>
                  <Input
                    type="text"
                    {...register('name', {required: 'Pakollinen kenttä'})}
                  />
                  <FormErrorMessage>
                    {errors.name && errors.name.message}
                  </FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={errors.expires_at} isRequired>
                  <FormLabel>Voimassa asti</FormLabel>
                  <ReactDatepicker
                    name="expires_at"
                    control={control}
                    dateFormat="MM/yyyy"
                    showMonthYearPicker
                    rules={{required: 'Pakollinen kenttä'}}
                  />
                  <FormErrorMessage>
                    {errors.expires_at && errors.expires_at.message}
                  </FormErrorMessage>
                </FormControl>
              </HStack>
              <HStack>
                <FormControl isInvalid={errors.description}>
                  <FormLabel>Kuvaus</FormLabel>
                  <Input
                    type="text"
                    {...register('description', {required: 'Pakollinen kenttä'})}
                  />
                  <FormErrorMessage>
                    {errors.description && errors.description.message}
                  </FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={errors.number}>
                  <FormLabel>Luvan numero</FormLabel>
                  <Input
                    type="number"
                    {...register('number', {required: 'Pakollinen kenttä'})}
                  />
                  <FormErrorMessage>
                    {errors.number && errors.number.message}
                  </FormErrorMessage>
                </FormControl>
              </HStack>
              <FormControl isInvalid={errors.admitter}>
                <FormLabel>Myöntäjä</FormLabel>
                <Input
                  type="text"
                  {...register('admitter', {required: 'Pakollinen kenttä'})}
                />
                <FormErrorMessage>
                  {errors.admitter && errors.admitter.message}
                </FormErrorMessage>
              </FormControl>
            </Stack>
          </form>
        </ModalBody>

        <ModalFooter>
          <Button onClick={onClose} mr={3}>
            Peruuta
          </Button>
          <Button
            type="submit"
            form="workPermit-form"
            variant="primary"
            isLoading={isLoading}
          >
            <span>Tallenna</span>
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default WorkPermitModal;
