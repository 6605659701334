import {createSelector} from 'reselect';
import namespace from './namespace';
import {scopedSelectors} from 'utils/redux';
import {
  formatSiteDefaultVals,
  formatExpenseDefaultVals,
  formatEmployeeDefaultVals,
  formatFetchableEmployeeNoticesQuery,
  formatContractNoticeQuery,
} from './utils';

let sels = {};

sels.processing = (st) => st.processing;

sels.loading = (st) => st.loading;

sels.employeeNoticeUrl = (st) => st.employeeNoticeUrl;

sels.site = (st) => st.site;

sels.users = (st) => st.users;

sels.employeesQuery = (st) => st.employeesQuery;

sels.filteredUsers = createSelector([sels.users, sels.employeesQuery], (users, query) => {
  const result = users.filter((user) => {
    return (
      (query.name === '' || user.name.toLowerCase().includes(query.name.toLowerCase())) &&
      (query.organization === null || user.organizationId === query.organization) &&
      (query.state === '' || user.activityStatus === query.state)
    );
  });
  return result;
});

sels.organization = (st) => st.organization;

sels.organizationName = (st) => st.organization.name;

sels.siteSubContractors = (st) => st.siteSubContractors;

sels.siteOrganizations = createSelector(
  [sels.organization, sels.siteSubContractors, sels.loading],
  (organization, siteSubContractors, loading) => {
    if (loading) {
      return [];
    }
    return [organization, ...siteSubContractors];
  },
);

sels.addableEmployees = (st) => st.addableEmployees;

sels.siteDefaultVals = createSelector(sels.site, (site) => formatSiteDefaultVals(site));

sels.employeeModalOpen = (st) => st.employeeModalOpen;

sels.openedEmployeeId = (st) => st.openedEmployeeId;

sels.selectedEmployeeId = (st) => st.selectedEmployeeId;

sels.openedEmployee = createSelector(
  [sels.users, sels.openedEmployeeId],
  (users, openedEmployeeId) => users.find((u) => u.id === openedEmployeeId),
);

sels.selectedEmployee = createSelector(
  [sels.addableEmployees, sels.selectedEmployeeId],
  (addableEmployees, selectedEmployeeId) =>
    addableEmployees.find((u) => u.id === selectedEmployeeId),
);

sels.fileUploaderModalOpen = (st) => st.fileUploaderModalOpen;

sels.employeeDefaultVals = createSelector(
  [sels.openedEmployee, sels.organization],
  (openedEmployee, organization) =>
    formatEmployeeDefaultVals(openedEmployee, organization),
);

sels.siteModalOpen = (st) => st.siteModalOpen;

sels.expenseModalOpen = (st) => st.expenseModalOpen;

sels.expenses = (st) => st.expenses;

sels.extraCosts = (st) => st.extraCosts;

sels.extraCostsLoading = (st) => st.extraCostsLoading;

sels.expensesLoading = (st) => st.expensesLoading;

sels.activeExpenseId = (st) => st.activeExpenseId;

sels.activeExpense = createSelector(
  [sels.activeExpenseId, sels.expenses],
  (activeExpenseId, expenses) => expenses.find((e) => e.id === activeExpenseId),
);

sels.expenseDefaultVals = createSelector(sels.activeExpense, (activeExpense) =>
  formatExpenseDefaultVals(activeExpense),
);

sels.expensesQuery = (st) => st.expensesQuery;

sels.expensesQueryFetchable = createSelector(
  [sels.site, sels.expensesQuery],
  (site, expensesQuery) => {
    return {...expensesQuery, 'filter[siteId]': site?.id};
  },
);

sels.documents = (st) => st.documents;

sels.documentsLoading = (st) => st.documentsLoading;

sels.documentEditorOpen = (st) => st.documentEditorOpen;

sels.activeDocumentId = (st) => st.activeDocumentId;

sels.activeDocument = createSelector(
  [sels.documents, sels.activeDocumentId],
  (documents, activeDocumentId) => documents.find((d) => d.id === activeDocumentId),
);

sels.documentEditorDefaultVals = createSelector(sels.activeDocument, (doc) => ({
  name: doc?.name || '',
}));

sels.employeeNoticeModalOpen = (st) => st.employeeNoticeModalOpen;

sels.employeeSelection = (st) => st.employeeSelection;

sels.employeeNoticeRows = (st) => st.employeeNoticeRows;

sels.employeeNoticeLoading = (st) => st.employeeNoticeLoading;

sels.employeeNoticeQuery = (st) => st.employeeNoticeQuery;

sels.employeeNoticeQueryFetchable = createSelector(
  [sels.employeeNoticeQuery, sels.site],
  (query, site) => formatFetchableEmployeeNoticesQuery(query, site),
);

sels.contractNoticeLoading = (st) => st.contractNoticeLoading;

sels.contractNoticeModalOpen = (st) => st.contractNoticeModalOpen;

sels.contractNoticeRows = (st) => st.contractNoticeRows;

sels.subContractorSelection = (st) => st.subContractorSelection;

sels.contractNoticeUrl = (st) => st.contractNoticeUrl;

sels.contractNoticeQuery = (st) => st.contractNoticeQuery;

sels.contractNoticeQueryFetchable = createSelector(
  [sels.contractNoticeQuery, sels.site],
  (dateRange, site) => formatContractNoticeQuery(dateRange, site),
);

export default scopedSelectors(namespace, sels);
