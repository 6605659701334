import React, {useState} from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalHeader,
  ModalCloseButton,
  ModalFooter,
  Stack,
  Input,
  FormControl,
  Text,
  FormLabel,
  InputRightElement,
  IconButton,
  InputGroup,
  Button,
  FormErrorMessage,
  HStack,
} from '@chakra-ui/react';
import {FiEye, FiEyeOff} from 'react-icons/fi';

import {useForm} from 'react-hook-form';
import {ReactSelect} from 'components';

const EmployeeModal = ({
  isOpen,
  isLoading,
  onClose = () => {},
  onSubmit = () => {},
  defaultValues = {},
  isNew = true,
  organizations,
}) => {
  const {
    handleSubmit,
    formState: {errors},
    control,
    watch,
    register,
  } = useForm({defaultValues});
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  const role = watch('role');

  const showPasswordInput = !role ? false : role === 'employee' ? false : true;

  const userTypes = [
    {value: 'employee', label: 'Työntekijä'},
    {value: 'manager', label: 'Työnantaja'},
    {value: 'admin', label: 'Admin'},
  ];

  const onSubmitActual = (formData) => {
    if (formData.password !== '') {
      onSubmit(formData);
    } else {
      const {password, ...data} = formData;
      onSubmit(data);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Työntekijä</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form onSubmit={handleSubmit(onSubmitActual)} id="addEmployee-form">
            <Stack spacing={4}>
              <HStack spacing={3} align="flex-start">
                <FormControl isInvalid={errors.name} isRequired>
                  <FormLabel>Nimi</FormLabel>
                  <Input
                    type="text"
                    {...register('name', {required: 'Pakollinen kenttä'})}
                  />
                  <FormErrorMessage>
                    {errors.name && errors.name.message}
                  </FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={errors.phone} isRequired>
                  <FormLabel>Puhelin</FormLabel>
                  <Input
                    type="text"
                    {...register('phone', {required: 'Pakollinen kenttä'})}
                  />
                  <FormErrorMessage>
                    {errors.phone && errors.phone.message}
                  </FormErrorMessage>
                </FormControl>
              </HStack>
              <FormControl isInvalid={errors.address} isRequired>
                <FormLabel>Osoite</FormLabel>
                <Input
                  type="text"
                  {...register('address', {required: 'Pakollinen kenttä'})}
                />
                <FormErrorMessage>
                  {errors.address && errors.address.message}
                </FormErrorMessage>
              </FormControl>
              <HStack spacing={3} align="flex-start">
                <FormControl isInvalid={errors.email} isRequired>
                  <FormLabel>Sähköposti</FormLabel>
                  <Input
                    type="text"
                    {...register('email', {required: 'Pakollinen kenttä'})}
                  />
                  <FormErrorMessage>
                    {errors.email && errors.email.message}
                  </FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={errors.birthday} isRequired>
                  <FormLabel>Syntymäaika</FormLabel>
                  <Input
                    type="text"
                    placeholder="esim. 01.01.1990"
                    {...register('birthday', {
                      required: 'Pakollinen kenttä',
                      pattern: {
                        value:
                          /(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d/,
                        message: 'Tarkista syntymäajan muoto',
                      },
                    })}
                  />
                  <FormErrorMessage>
                    {errors.birthday && errors.birthday.message}
                  </FormErrorMessage>
                </FormControl>
              </HStack>
              <HStack spacing={3} align="flex-start">
                <FormControl isInvalid={errors.ssn} isRequired>
                  <FormLabel>Sosiaaliturvatunnus</FormLabel>
                  <Input
                    type="text"
                    {...register('ssn', {required: 'Pakollinen kenttä'})}
                  />
                  <FormErrorMessage>{errors.ssn && errors.ssn.message}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={errors.vatNumber} isRequired>
                  <FormLabel>Veronumero</FormLabel>
                  <Input
                    type="number"
                    {...register('vatNumber', {
                      required: 'Pakollinen kenttä',
                      minLength: {
                        value: 12,
                        message: 'Veronumeron pituus tulee olla 12 numeroa pitkä.',
                      },
                    })}
                  />
                  <FormErrorMessage>
                    {errors.vatNumber && errors.vatNumber.message}
                  </FormErrorMessage>
                </FormControl>
              </HStack>

              <HStack spacing={3} align="flex-start">
                <FormControl isInvalid={errors.hourPrice}>
                  <FormLabel>Tuntipalkka</FormLabel>
                  <Input size="lg" type="number" {...register('hourPrice')}></Input>
                  <FormErrorMessage>
                    {errors.hourPrice && errors.hourPrice.message}
                  </FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={errors.jobTitle}>
                  <FormLabel>Tehtävänimike</FormLabel>
                  <Input size="lg" {...register('jobTitle')}></Input>
                  <FormErrorMessage>
                    {errors.jobTitle && errors.jobTitle.message}
                  </FormErrorMessage>
                </FormControl>
              </HStack>
              <FormControl isInvalid={errors.organizationId} isRequired>
                <FormLabel>Yritys</FormLabel>
                <ReactSelect
                  name="organizationId"
                  control={control}
                  rules={{
                    // provide explicit validation function, "required" didn't seem to work with react-select
                    validate: (val) => (val == null ? 'Pakollinen kenttä' : null),
                    valueAsNumber: true,
                  }}
                  options={organizations.map((e) => ({value: e.id, label: e.name}))}
                />
                <FormErrorMessage>
                  {errors.organizationId && errors.organizationId.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors.role}>
                <FormLabel>Tyyppi</FormLabel>
                <ReactSelect
                  name="role"
                  control={control}
                  rules={{
                    validate: (val) => (val == null ? 'Pakollinen kenttä' : null),
                  }}
                  options={userTypes}
                />
                <FormErrorMessage>{errors.role && errors.role.message}</FormErrorMessage>
              </FormControl>

              {showPasswordInput ? (
                <FormControl isInvalid={errors.password}>
                  <FormLabel>Salasana</FormLabel>
                  <InputGroup>
                    <Input
                      type={show ? 'text' : 'password'}
                      size="lg"
                      {...register(
                        'password',
                        showPasswordInput
                          ? {
                              required: 'Pakollinen kenttä',
                              minLength: {
                                value: 12,
                                message:
                                  'Salasanan tulee olla vähintään 12 merkkiä pitkä',
                              },
                            }
                          : null,
                      )}
                    />
                    <InputRightElement>
                      <IconButton
                        h="1.75rem"
                        size="sm"
                        onClick={handleClick}
                        icon={show ? <FiEyeOff /> : <FiEye />}
                      />
                    </InputRightElement>
                  </InputGroup>
                  <FormErrorMessage>
                    {errors.password && errors.password.message}
                  </FormErrorMessage>
                  <Text fontSize="sm">
                    Huom! Salasana mahdollistaa kirjautumisen hallintapaneeliin
                  </Text>
                </FormControl>
              ) : null}
            </Stack>
          </form>
        </ModalBody>

        <ModalFooter>
          <Button onClick={onClose} mr={3}>
            Peruuta
          </Button>
          <Button
            type="submit"
            form="addEmployee-form"
            variant="primary"
            isLoading={isLoading}
          >
            <span>Tallenna</span>
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EmployeeModal;
