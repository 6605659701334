import React from 'react';
import {Divider, Stack} from '@chakra-ui/react';

const FileList = ({children}) => {
  return (
    <Stack spacing="0" divider={<Divider />}>
      {children}
    </Stack>
  );
};

export default FileList;
