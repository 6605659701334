import {includes, reject, append, equals} from 'ramda';
import {startOfMonth, endOfMonth} from 'date-fns';
import namespace from './namespace';
import {scopedStore} from 'utils/redux';

export const initialState = {
  processing: false,
  organization: null,
  siteSubContractors: [],
  loading: true,
  site: null,
  users: [],
  addableEmployees: [],
  extraCosts: [],
  extraCostsLoading: true,
  extraCostsQuery: {
    'filter[startDate]': '',
  },
  siteModalOpen: false,
  employeeModalOpen: false,
  openedEmployeeId: null,
  selectedEmployeeId: null,
  updatedSiteEmployees: [],
  fileUploaderModalOpen: false,
  employeesQuery: {name: '', state: null, organization: null},
  expenseModalOpen: false,
  expenses: [],
  expensesLoading: true,
  activeExpenseId: null,
  expensesQuery: {
    'filter[title]': '',
  },
  documents: [],
  documentsLoading: true,
  documentEditorOpen: false,
  activeDocumentId: null,
  contractNoticeModalOpen: false,
  subContractorSelection: [],
  contractNoticeRows: [],
  contractNoticeLoading: false,
  contractNoticeQuery: {
    startDate: startOfMonth(new Date()),
    endDate: endOfMonth(new Date()),
  },
  contractNoticeUrl: null,
  employeeNoticeModalOpen: false,
  employeeSelection: [],
  employeeNoticeRows: [],
  employeeNoticeLoading: false,
  employeeNoticeQuery: {
    startDate: startOfMonth(new Date()),
    endDate: endOfMonth(new Date()),
    organizationId: '',
  },
  employeeNoticeUrl: null,
};

export const cases = {
  setSite: (st, all, payload) => {
    const {users, organization, subContractors, ...site} = payload;
    return {
      ...st,
      site,
      users,
      organization,
      siteSubContractors: subContractors,
      loading: false,
    };
  },

  setEmployeeNoticeUrl: (st, all, url) => {
    return {...st, employeeNoticeUrl: url};
  },

  setExtraCosts: (st, all, extraCosts) => {
    return {...st, extraCosts, extraCostsLoading: false};
  },

  setExtraCostsLoading: (st, all, value) => {
    return {...st, extraCostsLoading: value};
  },

  setSiteUsers: (st, all, users) => {
    return {...st, users};
  },

  setAddableEmployees: (st, all, addableEmployees) => {
    return {...st, addableEmployees, loading: false};
  },

  openFileUploaderModal: (st, all) => {
    return {...st, fileUploaderModalOpen: true};
  },

  closeFileUploaderModal: (st, all) => {
    return {...st, fileUploaderModalOpen: false};
  },

  updateEmployeesQuery: (st, all, query) => {
    return {...st, employeesQuery: query};
  },

  updateSite: (st, all, payload) => {
    return {
      ...st,
      site: {...st.site, ...payload},
      siteSubContractors: payload.subContractors,
    };
  },

  toggleSiteModal: (st) => {
    return {...st, siteModalOpen: !st.siteModalOpen};
  },

  openEmployeeModal: (st, all, employeeId) => {
    return {...st, employeeModalOpen: true, openedEmployeeId: employeeId};
  },

  setSelectedEmployeeId: (st, all, employeeId) => {
    return {...st, selectedEmployeeId: employeeId};
  },

  closeEmployeeModal: (st, all) => {
    return {...st, employeeModalOpen: false, openedEmployeeId: null};
  },

  setProcessing: (st, all, processing) => {
    return {...st, processing};
  },

  setLoading: (st, all, loading) => {
    return {...st, loading};
  },

  openExpenseModal: (st, all, expenseId) => {
    return {...st, expenseModalOpen: true, activeExpenseId: expenseId};
  },

  closeExpenseModal: (st) => {
    return {...st, expenseModalOpen: false, activeExpenseId: null};
  },

  setExpenses: (st, all, expenses) => {
    return {...st, expenses, expensesLoading: false};
  },

  updateExpensesQuery: (st, all, payload) => {
    return {
      ...st,
      expensesQuery: {...st.expensesQuery, ...payload},
      expensesLoading: true,
    };
  },

  setDocuments: (st, all, documents) => {
    return {...st, documents, documentsLoading: false};
  },

  updateDocument: (st, all, payload) => {
    return {
      ...st,
      documents: st.documents.map((d) => (d.id === payload.id ? {...d, ...payload} : d)),
    };
  },

  removeDocument: (st, all, id) => {
    return {...st, documents: st.documents.filter((d) => d.id !== id)};
  },

  openDocumentEditor: (st, all, documentId) => {
    return {...st, documentEditorOpen: true, activeDocumentId: documentId};
  },

  closeDocumentEditor: (st, all) => {
    return {...st, documentEditorOpen: false, activeDocumentId: null};
  },

  openEmployeeNoticeModal: (st, all) => {
    return {...st, employeeNoticeModalOpen: true, employeeNoticeLoading: true};
  },

  closeEmployeeNoticeModal: (st, all) => {
    return {
      ...st,
      employeeNoticeModalOpen: false,
      employeeNoticeLoading: false,
      employeeSelection: initialState.employeeSelection,
      employeeNoticeRows: initialState.employeeNoticeRows,
      employeeNoticeQuery: initialState.employeeNoticeQuery,
      employeeNoticeUrl: initialState.employeeNoticeUrl,
    };
  },

  setEmployeeNoticeRows: (st, all, employeeNoticeRows) => {
    return {...st, employeeNoticeRows, employeeNoticeLoading: false};
  },

  selectEmployees: (st, all, employees) => {
    let selection = st.employeeSelection;

    employees.forEach((e) => {
      if (includes(e.id, selection)) {
        selection = !e.selected ? reject(equals(e.id), selection) : selection;
      } else {
        selection = e.selected ? append(e.id, selection) : selection;
      }
    });

    return {...st, employeeSelection: selection};
  },

  clearEmployeeSelection: (st) => {
    return {...st, employeeSelection: initialState.employeeSelection};
  },

  updateEmployeeNoticeQuery: (st, all, payload) => {
    return {
      ...st,
      employeeNoticeQuery: {...st.employeeNoticeQuery, ...payload},
      employeeNoticeLoading: true,
    };
  },

  selectSubcontractors: (st, all, subContractors) => {
    let selection = st.subContractorSelection;

    subContractors.forEach((s) => {
      if (includes(s.id, selection)) {
        selection = !s.selected ? reject(equals(s.id), selection) : selection;
      } else {
        selection = s.selected ? append(s.id, selection) : selection;
      }
    });

    return {...st, subContractorSelection: selection};
  },

  setContractNoticeRows: (st, all, contractNoticeRows) => {
    return {...st, contractNoticeRows, contractNoticeLoading: false};
  },

  clearSubContractorSelection: (st) => {
    return {...st, subContractorSelection: []};
  },

  updateContractNoticeQuery: (st, all, payload) => {
    return {
      ...st,
      contractNoticeQuery: {...st.contractNoticeQuery, ...payload},
      contractNoticeLoading: true,
    };
  },

  selectSubContractors: (st, all, subContractors) => {
    let selection = st.subContractorSelection;

    subContractors.forEach((e) => {
      if (includes(e.id, selection)) {
        selection = !e.selected ? reject(equals(e.id), selection) : selection;
      } else {
        selection = e.selected ? append(e.id, selection) : selection;
      }
    });

    return {...st, subContractorSelection: selection};
  },

  openContractNoticeModal: (st, all) => {
    return {...st, contractNoticeModalOpen: true, contractNoticeLoading: true};
  },

  setContractNoticeUrl: (st, all, url) => {
    return {...st, contractNoticeUrl: url};
  },

  closeContractNoticeModal: (st, all) => {
    return {
      ...st,
      contractNoticeModalOpen: false,
      contractNoticeLoading: false,
      subContractorSelection: [],
      contractNoticeRows: initialState.contractNoticeRows,
      contractNoticeQuery: initialState.contractNoticeQuery,
    };
  },

  reset: (st) => {
    return initialState;
  },
};

export const {actions, reducer} = scopedStore(namespace, cases, initialState);
