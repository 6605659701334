import React from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  IconButton,
  Icon,
  Box,
  Center,
  Spinner,
  MenuButton,
  Text,
  Menu,
  MenuList,
  MenuItem,
} from '@chakra-ui/react';
import {FiChevronDown, FiMoreHorizontal} from 'react-icons/fi';
import {sToTime} from 'utils/time';

const EmployeesRow = ({
  userEntry,
  onRowClick,
  onEdit,
  onEditClick,
  setEditableEntries,
}) => {
  const totalTime = userEntry.totalTime.total;
  return (
    <Tr
      _hover={{bg: 'gray.50', cursor: 'pointer'}}
      onClick={(e) => {
        e.stopPropagation();
        setEditableEntries(userEntry.items);
        onRowClick();
      }}
    >
      <Td p={6}>
        <strong>{userEntry.user.name}</strong>
      </Td>
      <Td>{userEntry.site.name}</Td>
      <Td>{userEntry.user.organizationName}</Td>
      <Td>{sToTime(totalTime)}</Td>
      <Td textAlign="right">
        <Menu>
          <MenuButton
            as={IconButton}
            variant="ghost"
            size="md"
            aria-label="more"
            icon={<FiMoreHorizontal />}
            onClick={(e) => e.stopPropagation()}
          />
          <MenuList>
            <MenuItem
              onClick={(e) => {
                e.stopPropagation();
                onEditClick(userEntry.user.id);
              }}
            >
              <Text fontSize="sm" fontWeight="bold">
                Työntekijän sivulle
              </Text>
            </MenuItem>
          </MenuList>
        </Menu>
      </Td>
    </Tr>
  );
};

const EmployeesTab = ({
  userEntries = [],
  loading,
  onEmployeeClick,
  editTimeEntries,
  setEditableEntries,
}) => {
  return (
    <Box py={4}>
      <Table overflow="auto">
        <Thead>
          <Tr>
            <Th>
              Työntekijä
              <IconButton
                variant="ghost"
                colorScheme="blue"
                onClick={() => {}}
                size="sm"
                icon={<Icon as={FiChevronDown} />}
              />
            </Th>
            <Th>
              Työmaa
              <IconButton
                variant="ghost"
                colorScheme="blue"
                onClick={() => {}}
                size="sm"
                icon={<Icon as={FiChevronDown} />}
              />
            </Th>
            <Th>
              Organisaatio
              <IconButton
                variant="ghost"
                colorScheme="blue"
                onClick={() => {}}
                size="sm"
                icon={<Icon as={FiChevronDown} />}
              />
            </Th>
            <Th>
              Työtunnit
              <IconButton
                variant="ghost"
                colorScheme="blue"
                onClick={() => {}}
                size="sm"
                icon={<Icon as={FiChevronDown} />}
              />
            </Th>
            <Th></Th>
          </Tr>
        </Thead>
        {loading ? null : (
          <Tbody>
            {userEntries.map((userEntry) => (
              <EmployeesRow
                key={userEntry.site.id}
                userEntry={userEntry}
                onEditClick={onEmployeeClick}
                onRowClick={editTimeEntries}
                setEditableEntries={setEditableEntries}
              />
            ))}
          </Tbody>
        )}
      </Table>
      {loading ? (
        <Center p={6}>
          <Spinner size="xl" />
        </Center>
      ) : !userEntries.length ? (
        <Box p={6}>Ei kirjauksia</Box>
      ) : null}
    </Box>
  );
};

export default EmployeesTab;
