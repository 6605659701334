import React, {useEffect} from 'react';
import {
  SimpleGrid,
  Stack,
  Text,
  Center,
  Spinner,
  IconButton,
  Icon,
  Badge,
  Box,
  Button,
  Avatar,
  AvatarGroup,
} from '@chakra-ui/react';
import {Link} from 'react-router-dom';
import {pick} from 'ramda';
import {connect} from 'react-redux';
import {FiEdit, FiPlusCircle, FiFileText} from 'react-icons/fi';

import {applyState} from 'utils/redux';
import {getDateRangeStr} from 'utils/time';
import {encodeQuery, createReferrerUrl} from 'utils/url';
import effs from 'modules/sites/sitePage/effects';
import sels from 'modules/sites/sitePage/selectors';
import commonSels from 'modules/common/selectors';
import {fullAddress} from 'modules/sites/sitePage/utils';
import SiteEmployeeFilters from './components/siteEmployee-filters';
import SiteEmployeeModal from './components/siteEmployee-modal';
import CostCalculationFilters from './components/costCalculation-filters';
import CostCalculationTable from './components/costCalculation-table';
import DropzoneModal from './components/dropZone-modal';
import ExpenseModal from './components/expense-modal';
import DocumentEditorModal from './components/document-editor-modal';
import ExtraCostsTable from './components/extraCosts-table';
import ExtraCostsFilter from './components/extraCosts-filter';

import {
  Content,
  PageHeader,
  Card,
  CardHeader,
  List,
  ListItem,
  FileList,
  File,
} from 'components';
import SiteModal from '../sites/components/site-modal';
import SiteEmployeesTable from './components/siteEmployee-table';
import EmployeeNoticeModal from './components/employee-notice-modal';
import EmployeeCosts from './components/employeeCosts';
import {siteStatuses} from 'dicts/data';

const SiteStatusBadge = (status) => {
  if (status === 'Passiivinen') {
    return <Badge variant="muted">Passiivinen</Badge>;
  } else if (status === 'Aktiivinen') {
    return <Badge variant="primary">Aktiivinen</Badge>;
  }
};

const Site = ({
  match: {
    params: {siteId},
  },
  organizations,
  organizationId,
  employeeNoticeUrl,
  processing,
  loading,
  site,
  extraCosts,
  extraCostsLoading,
  siteModalOpen,
  siteDefaultVals,
  siteSubContractors,
  organization,
  employeeDefaultVals,
  selectedEmployee,
  selectedEmployeeId,
  employeeModalOpen,
  addableEmployees,
  users,
  location,
  history,
  fileUploaderModalOpen,
  query,
  filteredUsers,
  siteOrganizations,
  expenses,
  expensesLoading,
  expenseModalOpen,
  expenseDefaultVals,
  expensesQuery,
  openedEmployee,
  documents,
  documentsLoading,
  documentEditorOpen,
  documentEditorDefaultVals,
  employeeNoticeModalOpen,
  employeeSelection,
  employeeNoticeRows,
  employeeNoticeLoading,
  employeeNoticeQuery,
}) => {
  useEffect(() => {
    effs.initialize(Number(siteId));

    return () => {
      effs.destroy();
    };
  }, []);

  const addableOrganizations = organizations.filter((o) => o.id !== organizationId);

  const referrerUrl = createReferrerUrl(location);

  const openEmployee = (id) => {
    history.push(
      `/employees/${id}${encodeQuery({referrerUrl, referrerTitle: 'Työmaat'})}`,
    );
  };
  return (
    <Content>
      <PageHeader activeCrumbName={site?.name}>
        <Link
          to={{
            pathname: '/reportage',
            search: encodeQuery({
              referrerUrl,
              referrerTitle: site?.name,
              reportageIndex: 1,
              user: JSON.stringify({siteId: siteId}),
            }),
            params: siteId,
          }}
        >
          <Button
            marginLeft={4}
            onClick={() => {}}
            leftIcon={<FiFileText />}
            colorScheme="blue"
            variant="solid"
          >
            Työaikaraportti
          </Button>
        </Link>
      </PageHeader>

      {loading ? (
        <Center>
          <Spinner size="xl" />
        </Center>
      ) : (
        <SimpleGrid columns={{md: 2}} templateColumns="1fr 2fr" spacing="5">
          <Stack spacing={6}>
            <Card>
              <CardHeader
                isMain
                title={site.name}
                action={
                  <IconButton
                    onClick={() => effs.toggleSiteModal()}
                    size="sm"
                    icon={<Icon as={FiEdit} />}
                  />
                }
              />

              <List marginBottom="5">
                <ListItem
                  title="Organisaatio"
                  display="flex"
                  justifyContent="space-between"
                >
                  {organization.name}
                </ListItem>
                <ListItem title="Sijainti" display="flex" justifyContent="space-between">
                  <Text textAlign="right">{fullAddress(site)}</Text>
                </ListItem>
                <ListItem title="Aikataulu" display="flex" justifyContent="space-between">
                  <Text>{getDateRangeStr(site.startDate, site.endDate)}</Text>
                </ListItem>
                <ListItem
                  title="Kokonaisbudjetti"
                  display="flex"
                  justifyContent="space-between"
                >
                  <Text>{site.budget}€</Text>
                </ListItem>
                <ListItem title="Tila" display="flex" justifyContent="space-between">
                  {SiteStatusBadge(siteStatuses[site.state])}
                </ListItem>
                <ListItem
                  title="Alihankkijat"
                  display="flex"
                  justifyContent="space-between"
                >
                  <AvatarGroup max={2}>
                    {siteSubContractors.map((subContractor) => (
                      <Avatar
                        size="sm"
                        key={subContractor.id}
                        name={subContractor.contact}
                      />
                    ))}
                  </AvatarGroup>
                </ListItem>
              </List>
            </Card>

            <Card>
              <CardHeader
                title="Tiedostot"
                action={
                  <IconButton
                    onClick={effs.openDropzoneModal}
                    size="sm"
                    icon={<Icon as={FiPlusCircle} />}
                  />
                }
              />

              <FileList>
                {documentsLoading ? (
                  <Center p={6}>
                    <Spinner size="xl" />
                  </Center>
                ) : documents.length ? (
                  documents.map((doc) => (
                    <File
                      key={doc.id}
                      modified={doc.updatedAt}
                      onOpen={() => window.open(doc.url, '_blank')}
                      onEdit={() => effs.openDocumentEditor(doc.id)}
                      onDelete={() => effs.removeDocument(doc.id)}
                    >
                      {doc.name}
                    </File>
                  ))
                ) : (
                  <Text p={6}>Ei tiedostoja</Text>
                )}
              </FileList>
            </Card>
          </Stack>
          <Stack spacing={6}>
            <Card>
              <CardHeader
                title={`Työntekijät (${users.length})`}
                action={
                  <IconButton
                    onClick={() => {
                      effs.openEmployeeModal(null);
                    }}
                    size="sm"
                    icon={<Icon as={FiPlusCircle} />}
                  />
                }
              >
                <SiteEmployeeFilters
                  updateQuery={effs.updateEmployeesQuery}
                  siteOrganizations={siteOrganizations}
                />
              </CardHeader>

              {loading ? (
                <Center p={6}>
                  <Spinner size="xl" />
                </Center>
              ) : !filteredUsers.length ? (
                <Box p={6}>Ei työntekijöitä</Box>
              ) : (
                <SiteEmployeesTable
                  employees={filteredUsers}
                  site={site}
                  processing={processing}
                  removeEmployee={effs.removeEmployee}
                  onEmployeeClick={openEmployee}
                  editEmployeeDetails={(id) => effs.openEmployeeModal(id)}
                />
              )}
            </Card>
            <Card padding={6} display="flex" alignItems="center">
              <Box>
                <Text color="gray.600" textTransform="uppercase" textStyle="subheading">
                  Työntekijäkulut
                </Text>
              </Box>
              <EmployeeCosts users={users} site={site} />
            </Card>
            <Card>
              <CardHeader
                title="Kustannuslaskelma"
                action={
                  <IconButton
                    onClick={() => effs.openExpenseModal(null)}
                    size="sm"
                    icon={<Icon as={FiPlusCircle} />}
                  />
                }
              >
                <CostCalculationFilters
                  query={expensesQuery}
                  updateQuery={effs.updateExpensesQuery}
                />
              </CardHeader>
              {expensesLoading ? (
                <Center p={6}>
                  <Spinner />
                </Center>
              ) : !expenses.length ? (
                <Box p={6}>Ei kuluja</Box>
              ) : (
                <CostCalculationTable
                  expenses={expenses}
                  processing={processing}
                  openExpense={effs.openExpenseModal}
                  removeExpense={effs.removeExpense}
                />
              )}
            </Card>
            <Card>
              <CardHeader
                title="Erillisveloitettavat"
                action={
                  <IconButton
                    onClick={() => {}}
                    size="sm"
                    icon={<Icon as={FiPlusCircle} />}
                  />
                }
              >
                <ExtraCostsFilter />
              </CardHeader>
              {extraCostsLoading ? (
                <Center p={6}>
                  <Spinner />
                </Center>
              ) : !extraCosts.length ? (
                <Box p={6}>Ei erillisveloitettavia</Box>
              ) : (
                <ExtraCostsTable
                  extraCosts={extraCosts}
                  processing={processing}
                  removeExtaCost={effs.removeExtraCost}
                />
              )}
            </Card>
          </Stack>
        </SimpleGrid>
      )}

      {employeeModalOpen && (
        <SiteEmployeeModal
          isOpen={!!employeeModalOpen}
          isLoading={processing}
          onClose={() => effs.closeEmployeeModal()}
          addableEmployees={addableEmployees}
          onSubmit={openedEmployee ? effs.updateEmployeeToSite : effs.addEmployeeToSite}
          selectedEmployee={selectedEmployee}
          setSelectedEmployee={effs.setSelectedEmployeeId}
          defaultValues={employeeDefaultVals}
          selectedEmployeeId={selectedEmployeeId}
          openedEmployee={openedEmployee}
          organizationId={organizationId}
        />
      )}

      {siteModalOpen && (
        <SiteModal
          isOpen={siteModalOpen}
          isLoading={processing}
          onClose={() => effs.toggleSiteModal()}
          onSubmit={effs.updateSite}
          isNew={false}
          organizations={organizations}
          addableOrganizations={addableOrganizations}
          defaultSiteSubContractors={siteSubContractors}
          organizationId={organizationId}
          defaultValues={siteDefaultVals}
        />
      )}

      {fileUploaderModalOpen && (
        <DropzoneModal
          isOpen={fileUploaderModalOpen}
          onClose={effs.closeDropzoneModal}
          isLoading={processing}
          onSubmit={effs.uploadFiles}
        />
      )}

      {expenseModalOpen && (
        <ExpenseModal
          isOpen={expenseModalOpen}
          isLoading={processing}
          onClose={() => effs.closeExpenseModal()}
          onSubmit={effs.saveExpense}
          defaultValues={expenseDefaultVals}
        />
      )}

      {documentEditorOpen && (
        <DocumentEditorModal
          isOpen={documentEditorOpen}
          isLoading={processing}
          onClose={() => effs.closeDocumentEditor()}
          onSubmit={effs.updateDocument}
          defaultValues={documentEditorDefaultVals}
        />
      )}

      {employeeNoticeModalOpen && (
        <EmployeeNoticeModal
          employeeNoticeUrl={employeeNoticeUrl}
          isOpen={employeeNoticeModalOpen}
          isLoading={processing}
          onClose={() => effs.closeEmployeeNoticeModal()}
          onSubmit={() => effs.sendEmployeeNotice()}
          employeeRows={employeeNoticeRows}
          employeeRowsLoading={employeeNoticeLoading}
          selection={employeeSelection}
          selectEmployees={effs.selectEmployees}
          clearSelection={effs.clearEmployeeSelection}
          updateQuery={effs.updateEmployeeNoticeQuery}
          query={employeeNoticeQuery}
          organizations={siteOrganizations}
        />
      )}
    </Content>
  );
};

export default connect(
  applyState({
    ...pick(
      [
        'processing',
        'loading',
        'employeeNoticeUrl',
        'site',
        'siteModalOpen',
        'siteDefaultVals',
        'organization',
        'extraCosts',
        'extraCostsLoading',
        'employeeModalOpen',
        'employeeDefaultVals',
        'selectedEmployeeDefaultVals',
        'selectedEmployeeId',
        'selectedEmployee',
        'addableEmployees',
        'users',
        'fileUploaderModalOpen',
        'query',
        'filteredUsers',
        'selectSubContractors',
        'siteOrganizations',
        'siteSubContractors',
        'expenseModalOpen',
        'expenses',
        'expensesLoading',
        'expenseDefaultVals',
        'expensesQuery',
        'openedEmployee',
        'documents',
        'documentsLoading',
        'documentEditorOpen',
        'documentEditorDefaultVals',
        'employeeNoticeModalOpen',
        'employeeSelection',
        'employeeNoticeRows',
        'employeeNoticeLoading',
        'employeeNoticeQuery',
      ],
      sels,
    ),
    ...pick(['organizations', 'organizationId'], commonSels),
  }),
)(Site);
