import React from 'react';
import {Link} from 'react-router-dom';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalHeader,
  ModalCloseButton,
  ModalFooter,
  Button,
  Flex,
  HStack,
  Box,
  Text,
} from '@chakra-ui/react';
import ContractNoticeTable from './contract-notice-table';
import ContractNoticeFilters from './contract-notice-filters';

const ContractNoticeModal = ({
  isOpen,
  query,
  isLoading,
  onClose = () => {},
  onSubmit = () => {},
  selection = [],
  updateQuery,
  organizations = [],
  contractNoticeUrl,
  selectedSites,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} size="5xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Urakkailmoitus</ModalHeader>
        <ModalCloseButton />
        <ModalBody p={0}>
          <Flex
            px={6}
            py={4}
            bg="gray.50"
            alignItems="center"
            justifyContent="space-between"
          >
            <ContractNoticeFilters
              query={query}
              updateQuery={updateQuery}
              organizations={organizations}
            />
            <HStack spacing={8}>
              <Box display="flex" flexDirection="column" alignItems="end">
                <Text textStyle="label" color="gray.600">
                  Ilmoitettavat työmaat
                </Text>
                <Text fontWeight="bold">{selectedSites.length}</Text>
              </Box>
            </HStack>
          </Flex>
          {!selectedSites.length ? (
            <Box pt={6} px={6}>
              Ei alihankkijoita
            </Box>
          ) : (
            <ContractNoticeTable sites={selectedSites} />
          )}
        </ModalBody>
        <ModalFooter borderTopWidth={0}>
          <Button onClick={onClose} mr={3}>
            Peruuta
          </Button>
          {!contractNoticeUrl ? (
            <Button
              onClick={onSubmit}
              variant="primary"
              isLoading={isLoading}
              disabled={!selection.length}
            >
              <span>Luo ilmoitus</span>
            </Button>
          ) : (
            <Link to={{pathname: contractNoticeUrl}} download target="_top">
              <Button variant="primary">
                <span>Lataa tiedosto</span>
              </Button>
            </Link>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ContractNoticeModal;
