import React from 'react';
import {Link} from 'react-router-dom';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalHeader,
  ModalCloseButton,
  ModalFooter,
  Button,
  Flex,
  Box,
} from '@chakra-ui/react';
import EmployeeNoticeTable from './employee-notice-table';
import EmployeeNoticeFilters from './employee-notice-filters';

const EmployeeNoticeModal = ({
  isOpen,
  employeeNoticeUrl,
  isLoading,
  onClose = () => {},
  onSubmit = () => {},
  selectedSites,
  selectedContractSites,
  selection = [],
  updateQuery = () => {},
  query,
  organizations = [],
}) => {
  console.log(selectedContractSites, 'perse');
  // Merge contractSites and sites array to one array
  const sites = [...selectedSites, ...selectedContractSites];
  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} size="5xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Työntekijäilmoitus</ModalHeader>
        <ModalCloseButton />
        <ModalBody p={0}>
          <Flex
            px={6}
            py={4}
            bg="gray.50"
            alignItems="center"
            justifyContent="space-between"
          >
            <EmployeeNoticeFilters
              updateQuery={updateQuery}
              query={query}
              organizations={organizations}
            />
          </Flex>
          {!sites.length ? (
            <Box pt={6} px={6}>
              Ei valittuja työmaita
            </Box>
          ) : (
            <EmployeeNoticeTable selectedSites={sites} />
          )}
        </ModalBody>
        <ModalFooter borderTopWidth={0}>
          <Button onClick={onClose} mr={3}>
            Peruuta
          </Button>
          {!employeeNoticeUrl ? (
            <Button
              onClick={onSubmit}
              variant="primary"
              isLoading={isLoading}
              disabled={!selection.length}
            >
              <span>Luo ilmoitus</span>
            </Button>
          ) : (
            <Link to={{pathname: employeeNoticeUrl}} download target="_top">
              <Button variant="primary">
                <span>Lataa tiedosto</span>
              </Button>
            </Link>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EmployeeNoticeModal;
