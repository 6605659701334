import React, {useEffect, useState} from 'react';
import {
  HStack,
  Box,
  Spacer,
  Button,
  Text,
  Spinner,
  Center,
  VStack,
  Switch,
} from '@chakra-ui/react';
import {FiPlusCircle} from 'react-icons/fi';
import {pick} from 'ramda';
import {connect} from 'react-redux';

import {applyState} from 'utils/redux';
import {encodeQuery, createReferrerUrl} from 'utils/url';
import effs from 'modules/employees/employeesPage/effects';
import sels from 'modules/employees/employeesPage/selectors';
import commonSels from 'modules/common/selectors';

import {Content, Card} from 'components';
import EmployeeFilters from './components/employees-filters';
import EmployeesTable from './components/employees-table';
import EmployeeModal from './components/employees-modal';
import PresentEmployeesTable from './components/presentEmployees-table';

const Employees = ({
  history,
  location,
  processing,
  loading,
  pagination,
  employees,
  activeEmployees,
  employeeModalOpen,
  presentEmployeesModalOpen,
  organizations,
  query,
}) => {
  useEffect(() => {
    effs.initialize();

    return () => {
      effs.destroy();
    };
  }, []);

  const referrerUrl = createReferrerUrl(location);
  const [checked, setChecked] = useState(true);

  const openEmployee = (id) => {
    history.push(
      `/employees/${id}${encodeQuery({referrerUrl, referrerTitle: 'Työntekijät'})}`,
    );
  };

  return (
    <Content>
      <VStack spacing={6}>
        <Card w="full">
          <HStack
            px={6}
            py={8}
            verticalAlign="space-between"
            justifyContent="space-between"
          >
            <Text textStyle="h1">Aktiiviset työntekijät</Text>
            <HStack pr={6}>
              <Text>Näytä aktiiviset työntekijät</Text>
              <Switch
                isChecked={checked}
                defaultChecked={!checked}
                onChange={() => {
                  setChecked(!checked);
                }}
              />
            </HStack>
          </HStack>

          {checked ? (
            loading ? (
              <Center p={6}>
                <Spinner size="xl" />
              </Center>
            ) : !activeEmployees.length ? (
              <Box p={6}>Ei aktiivisia työntekijöitä</Box>
            ) : (
              <PresentEmployeesTable
                employees={activeEmployees}
                processing={processing}
                openEmployee={openEmployee}
                referrerUrl={referrerUrl}
              />
            )
          ) : null}
        </Card>
        <Card w="full">
          <HStack px={6} py={8} verticalAlign="space-between">
            <Text textStyle="h1">Työntekijät</Text>
            <Spacer />
            <Button onClick={() => effs.openEmployeeModal()} leftIcon={<FiPlusCircle />}>
              Lisää
            </Button>
          </HStack>
          <EmployeeFilters
            query={query}
            organizations={organizations}
            updateQuery={effs.updateQuery}
          />
          {loading ? (
            <Center p={6}>
              <Spinner size="xl" />
            </Center>
          ) : !employees.length ? (
            <Box p={6}>Ei työntekijöitä</Box>
          ) : (
            <EmployeesTable
              employees={employees}
              pagination={pagination}
              processing={processing}
              onPageSelect={(page) => effs.updateQuery({page})}
              openEmployee={openEmployee}
              removeEmployee={effs.removeEmployee}
              referrerUrl={referrerUrl}
            />
          )}
        </Card>
      </VStack>

      {employeeModalOpen && (
        <EmployeeModal
          isOpen={employeeModalOpen}
          isLoading={processing}
          organizations={organizations}
          onClose={() => effs.closeEmployeeModal()}
          onSubmit={effs.createEmployee}
        />
      )}
    </Content>
  );
};

export default connect(
  applyState({
    ...pick(
      [
        'processing',
        'loading',
        'employeeModalOpen',
        'presentEmployeesModalOpen',
        'employees',
        'pagination',
        'query',
        'organizations',
        'activeEmployees',
      ],
      sels,
    ),
    ...pick(['organizations'], commonSels),
  }),
)(Employees);
