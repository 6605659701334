import namespace from './namespace';
import {scopedSelectors} from 'utils/redux';

let sels = {};

sels.loading = (st) => st.loading;

sels.processing = (st) => st.processing;

export default scopedSelectors(namespace, sels);
