import React, {useEffect} from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalHeader,
  ModalCloseButton,
  ModalFooter,
  Stack,
  Input,
  FormControl,
  FormLabel,
  Button,
  FormErrorMessage,
  HStack,
  Select,
  Divider,
  Checkbox,
} from '@chakra-ui/react';
import {useForm} from 'react-hook-form';
import {ReactDatepicker, ReactSelect} from 'components';

const SiteEmployeeModal = ({
  addableEmployees,
  isOpen,
  isLoading,
  onClose = () => {},
  onSubmit = () => {},
  defaultValues = {},
  setSelectedEmployee,
  selectedEmployeeId,
  openedEmployee,
  organizationId,
}) => {
  const {
    handleSubmit,
    watch,
    formState: {errors},
    control,
    register,
  } = useForm({
    defaultValues,
  });

  useEffect(async () => {
    watch((value) => {
      setSelectedEmployee(value.id);
    });
  }, [watch]);

  const selectedEmployee = selectedEmployeeId
    ? addableEmployees.find((u) => u.id === selectedEmployeeId)
    : null;

  const employeeWage = () => {
    if (selectedEmployee?.organizationId === organizationId) {
      return selectedEmployee?.baseWage * selectedEmployee?.wageMultiplier;
    } else {
      return selectedEmployee?.baseWage;
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
      closeOnOverlayClick={false}
      closeOnEsc={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {openedEmployee ? 'Muokkaa työntekijää' : 'Lisää työntekijä'}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form onSubmit={handleSubmit(onSubmit)} id="employee-form">
            <Stack spacing={4}>
              {openedEmployee ? (
                <FormControl>
                  <FormLabel>Työntekijä</FormLabel>
                  <Input value={openedEmployee.name} disabled />
                </FormControl>
              ) : (
                <FormControl isInvalid={errors.id}>
                  <FormLabel>Työntekijä</FormLabel>
                  <ReactSelect
                    name="id"
                    control={control}
                    rules={{
                      // provide explicit validation function, "required" didn't seem to work with react-select
                      validate: (val) => (val == null ? 'Pakollinen kenttä' : null),
                      valueAsNumber: true,
                    }}
                    options={addableEmployees.map((e) => ({
                      value: e.id,
                      label: e.name,
                    }))}
                  />
                  <FormErrorMessage>{errors.id && errors.id.message}</FormErrorMessage>
                </FormControl>
              )}
              <Divider />
              <ModalHeader borderWidth={0} padding={(4, 0, 4, 0)}>
                Työmaakohtaiset tiedot
              </ModalHeader>
              <HStack spacing={3} align="flex-start">
                <FormControl isInvalid={errors.employmentStart}>
                  <FormLabel>Työsuhde (alkaen)</FormLabel>
                  <ReactDatepicker name="employmentStart" control={control} />
                  <FormErrorMessage>
                    {errors.employmentStart && errors.employmentStart.message}
                  </FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={errors.employmentEnd}>
                  <FormLabel>Työsuhde (päättyen)</FormLabel>
                  <ReactDatepicker name="employmentEnd" control={control} />
                  <FormErrorMessage>
                    {errors.employmentEnd && errors.employmentEnd.message}
                  </FormErrorMessage>
                </FormControl>
              </HStack>
              <HStack spacing={3} align="flex-start" display="flex" alignItems="center">
                <FormControl isInvalid={errors.hourPrice}>
                  <FormLabel>
                    {openedEmployee && openedEmployee.organizationId === organizationId
                      ? `Tuntihinta sis. sivukulut (€)`
                      : `Tuntihinta (€)`}
                  </FormLabel>
                  {openedEmployee ? (
                    <Input
                      type="number"
                      {...register('hourPrice')}
                      disabled={
                        openedEmployee && openedEmployee.organizationId === organizationId
                      }
                    />
                  ) : (
                    <Input
                      type="number"
                      {...register('hourPrice')}
                      disabled={selectedEmployee?.organizationId === organizationId}
                      placeholder={employeeWage()}
                    />
                  )}

                  <FormErrorMessage>
                    {errors.hourPrice && errors.hourPrice.message}
                  </FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={errors.includingtocontract}>
                  <Checkbox {...register('includingtocontract')}>
                    Sisältyy urakkaan
                  </Checkbox>
                  <FormErrorMessage>
                    {errors.includingtocontract && errors.includingtocontract.message}
                  </FormErrorMessage>
                </FormControl>
              </HStack>
              <FormControl isInvalid={errors.sitePermission}>
                <Select
                  {...register('sitePermission', {
                    required: 'Pakollinen kenttä',
                  })}
                >
                  <option value="administrator">Admin</option>
                  <option value="employee">Työntekijä</option>
                </Select>
                <FormErrorMessage>
                  {errors.sitePermission && errors.sitePermission.message}
                </FormErrorMessage>
              </FormControl>
            </Stack>
          </form>
        </ModalBody>

        <ModalFooter>
          <Button onClick={onClose} mr={3}>
            Peruuta
          </Button>
          <Button
            type="submit"
            form="employee-form"
            variant="primary"
            isLoading={isLoading}
          >
            <span>Tallenna</span>
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default SiteEmployeeModal;
