export const activityStatuses = {
  present: 'Paikalla',
  break: 'Tauko',
  pickup: 'Nouto',
  away: 'Poissa',
  transition: 'Siirtymä',
};

export const siteStatuses = {
  active: 'Aktiivinen',
  inactive: 'Poissa',
};
