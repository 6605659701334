import {format, parseISO} from 'date-fns';

export const getDateRangeStr = (startDate, endDate) => {
  const startStr = startDate ? `${format(parseISO(startDate), 'd.M.yyyy')} ` : '';
  const endStr = endDate ? `${format(parseISO(endDate), 'd.M.yyyy')} ` : '';

  return `${startStr} - ${endStr}`;
};

export const formatDate = (date) => {
  return format(date, 'yyyy-MM-dd');
};

const hoursToSeconds = 60 * 60;

const formatTimePart = (num) => {
  if (num < 10) {
    return `0${num}`;
  }
  if (num < 0) {
    return `00`;
  }
  return String(num);
};

export const sToTime = (s) => {
  const hours = Math.floor(s / hoursToSeconds);
  const minutes = Math.round((s / 60) % 60);
  return `${formatTimePart(hours)}h ${formatTimePart(minutes)}min`;
};
