import {prop} from 'ramda';
import services from 'services';
import {describeThrow} from 'utils/errors';
import msgs from 'dicts/messages';

let httpJson = null;
services.waitFor('api').then((x) => (httpJson = x.httpJson));

export const putTimeEntries = (data) =>
  httpJson('post', '/timeEntries/updateTimeEntries', {}, {body: data})
    .catch(describeThrow(msgs.saveFailed))
    .then(prop('data'));
