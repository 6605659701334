import React from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  IconButton,
  Badge,
  Menu,
  Portal,
  MenuButton,
  MenuList,
  MenuItem,
  Text,
  Icon,
  Link,
} from '@chakra-ui/react';
import {FiMoreHorizontal, FiChevronDown} from 'react-icons/fi';
import {activityStatuses} from 'dicts/data';
import {sToTime} from 'utils/time';
import {Link as RouterLink} from 'react-router-dom';

import {encodeQuery} from 'utils/url';

const SiteEmployeeRow = ({
  employee,
  onEmployeeClick,
  onDelete,
  onEdit,
  site,
  referrerUrl,
}) => {
  const ActivityStatusBadge = (activityStatus) => {
    if (activityStatus === 'Poissa') {
      return <Badge variant="muted">Poissa</Badge>;
    } else if (activityStatus === 'Paikalla') {
      return <Badge variant="primary">Paikalla</Badge>;
    } else if (activityStatus === 'Tauko') {
      return <Badge variant="danger">Tauko</Badge>;
    } else if (activityStatus === 'Nouto') {
      return <Badge variant="warning">Nouto</Badge>;
    } else if (activityStatus === 'Siirtymä') {
      return <Badge variant="success">Siirtymä</Badge>;
    }
  };

  const showCorrectEmployeeWages = () => {
    if (site.organizationId !== employee.organizationId) {
      return employee.hourPrice;
    } else {
      return employee.baseWage * employee.wageMultiplier;
    }
  };

  return (
    <Tr
      _hover={{bg: 'gray.50', cursor: 'pointer'}}
      onClick={() => {
        onEdit(employee.id);
      }}
    >
      <Td textStyle="small">
        <Link
          onClick={(e) => {
            e.stopPropagation();
            onEmployeeClick(employee.id);
          }}
          as={RouterLink}
          to={`/employees/${employee.id}${encodeQuery({
            referrerUrl,
            referrerTitle: 'Työntekijä',
          })}`}
        >
          <strong>{employee.name}</strong>
        </Link>
      </Td>
      <Td textStyle="small">{employee.organization.name}</Td>
      <Td textStyle="small">{employee.jobTitle}</Td>
      <Td textStyle="small">{sToTime(employee.siteMonthlyTime)}</Td>
      <Td textStyle="small">{showCorrectEmployeeWages()}€/h</Td>
      <Td>{ActivityStatusBadge(activityStatuses[employee.activityStatus])}</Td>
      <Td textAlign="right">
        <Menu>
          <MenuButton
            as={IconButton}
            variant="ghost"
            size="md"
            aria-label="more"
            icon={<FiMoreHorizontal />}
            onClick={(e) => e.stopPropagation()}
          />
          <Portal>
            <MenuList>
              <MenuItem
                onClick={(e) => {
                  e.stopPropagation();
                  onEmployeeClick(employee.id);
                }}
              >
                <Text fontSize="sm" fontWeight="bold">
                  Avaa työntekijä
                </Text>
              </MenuItem>
              <MenuItem
                onClick={(e) => {
                  e.stopPropagation();
                  onDelete(employee);
                }}
              >
                <Text fontSize="sm" fontWeight="bold" color="red.600">
                  Poista
                </Text>
              </MenuItem>
            </MenuList>
          </Portal>
        </Menu>
      </Td>
    </Tr>
  );
};

const SiteEmployeesTable = ({
  employees,
  site,
  onEmployeeClick,
  removeEmployee,
  editEmployeeDetails,
}) => {
  return (
    <>
      <Table overflow="auto">
        <Thead>
          <Tr whiteSpace="nowrap">
            <Th>
              Nimi
              <IconButton
                variant="ghost"
                colorScheme="blue"
                onClick={() => {}}
                size="sm"
                icon={<Icon as={FiChevronDown} />}
              />
            </Th>
            <Th whiteSpace="nowrap">
              Yritys
              <IconButton
                variant="ghost"
                colorScheme="blue"
                onClick={() => {}}
                size="sm"
                icon={<Icon as={FiChevronDown} />}
              />
            </Th>
            <Th whiteSpace="nowrap">
              Tehtävänimike
              <IconButton
                colorScheme="blue"
                variant="ghost"
                onClick={() => {}}
                size="sm"
                icon={<Icon as={FiChevronDown} />}
              />
            </Th>
            <Th whiteSpace="nowrap">
              Työtunnit
              <IconButton
                variant="ghost"
                colorScheme="blue"
                onClick={() => {}}
                size="sm"
                icon={<Icon as={FiChevronDown} />}
              />
            </Th>
            <Th whiteSpace="nowrap">
              Palkka
              <IconButton
                colorScheme="blue"
                variant="ghost"
                onClick={() => {}}
                size="sm"
                icon={<Icon as={FiChevronDown} />}
              />
            </Th>
            <Th whiteSpace="nowrap">
              Tila
              <IconButton
                variant="ghost"
                colorScheme="blue"
                onClick={() => {}}
                size="sm"
                icon={<Icon as={FiChevronDown} />}
              />
            </Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {employees.map((e) => (
            <SiteEmployeeRow
              key={e.id}
              employee={e}
              site={site}
              onEmployeeClick={onEmployeeClick}
              onDelete={removeEmployee}
              onEdit={editEmployeeDetails}
            />
          ))}
        </Tbody>
      </Table>
    </>
  );
};

export default SiteEmployeesTable;
