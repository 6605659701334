import React from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  IconButton,
  Menu,
  Portal,
  MenuButton,
  MenuList,
  MenuItem,
  Text,
} from '@chakra-ui/react';
import {FiMoreHorizontal} from 'react-icons/fi';

const PermitRow = ({permit, onRowClick, onDelete}) => {
  return (
    <Tr
      _hover={{bg: 'gray.50', cursor: 'pointer'}}
      onClick={() => {
        onRowClick(permit.id);
      }}
    >
      <Td textStyle="small">{permit.name}</Td>
      <Td textStyle="small">{permit.description}</Td>
      <Td textStyle="small">{permit.expiresAt}</Td>
      <Td textStyle="small">{permit.number}</Td>
      <Td textStyle="small">{permit.admitter}</Td>
      <Td textAlign="right">
        <Menu>
          <MenuButton
            as={IconButton}
            variant="ghost"
            size="md"
            aria-label="more"
            icon={<FiMoreHorizontal />}
            onClick={(e) => e.stopPropagation()}
          />
          <Portal>
            <MenuList>
              <MenuItem
                onClick={(e) => {
                  e.stopPropagation();
                  onDelete(permit);
                }}
              >
                <Text fontSize="sm" fontWeight="bold" color="red.600">
                  Poista
                </Text>
              </MenuItem>
            </MenuList>
          </Portal>
        </Menu>
      </Td>
    </Tr>
  );
};

const WorkPermitTable = ({workPermits, editPermit, onDelete}) => {
  return (
    <>
      <Table overflow="auto">
        <Thead>
          <Tr>
            <Th>Nimi</Th>
            <Th>Kuvaus</Th>
            <Th>Voimassaolo</Th>
            <Th>Numero</Th>
            <Th>Myöntäjä</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {workPermits.map((p) => (
            <PermitRow
              key={p.id}
              permit={p}
              onRowClick={editPermit}
              onDelete={onDelete}
            />
          ))}
        </Tbody>
      </Table>
    </>
  );
};

export default WorkPermitTable;
