import namespace from './namespace';
import {scopedSelectors} from 'utils/redux';
import cache from './cache';

let sels = {};

sels.opts = (st) => {
  const {onCancel, onOk} = cache.read();
  return {...st, showCancel: !!onCancel, showOk: !!onOk};
};

export default scopedSelectors(namespace, sels);
