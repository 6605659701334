import React from 'react';
import {useForm} from 'react-hook-form';
import {Stack, FormControl, FormErrorMessage, FormLabel, Input} from '@chakra-ui/react';

const LoginForm = ({onSubmit, id}) => {
  const {
    handleSubmit,
    register,
    formState: {errors},
  } = useForm();

  return (
    <form onSubmit={handleSubmit(onSubmit)} id={id}>
      <Stack spacing={4}>
        <FormControl isInvalid={errors.email}>
          <FormLabel>Käyttäjätunnus</FormLabel>
          <Input type="email" {...register('email', {required: 'Pakollinen kenttä'})} />
          <FormErrorMessage>{errors.email && errors.email.message}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={errors.password}>
          <FormLabel>Salasana</FormLabel>
          <Input
            type="password"
            {...register('password', {required: 'Pakollinen kenttä'})}
          />
          <FormErrorMessage>
            {errors.password && errors.password.message}
          </FormErrorMessage>
        </FormControl>
      </Stack>
    </form>
  );
};

export default LoginForm;
