import {evolve} from 'ramda';
import {replace} from 'utils/objects';
import {initialState} from './store';
import {startOfDay, endOfDay} from 'date-fns';

export const parseUrlQuery = (query) => {
  const vals = evolve(
    {
      page: Number,
    },
    query,
  );
  return replace(vals, initialState.query);
};

export const formatFetchableQuery = (query) => {
  return query;
};

export const formatFetchableEmployeeNoticesQuery = (query, site) => {
  return {
    groupByUser: true,
    'filter[siteId]': site?.id,
    'filter[startDateBetween]': [
      query.startDate ? startOfDay(query.startDate).toISOString() : null,
      query.endDate ? endOfDay(query.endDate).toISOString() : null,
    ],
    'filter[processed]': 0,
    'filter[organizationId]': query.organizationId,
  };
};

export const formatContractNoticeQuery = (dateRange, site) => {
  return {
    groupByOrganization: true,
    siteId: site.id,
    'filter[startDateBetween]': [
      dateRange.startDate ? startOfDay(dateRange.startDate).toISOString() : null,
      dateRange.endDate ? endOfDay(dateRange.endDate).toISOString() : null,
    ],
  };
};
