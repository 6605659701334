const global = {
  body: {
    fontSize: 'md',
    color: 'gray.800',
    lineHeight: 'tall',
    bg: 'gray.100',
  },
  a: {
    color: 'blue.500',
  },
};

export default global;
