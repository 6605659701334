import namespace from './namespace';
import {scopedSelectors} from 'utils/redux';
import {createSelector} from 'reselect';
import {formatFetchableQuery} from './utils';

let sels = {};

sels.organizations = (st) => st.organizations;

sels.pagination = (st) => st.pagination;

sels.query = (st) => st.query;

sels.loading = (st) => st.loading;

sels.processing = (st) => st.processing;

sels.queryFetchable = createSelector(sels.query, (q) => formatFetchableQuery(q));

sels.organizationModalOpen = (st) => st.organizationModalOpen;

export default scopedSelectors(namespace, sels);
