const textStyles = {
  h1: {
    fontSize: '2xl',
    fontWeight: 'bold',
    lineHeight: '6',
  },
  h2: {
    fontSize: 'lg',
    fontWeight: 'bold',
    lineHeight: '6',
  },
  h3: {
    fontSize: 'md',
    fontWeight: 'bold',
    lineHeight: '6',
  },
  body: {
    fontSize: 'md',
    fontWeight: 'regular',
    lineHeight: '6',
  },
  small: {
    fontSize: 'sm',
    fontWeight: 'regular',
    lineHeight: '4',
  },
  subheading: {
    fontSize: 'sm',
    fontWeight: 'bold',
    lineHeight: '4',
    textTransform: 'uppercase',
    letterSpacing: 'wide',
  },
  caption: {
    fontSize: 'xs',
    fontWeight: 'regular',
    lineHeight: '4',
  },
  label: {
    fontSize: 'xs',
    fontWeight: 'bold',
    lineHeight: '4',
  },
};

export default textStyles;
