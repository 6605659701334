import React from 'react';
import {Button, IconButton, HStack, Icon, useMediaQuery} from '@chakra-ui/react';
import {
  FiChevronLeft,
  FiChevronRight,
  FiChevronsLeft,
  FiChevronsRight,
} from 'react-icons/fi';

const PageButton = ({children, isActive, nonFunctioning, ...props}) => {
  return (
    <Button
      size="sm"
      variant="pagination"
      transition="none"
      isActive={isActive}
      cursor={nonFunctioning ? 'not-allowed' : 'pointer'}
      {...props}
    >
      {children}
    </Button>
  );
};

const Paginator = ({totalPages, currentPage, onPageSelect = () => {}, disabled}) => {
  const [isXs] = useMediaQuery('(max-width: 30em)');
  const maxButtons = isXs ? 2 : 4;
  const range = Math.floor(maxButtons / 2);

  const renderItems = (totalPages, currentPage, onPageSelect, maxButtons, disabled) => {
    let range = Math.floor(maxButtons / 2);
    let items = [];
    const max = totalPages <= maxButtons ? totalPages : maxButtons;

    let startingPage = currentPage > range ? currentPage - range : 1;

    const lastPage =
      max + startingPage <= totalPages ? max + startingPage - 1 : totalPages;

    const rangeBuffer = totalPages - (currentPage + range);

    startingPage =
      rangeBuffer < 0
        ? startingPage + rangeBuffer > 0
          ? startingPage + rangeBuffer
          : startingPage
        : startingPage;

    for (let i = startingPage; i <= lastPage; i++) {
      items.push(
        <PageButton
          key={i}
          isActive={i === currentPage}
          onClick={() => (i === currentPage ? null : onPageSelect(i))}
          disabled={disabled}
        >
          {i}
        </PageButton>,
      );
    }

    return items;
  };

  return (
    <HStack justifyContent={{base: 'center', md: 'flex-end'}} spacing="1" marginTop="5">
      <IconButton
        icon={<Icon as={FiChevronsLeft} />}
        variant="pagination"
        size="sm"
        isDisabled={currentPage === 1 || disabled}
        onClick={() => onPageSelect(1)}
      />
      <IconButton
        icon={<Icon as={FiChevronLeft} />}
        variant="pagination"
        size="sm"
        isDisabled={currentPage <= 1 || disabled}
        onClick={() => onPageSelect(currentPage - 1)}
      />
      {renderItems(totalPages, currentPage, onPageSelect, maxButtons, disabled)}
      {currentPage < totalPages - range && totalPages > maxButtons && (
        <PageButton nonFunctioning disabled={disabled} style={{width: '32px'}}>
          ...
        </PageButton>
      )}
      {currentPage < totalPages - range && totalPages > maxButtons && (
        <PageButton onClick={() => onPageSelect(totalPages)} disabled={disabled}>
          {totalPages}
        </PageButton>
      )}
      <IconButton
        icon={<Icon as={FiChevronRight} />}
        variant="pagination"
        size="sm"
        isDisabled={currentPage >= totalPages || disabled}
        onClick={() => onPageSelect(currentPage + 1)}
      />
      <IconButton
        icon={<Icon as={FiChevronsRight} />}
        variant="pagination"
        size="sm"
        isDisabled={currentPage === totalPages || disabled}
        onClick={() => onPageSelect(totalPages)}
      />
    </HStack>
  );
};

export default Paginator;
