import React, {useEffect, useState, useRef} from 'react';
import {HStack, FormControl, FormLabel} from '@chakra-ui/react';
import {ReactDatepicker} from 'components';

const TimeEntryFilters = ({query, updateQuery}) => {
  const [startDate, setStartDate] = useState(query.startDate);
  const [endDate, setEndDate] = useState(query.endDate);

  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }

    if (!(startDate && endDate)) {
      return;
    }

    updateQuery({startDate, endDate});
  }, [startDate, endDate]);

  return (
    <HStack>
      <FormControl>
        <FormLabel>Alkaen</FormLabel>
        <ReactDatepicker
          name="startDate"
          standalone
          maxDate={endDate}
          selected={query.startDate}
          onChange={(date) => setStartDate(date)}
        />
      </FormControl>
      <FormControl>
        <FormLabel>Päättyen</FormLabel>
        <ReactDatepicker
          name="endDate"
          standalone
          minDate={startDate}
          selected={query.endDate}
          onChange={(date) => setEndDate(date)}
        />
      </FormControl>
    </HStack>
  );
};

export default TimeEntryFilters;
