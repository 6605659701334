import {createSelector} from 'reselect';
import namespace from './namespace';
import {scopedSelectors} from 'utils/redux';
import {formatFetchableQuery} from './utils';

let sels = {};

sels.processing = (st) => st.processing;

sels.loading = (st) => st.loading;

sels.siteModalOpen = (st) => st.siteModalOpen;

sels.query = (st) => st.query;

sels.contractSitesQuery = (st) => st.contractSitesQuery;

sels.queryFetchable = createSelector(sels.query, (q) => formatFetchableQuery(q));

sels.contractSitesQueryFetchable = createSelector(sels.contractSitesQuery, (q) =>
  formatFetchableQuery(q),
);

sels.sites = (st) => st.sites;

sels.contractSites = (st) => st.contractSites;

sels.pagination = (st) => st.pagination;

sels.contractSitesPagination = (st) => st.contractSitesPagination;

sels.employeeNoticeModalOpen = (st) => st.employeeNoticeModalOpen;

sels.employeeSelection = (st) => st.employeeSelection;

sels.employeeNoticeRows = (st) => st.employeeNoticeRows;

sels.employeeNoticeLoading = (st) => st.employeeNoticeLoading;

sels.employeeNoticeUrl = (st) => st.employeeNoticeUrl;

sels.employeeNoticeQuery = (st) => st.employeeNoticeQuery;

// sels.employeeNoticeQueryFetchable = createSelector(
//   [sels.employeeNoticeQuery, sels.site],
//   (query, site) => formatFetchableEmployeeNoticesQuery(query, site),
// );

sels.contractNoticeLoading = (st) => st.contractNoticeLoading;

sels.contractNoticeModalOpen = (st) => st.contractNoticeModalOpen;

sels.contractNoticeRows = (st) => st.contractNoticeRows;

sels.siteSelection = (st) => st.siteSelection;

sels.contractNoticeUrl = (st) => st.contractNoticeUrl;

sels.contractNoticeDateRange = (st) => st.contractNoticeDateRange;

sels.selectedSites = createSelector(
  [sels.sites, sels.siteSelection],
  (sites, siteSelection) => {
    const selectedSites = sites.filter((site) => {
      return siteSelection.some((siteId) => {
        return siteId === site.id;
      });
    });
    return selectedSites;
  },
);

sels.selectedContractSites = createSelector(
  [sels.contractSites, sels.siteSelection],
  (contractSites, siteSelection) => {
    const selectedSites = contractSites.filter((site) => {
      return siteSelection.some((siteId) => {
        return siteId === site.id;
      });
    });
    return selectedSites;
  },
);

sels.selectedSitesToSend = createSelector([sels.selectedSites], (selectedSites) => {
  const sitesToSend = selectedSites.map((site) => ({
    id: site.id,
    subContractors: site.subContractors.map((subContractor) => subContractor.id),
  }));
  return sitesToSend;
});

sels.selectedEmployeeNoticeSites = createSelector(
  [sels.selectedSites, sels.selectedContractSites],
  (selectedSites, selectedContractSites) => {
    const mappedSites = selectedSites.map((site) => ({
      id: site.id,
      employees: site.users.map((user) => user.id),
    }));

    const mappedContractSites = selectedContractSites.map((site) => ({
      id: site.id,
      employees: site.users.map((user) => user.id),
    }));

    return [...mappedSites, ...mappedContractSites];
  },
);
// sels.contractNoticeQueryFetchable = createSelector(
//   [sels.contractNoticeQuery, sels.site],
//   (dateRange, site) => formatContractNoticeQuery(dateRange, site),
// );

export default scopedSelectors(namespace, sels);
