import React from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  IconButton,
  Menu,
  Portal,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  Text,
  Icon,
} from '@chakra-ui/react';
import {FiMoreHorizontal, FiChevronDown} from 'react-icons/fi';
import {format, parseISO} from 'date-fns';
import {sToTime} from 'utils/time';

const ExtraCostRow = ({extraCost, onDelete, onEdit}) => {
  return (
    <Tr _hover={{bg: 'gray.50', cursor: 'pointer'}}>
      <Td textStyle="small">
        <strong>{extraCost.name}</strong>
      </Td>
      <Td textStyle="small">{extraCost.user.name}</Td>
      <Td textStyle="small">{sToTime(extraCost.duration)}</Td>
      <Td textStyle="small">{format(parseISO(extraCost.createdAt), 'd.M.yyyy')}</Td>
      <Td textAlign="right">
        <Menu>
          <MenuButton
            as={IconButton}
            variant="ghost"
            size="md"
            aria-label="more"
            icon={<FiMoreHorizontal />}
            onClick={(e) => e.stopPropagation()}
          />
          <Portal>
            <MenuList>
              <MenuItem
                onClick={(e) => {
                  e.stopPropagation();
                  //onEdit(extraCost.id);
                }}
              >
                <Text fontSize="sm" fontWeight="bold">
                  Muokkaa
                </Text>
              </MenuItem>
              <MenuDivider />
              <MenuItem
                onClick={(e) => {
                  e.stopPropagation();
                  //onDelete(extraCost);
                }}
              >
                <Text fontSize="sm" fontWeight="bold" color="red.600">
                  Poista
                </Text>
              </MenuItem>
            </MenuList>
          </Portal>
        </Menu>
      </Td>
    </Tr>
  );
};

const ExtraCostsTable = ({
  extraCosts,
  onExtraCostClick,
  removeExtraCost,
  editExtraCost,
}) => {
  return (
    <>
      <Table overflow="auto">
        <Thead>
          <Tr whiteSpace="nowrap">
            <Th>
              Nimi
              <IconButton
                variant="ghost"
                colorScheme="blue"
                onClick={() => {}}
                size="sm"
                icon={<Icon as={FiChevronDown} />}
              />
            </Th>
            <Th whiteSpace="nowrap">
              Työntekijä
              <IconButton
                variant="ghost"
                colorScheme="blue"
                onClick={() => {}}
                size="sm"
                icon={<Icon as={FiChevronDown} />}
              />
            </Th>
            <Th whiteSpace="nowrap">
              Kesto
              <IconButton
                colorScheme="blue"
                variant="ghost"
                onClick={() => {}}
                size="sm"
                icon={<Icon as={FiChevronDown} />}
              />
            </Th>
            <Th whiteSpace="nowrap">
              Pvm.
              <IconButton
                variant="ghost"
                colorScheme="blue"
                onClick={() => {}}
                size="sm"
                icon={<Icon as={FiChevronDown} />}
              />
            </Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {extraCosts.map((extraCost) => (
            <ExtraCostRow
              key={extraCost.id}
              extraCost={extraCost}
              onRowClick={onExtraCostClick}
              onDelete={removeExtraCost}
              onEdit={editExtraCost}
            />
          ))}
        </Tbody>
      </Table>
    </>
  );
};

export default ExtraCostsTable;
