import React, {useEffect, useState, useRef} from 'react';
import {
  HStack,
  FormControl,
  FormLabel,
  Input,
  InputRightElement,
  InputGroup,
  Icon,
} from '@chakra-ui/react';
import {FiSearch} from 'react-icons/fi';
import {equals} from 'ramda';
import {useDebounce} from 'utils/react';

const ContractSitesFilters = ({
  contractSitesQuery = {},
  updateContractSitesQuery = () => {},
}) => {
  const querySearch = contractSitesQuery['filter[contractsitename]'] || '';
  const [search, setSearch] = useState(querySearch);
  const debouncedSearch = useDebounce(search, 500);
  const isInitialMount = useRef(true);

  // update filter to query debounced to prevent flooding API with requests
  // value also kept in local state that is updated immediately
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else if (!equals(debouncedSearch, querySearch)) {
      updateContractSitesQuery({'filter[contractsitename]': debouncedSearch});
    }
  }, [debouncedSearch]);

  useEffect(() => {
    if (querySearch !== search) {
      setSearch(querySearch);
    }
  }, [querySearch]);

  return (
    <HStack bg="gray.50" p={4}>
      <FormControl w="auto">
        <FormLabel>Vapaahaku</FormLabel>
        <InputGroup>
          <Input
            type="text"
            placeholder="Työmaan nimi"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <InputRightElement pointerEvents="none" color="gray.600">
            <Icon as={FiSearch} />
          </InputRightElement>
        </InputGroup>
      </FormControl>
    </HStack>
  );
};

export default ContractSitesFilters;
