import React from 'react';
import {Flex, Heading} from '@chakra-ui/react';

const ModalSubHeader = ({title, action}) => {
  return (
    <Flex
      align="center"
      justify="space-between"
      mt={5}
      pb={3}
      borderBottom="1px solid"
      borderBottomColor="gray.100"
    >
      <Heading size="sm">{title}</Heading>
      {action}
    </Flex>
  );
};

export default ModalSubHeader;
