import React, {useEffect} from 'react';
import {
  Box,
  Text,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Button,
  HStack,
} from '@chakra-ui/react';
import {pick} from 'ramda';
import {connect} from 'react-redux';
import {applyState} from 'utils/redux';
import {encodeQuery, createReferrerUrl} from 'utils/url';
import sels from 'modules/reportages/selectors';
import timeEntrySels from 'modules/timeEntries/selectors';
import effs from 'modules/reportages/effects';
import {Content, Card, TabsHorizontalScroll, PageHeader} from 'components';
import timeEntryEffs from 'modules/timeEntries/effects';
import TimeEntryFilters from './components/TimeEntryFilters';
import SitesTab from './sites/SitesTab';
import SitesTabFilters from './sites/SitesTabFilters';
import EmployeesTab from './employees/EmployeesTab';
import EmployeesTabFilters from './employees/EmployeesTabFilters';
import commonSels from 'modules/common/selectors';
import {TimeEntriesModal} from 'views/timeEntries/timeEntriesModal';
import {FiChevronsLeft} from 'react-icons/fi';
import GenerateCsvModal from './components/GenerateCsvModal';

const Reportage = ({
  location,
  history,
  initialized,
  siteEntries,
  referrerUrlBoolean,
  userEntries,
  dateRange,
  userQuery,
  siteQuery,
  timeEntriesModalOpen,
  timeEntryDefaultValues,
  loading,
  organizationSites,
  organizations,
  reportageIndex,
  userReportageLoading,
  userReportage,
  siteReportage,
  siteReportageLoading,
  generateReportageModalOpen,
  includeBreaksAndPickups,
}) => {
  useEffect(() => {
    effs.initialize();

    return () => {
      effs.destroy();
    };
  }, []);

  const referrerUrl = createReferrerUrl(location);

  const openEmployee = (id) => {
    history.push(
      `/employees/${id}${encodeQuery({referrerUrl, referrerTitle: 'Työntekijät'})}`,
    );
  };

  const openSite = (id) => {
    history.push(`/sites/${id}${encodeQuery({referrerUrl, referrerTitle: 'Työmaat'})}`);
  };

  const updateLists = () => {
    effs.updateUserQuery({});
    effs.updateSiteQuery({});
  };

  return (
    <Content>
      {referrerUrlBoolean ? (
        <PageHeader activeCrumbName={'Kirjaukset'}>
          <Button
            marginLeft={4}
            onClick={() => history.goBack()}
            leftIcon={<FiChevronsLeft />}
            colorScheme="blue"
            variant="solid"
          >
            Takaisin
          </Button>
        </PageHeader>
      ) : null}
      <Card w={'100%'} py={8}>
        <HStack px={6} paddingBottom={8} verticalAlign="space-between">
          <Text textStyle="h1">Työaika</Text>
        </HStack>
        <Tabs
          index={reportageIndex}
          size="lg"
          width={'100%'}
          onChange={(index) => {
            effs.changeTab(index);
          }}
        >
          <Box display="flex" justifyContent="space-between" px={6}>
            <TabsHorizontalScroll>
              <TabList>
                <Tab>Työmaat</Tab>
                <Tab>Työntekijät</Tab>
              </TabList>
            </TabsHorizontalScroll>
            {initialized && (
              <TimeEntryFilters query={dateRange} updateQuery={effs.updateDateRange} />
            )}
          </Box>
          <TabPanels>
            <TabPanel>
              <SitesTabFilters
                siteEntries={siteEntries}
                sites={organizationSites}
                siteReportage={siteReportage}
                siteReportageLoading={siteReportageLoading}
                query={siteQuery}
                updateQuery={effs.updateSiteQuery}
                generateReportageModalOpen={generateReportageModalOpen}
                includeBreaksAndPickups={includeBreaksAndPickups}
              />
              <SitesTab
                siteEntries={siteEntries}
                loading={loading}
                editTimeEntries={() => timeEntryEffs.openTimeEntryModal(updateLists)}
                setEditableEntries={timeEntryEffs.setEditableEntries}
                onSiteClick={openSite}
              />
            </TabPanel>
            <TabPanel>
              <EmployeesTabFilters
                userEntries={userEntries}
                organizationSites={organizationSites}
                organizations={organizations}
                userReportage={userReportage}
                userReportageLoading={userReportageLoading}
                generateReportageModalOpen={generateReportageModalOpen}
                includeBreaksAndPickups={includeBreaksAndPickups}
                query={userQuery}
                updateQuery={effs.updateUserQuery}
              />
              <EmployeesTab
                userEntries={userEntries}
                loading={loading}
                editTimeEntries={() => timeEntryEffs.openTimeEntryModal(updateLists)}
                setEditableEntries={timeEntryEffs.setEditableEntries}
                onEmployeeClick={openEmployee}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Card>
      {timeEntriesModalOpen && (
        <TimeEntriesModal
          isOpen={timeEntriesModalOpen}
          onSubmit={timeEntryEffs.updateTimeEntries}
          onClose={() => timeEntryEffs.closeTimeEntryModal()}
          defaultValues={timeEntryDefaultValues}
        />
      )}
      {generateReportageModalOpen && (
        <GenerateCsvModal
          isOpen={generateReportageModalOpen}
          onClose={effs.generateReportageModalOpen}
          includeBreaksAndPickups={includeBreaksAndPickups}
          reportageIndex={reportageIndex}
          generateSiteCsv={effs.generateSiteCsv}
          generateEmployeeCsv={effs.generateEmployeeCsv}
        />
      )}
    </Content>
  );
};
export default connect(
  applyState({
    ...pick(
      ['timeEntriesModalOpen', 'editableEntries', 'timeEntryDefaultValues'],
      timeEntrySels,
    ),
    ...pick(
      [
        'initialized',
        'siteEntries',
        'referrerUrlBoolean',
        'userEntries',
        'loading',
        'dateRange',
        'userQuery',
        'siteQuery',
        'reportageIndex',
        'siteReportage',
        'siteReportageLoading',
        'userReportage',
        'userReportageLoading',
        'generateReportageModalOpen',
        'includeBreaksAndPickups',
      ],
      sels,
    ),
    ...pick(['organizationSites', 'organizations'], commonSels),
  }),
)(Reportage);
