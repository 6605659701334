import React from 'react';
import {
  Center,
  IconButton,
  Link,
  Stack,
  Text,
  Menu,
  MenuList,
  MenuButton,
  MenuItem,
  MenuDivider,
  Grid,
  Icon,
} from '@chakra-ui/react';
import {parseISO, format} from 'date-fns';
import {FiMoreHorizontal, FiFile} from 'react-icons/fi';

const File = ({
  children,
  modified,
  onOpen = () => {},
  onEdit = () => {},
  onDelete = () => {},
}) => {
  const modifiedStr = modified ? format(parseISO(modified), 'd.M.yyyy') : '';

  return (
    <Grid
      gridAutoFlow="column"
      templateColumns="auto 1fr auto"
      alignItems="center"
      role="button"
      onClick={() => onOpen()}
    >
      <Center color="blue.500" boxSize="14" fontSize="2xl">
        <Icon as={FiFile} />
      </Center>

      <Stack spacing="1" overflow="hidden">
        <Link
          fontWeight="bold"
          lineHeight="1"
          fontSize="sm"
          display="inline-block"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          overflow="hidden"
        >
          {children}
        </Link>

        <Text fontSize="xs" lineHeight="1" color="gray.600">
          {modifiedStr}
        </Text>
      </Stack>

      <Menu>
        <MenuButton
          as={IconButton}
          icon={<Icon as={FiMoreHorizontal} />}
          variant="ghost"
          marginRight="1"
          onClick={(e) => e.stopPropagation()}
        />
        <MenuList>
          <MenuItem
            onClick={(e) => {
              e.stopPropagation();
              onEdit();
            }}
          >
            <Text fontSize="sm" fontWeight="bold">
              Muokkaa
            </Text>
          </MenuItem>
          <MenuDivider />
          <MenuItem
            onClick={(e) => {
              e.stopPropagation();
              onDelete();
            }}
          >
            <Text fontSize="sm" fontWeight="bold" color="red.600">
              Poista
            </Text>
          </MenuItem>
        </MenuList>
      </Menu>
    </Grid>
  );
};

export default File;
