import namespace from './namespace';
import {createEffects} from 'utils/events';
import services from 'services';
import {setPageTitleMessage, decorateWithNotificationsEff} from 'io/app';
import _acts from './boundActions';
import _sels from './boundSelectors';
import {P, Record} from 'utils/types';
import {guardHandled, logInfo} from 'io/errors';
import msgs from 'dicts/messages';
import confirmerEffs from 'modules/confirmer/effects';
import {
  getDayEntries,
  getEmployee,
  updateEmployee,
  createWorkPermit,
  deleteWorkPermit,
  updateWorkPermit,
} from './io';

let acts;
_acts.then((x) => (acts = x));
let sels;
_sels.then((x) => (sels = x));

let effects = {};
let types = {};

effects.initialize = guardHandled(async (id) => {
  const date = new Date();
  const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
  const lastDayOfMonth = new Date();

  acts.updateDateRange({startDate: firstDayOfMonth, endDate: lastDayOfMonth});

  acts.setLoading(true);
  const employee = await decorateWithNotificationsEff(
    {id: 'get-employee', failureStyle: 'warning'},
    getEmployee(id),
  );

  acts.setEmployee(employee);
  setPageTitleMessage(employee.data.name);

  //await fetchDayEntriesEff();
});
types.initialize = P.Number;

effects.updateDateRange = guardHandled(async ({startDate, endDate}) => {
  acts.updateDateRange({startDate: startDate, endDate: endDate});

  await fetchDayEntriesEff();
});
types.updateDateRange = Record({startDate: P.Date, endDate: P.Date});

effects.updateSiteIdFilter = guardHandled(async (id) => {
  acts.updateSiteIdFilter(id);

  await fetchDayEntriesEff();
});
types.updateSiteIdFilter = P.Object;

const fetchDayEntriesEff = async () => {
  acts.setEntriesLoading(true);
  const query = sels.timeEntryQueryFetchable();

  try {
    const siteEntries = await getDayEntries(query);
    await acts.setSiteEntries(siteEntries);
  } catch (e) {
    logInfo(e);
  }
  acts.setEntriesLoading(false);
};

effects.updateEmployee = guardHandled(async (formData) => {
  const employeeId = sels.employee().id;
  const employeeSites = sels.employee().sites;
  const employeesActiveSite = employeeSites.filter((s) => s.activityStatus !== 'away');
  const isActive = employeesActiveSite.length > 0 ? true : false;

  try {
    acts.setProcessing(true);
    const employee = await decorateWithNotificationsEff(
      {
        id: 'update-employee',
        failureStyle: 'error',
        success: 'Tiedot päivitetty',
      },

      updateEmployee({...formData, id: employeeId}),
    );
    acts.updateEmployee(employee);
    acts.closeEmployeeModal();
    acts.setProcessing(false);
    if (isActive) {
      window.location.reload();
    }
  } catch (e) {
    acts.setProcessing(false);
    throw e;
  }
});
types.updateEmployee = P.Object;

const fetchWorkPermits = async () => {
  const id = sels.employee().id;
  acts.setLoading(true);
  const employee = await decorateWithNotificationsEff(
    {id: 'get-employee', failureStyle: 'warning'},
    getEmployee(id),
  );

  acts.setEmployee(employee);
};

effects.createWorkPermit = guardHandled(async (formData) => {
  const userId = sels.employee().id;

  const expirationDate = formData.expires_at
    .toISOString()
    .substr(0, 19)
    .replace('T', ' ');

  const permitData = {
    ...formData,
    userId,
    expires_at: expirationDate,
  };

  try {
    acts.setProcessing(true);
    await decorateWithNotificationsEff(
      {
        id: 'create-work-permit',
        failureStyle: 'error',
        success: 'Lupa lisätty onnistuneesti!',
      },
      createWorkPermit(permitData),
    );
    acts.closeWorkPermitModal();
    await fetchWorkPermits();
  } catch (e) {
    acts.setProcessing(false);
    throw e;
  }
});
types.createWorkPermit = P.Object;

effects.removeWorkPermit = (workPermit) => {
  const onConfirm = guardHandled(async () => {
    try {
      acts.setProcessing(true);
      await decorateWithNotificationsEff(
        {
          id: 'remove-workPermit',
          failureStyle: 'error',
          loading: msgs.deleting,
          success: 'Työlupa poistettu',
        },
        deleteWorkPermit(workPermit.id),
      );
      acts.setProcessing(false);
      await fetchWorkPermits();
    } catch (e) {
      acts.setProcessing(false);
      throw e;
    }
  });

  confirmerEffs.show({
    message: `Poistetaanko työlupa ${workPermit.name}?`,
    okText: 'Poista',
    okStyle: 'danger',
    cancelText: msgs.cancel,
    onCancel: () => {},
    onOk: onConfirm,
  });
};
types.removeWorkPermit = P.Object;

effects.editWorkPermit = guardHandled(async (formData) => {
  const permitId = sels.openedPermitId();
  const expirationDate = formData.expires_at
    .toISOString()
    .substr(0, 19)
    .replace('T', ' ');

  const permitData = {
    ...formData,
    expires_at: expirationDate,
  };
  try {
    acts.setProcessing(true);
    await decorateWithNotificationsEff(
      {
        id: 'edit-work-permit',
        failureStyle: 'error',
        success: 'Luvan tietoja muokattu onnistuneesti!',
      },
      updateWorkPermit({permitData, permitId}),
    );
    acts.closeWorkPermitModal();
    await fetchWorkPermits();
  } catch (e) {
    acts.setProcessing(false);
    throw e;
  }
});
types.editWorkPermit = P.Object;

effects.openEmployeeModal = () => {
  acts.openEmployeeModal();
};
types.openEmployeeModal = P.Object;

effects.closeEmployeeModal = () => {
  acts.closeEmployeeModal();
};
types.closeEmployeeModal = P.Object;

effects.openWorkPermitModal = async (permitId) => {
  acts.setProcessing(true);
  await acts.openWorkPermitModal(permitId);
  acts.setProcessing(false);
};
types.openWorkPermitModal = P.Number;

effects.closeWorkPermitModal = () => {
  acts.closeWorkPermitModal();
};
types.closeWorkPermitModal = P.Object;

effects.destroy = async () => {
  acts.reset();
};

export default createEffects(namespace, services.get('channel').dispatch, types, effects);
