import React, {useEffect} from 'react';
import {
  HStack,
  VStack,
  Box,
  Spacer,
  Button,
  Text,
  Spinner,
  Center,
} from '@chakra-ui/react';
import {FiPlusCircle, FiMail} from 'react-icons/fi';
import {pick} from 'ramda';
import {connect} from 'react-redux';

import {applyState} from 'utils/redux';
import {encodeQuery, createReferrerUrl} from 'utils/url';
import effs from 'modules/sites/sitesPage/effects';
import sels from 'modules/sites/sitesPage/selectors';

import {Content, Card} from 'components';
import SitesTable from './components/sites-table';
import SitesFilters from './components/sites-filters';
import SiteModal from './components/site-modal';
import ContractSitesFilters from './components/contractSites-filters';
import ContractSitesTable from './components/contractSites-table';
import ContractNoticeModal from './components/contract-notice-modal';
import EmployeeNoticeModal from './components/employee-notice-modal';

const Sites = ({
  history,
  location,
  processing,
  loading,
  siteModalOpen,
  sites,
  contractSites,
  contractSitesQuery,
  contractSitesLoading,
  pagination,
  contractSitesPagination,
  query,
  employeeNoticeModalOpen,
  employeeSelection,
  selectedContractSites,
  employeeNoticeRows,
  employeeNoticeLoading,
  employeeNoticeQuery,
  employeeNoticeUrl,
  contractNoticeModalOpen,
  contractNoticeDateRange,
  contractNoticeUrl,
  siteSelection,
  contractNoticeLoading,
  selectedSites,
}) => {
  useEffect(() => {
    effs.initialize();

    return () => {
      effs.destroy();
    };
  }, []);

  const referrerUrl = createReferrerUrl(location);

  const openSite = (id) => {
    history.push(`/sites/${id}${encodeQuery({referrerUrl, referrerTitle: 'Työmaat'})}`);
  };

  return (
    <Content>
      <VStack spacing={6}>
        <Card w="full">
          <HStack px={6} py={8} verticalAlign="space-between">
            <Text textStyle="h1">Työmaat</Text>
            <Spacer />
            <Button
              onClick={() => effs.openContractNoticeModal()}
              leftIcon={<FiMail />}
              colorScheme="blue"
              variant="solid"
            >
              Urakkailmoitus
            </Button>
            <Button
              marginLeft={4}
              onClick={() => effs.openEmployeeNoticeModal()}
              leftIcon={<FiMail />}
              colorScheme="blue"
              variant="solid"
            >
              Työntekijäilmoitus
            </Button>
            <Button onClick={() => effs.toggleSiteModal()} leftIcon={<FiPlusCircle />}>
              Lisää
            </Button>
          </HStack>
          <SitesFilters query={query} updateQuery={effs.updateQuery} />
          {loading ? (
            <Center p={6}>
              <Spinner size="xl" />
            </Center>
          ) : !sites.length ? (
            <Box p={6}>Ei työmaita</Box>
          ) : (
            <SitesTable
              sites={sites}
              pagination={pagination}
              processing={processing}
              onPageSelect={(page) => effs.updateQuery({page})}
              openSite={openSite}
              removeSite={effs.removeSite}
              referrerUrl={referrerUrl}
              selection={siteSelection}
              clearSelection={effs.clearSubContractorSelection}
              selectSites={effs.selectSubContractors}
            />
          )}
        </Card>
        <Card w="full">
          <HStack px={6} py={10} verticalAlign="space-between">
            <Text textStyle="h1">Alihankintatyömaat</Text>
            <Spacer />
            <Button
              marginLeft={4}
              onClick={() => effs.openEmployeeNoticeModal()}
              leftIcon={<FiMail />}
              colorScheme="blue"
              variant="solid"
            >
              Työntekijäilmoitus
            </Button>
          </HStack>

          <ContractSitesFilters
            contractSitesQuery={contractSitesQuery}
            updateContractSitesQuery={effs.updateContractSitesQuery}
          />

          {contractSitesLoading ? (
            <Center p={6}>
              <Spinner size="xl" />
            </Center>
          ) : !contractSites.length ? (
            <Box p={6}>Ei työmaita</Box>
          ) : (
            <ContractSitesTable
              contractSites={contractSites}
              pagination={contractSitesPagination}
              processing={processing}
              onPageSelect={(page) => effs.updateContractSitesQuery({page})}
              openSite={openSite}
              referrerUrl={referrerUrl}
              selection={siteSelection}
              clearSelection={effs.clearSubContractorSelection}
              selectSites={effs.selectSubContractors}
            />
          )}
        </Card>
      </VStack>

      {siteModalOpen && (
        <SiteModal
          isOpen={siteModalOpen}
          isLoading={processing}
          onClose={() => effs.toggleSiteModal()}
          onSubmit={effs.createSite}
        />
      )}

      {employeeNoticeModalOpen && (
        <EmployeeNoticeModal
          employeeNoticeUrl={employeeNoticeUrl}
          isOpen={employeeNoticeModalOpen}
          isLoading={processing}
          onClose={() => effs.closeEmployeeNoticeModal()}
          onSubmit={() => effs.sendEmployeeNotice()}
          employeeRowsLoading={employeeNoticeLoading}
          selection={siteSelection}
          selectedSites={selectedSites}
          selectedContractSites={selectedContractSites}
          selectEmployees={effs.selectEmployees}
          updateQuery={effs.updateEmployeeNoticeQuery}
          query={employeeNoticeQuery}
        />
      )}

      {contractNoticeModalOpen && (
        <ContractNoticeModal
          isOpen={contractNoticeModalOpen}
          isLoading={processing}
          onClose={() => effs.closeContractNoticeModal()}
          updateQuery={effs.updateContractNoticeDateRange}
          query={contractNoticeDateRange}
          selection={siteSelection}
          subContractorRowsLoading={contractNoticeLoading}
          onSubmit={() => effs.sendContractNotice()}
          contractNoticeUrl={contractNoticeUrl}
          selectedSites={selectedSites}
        />
      )}
    </Content>
  );
};

export default connect(
  applyState(
    pick(
      [
        'processing',
        'loading',
        'siteModalOpen',
        'sites',
        'contractSites',
        'pagination',
        'contractSitesPagination',
        'query',
        'contractSitesQuery',
        'contractSitesLoading',
        'employeeNoticeModalOpen',
        'employeeSelection',
        'employeeNoticeRows',
        'employeeNoticeLoading',
        'employeeNoticeQuery',
        'employeeNoticeUrl',
        'contractNoticeModalOpen',
        'contractNoticeDateRange',
        'siteSelection',
        'contractNoticeLoading',
        'contractNoticeUrl',
        'selectedSites',
        'selectedContractSites',
      ],
      sels,
    ),
  ),
)(Sites);
