import namespace from './namespace';
import {createEffects} from 'utils/events';
import services from 'services';
import _acts from './boundActions';
import {P, Record, Union, Maybe} from 'utils/types';

// types

const NId = Union(P.String, P.Number);

const AdjustArg = Record({
  id: NId,
  message: P.String,
  duration: Maybe(P.Number),
});

let acts;
_acts.then((x) => (acts = x));

let effects = {};
let types = {};

let timeouts = {};

const adjustEff = (type) => async (spec) => {
  const {duration, ...rest} = spec;

  acts.adjust({...rest, type});

  if (duration) {
    const id = spec.id;
    if (timeouts[id]) {
      clearInterval(timeouts[id]);
    }
    const timeoutId = setTimeout(() => {
      delete timeouts[id];
      acts.remove(id);
    }, duration);
    timeouts[id] = timeoutId;
  }
};

['info', 'success', 'warning', 'error', 'loading'].forEach((type) => {
  effects[type] = adjustEff(type);
  types[type] = AdjustArg;
});

effects.remove = async (id) => {
  acts.remove(id);
};
types.remove = NId;

export default createEffects(namespace, services.get('channel').dispatch, types, effects);
