import {prop} from 'ramda';
import services from 'services';
import {describeThrow} from 'utils/errors';
import msgs from 'dicts/messages';

let httpJson = null;
services.waitFor('api').then((x) => (httpJson = x.httpJson));

export const getSites = (query) =>
  httpJson('get', '/sites', {...query, type: 'organization'}).catch(
    describeThrow(msgs.fetchFailed),
  );

export const getContractSites = (query) =>
  httpJson('get', '/sites', {...query, type: 'contractSites'}).catch(
    describeThrow(msgs.fetchFailed),
  );

export const postSite = (body) =>
  httpJson('post', '/sites', {}, {body})
    .catch(describeThrow(msgs.saveFailed))
    .then(prop('data'));

export const deleteSite = (id) =>
  httpJson('delete', `/sites/${id}`).catch(describeThrow(msgs.deleteFailed));

export const sendContractNotice = ({data}) =>
  httpJson(
    'post',
    `/sites/sendContractNotice`,
    {},
    {
      body: data,
    },
  )
    .catch(describeThrow(msgs.opSuccess))
    .then(prop('data'));

export const sendEmployeeNotice = ({data}) =>
  httpJson(
    'post',
    `/sites/sendEmployeeNotice`,
    {},
    {
      body: data,
    },
  )
    .catch(describeThrow(msgs.opSuccess))
    .then(prop('data'));
