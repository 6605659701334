import {createStore, compose as reduxCompose, applyMiddleware} from 'redux';
import {stackInjectorMiddleware} from 'utils/redux';
import reducer from 'modules/reducer';
import {inDev} from 'constants/app';

export default ({actionHookEnhancer = null}) => {
  const compose = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || reduxCompose;

  const middleware = inDev ? [stackInjectorMiddleware] : [];

  const enhancers = [
    ...(actionHookEnhancer ? [actionHookEnhancer] : []),
    applyMiddleware(...middleware),
  ];

  const enhancer = compose(...enhancers);

  return createStore(reducer, enhancer);
};
