import {http, httpJson} from 'io/http';
import {apiUrl} from 'constants/app';

export default ({get}) => {
  const augmentArgs = ([method, url, query, conf = {}]) => {
    const headers = {
      Accept: 'application/json',
      ...(get('apiToken') ? {Authorization: `Bearer ${get('apiToken')}`} : {}),
      ...(conf.headers || {}),
    };

    const reqConf = {...conf, headers};
    const reqUrl = `${apiUrl}${url}`;

    return [method, reqUrl, query, reqConf];
  };

  const augmentResponse = (promise) =>
    promise.catch((e) => {
      if (get('onError')) get('onError')(e);
      throw e;
    });

  return {
    http: (...args) => augmentResponse(http(...augmentArgs(args))),
    httpJson: (...args) => augmentResponse(httpJson(...augmentArgs(args))),
  };
};
