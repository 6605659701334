import React, {useEffect} from 'react';
import {
  SimpleGrid,
  Stack,
  Text,
  Center,
  Spinner,
  IconButton,
  Icon,
  Box,
  Button,
} from '@chakra-ui/react';
import {Link} from 'react-router-dom';
import {pick} from 'ramda';
import {connect} from 'react-redux';
import {FiEdit, FiFileText} from 'react-icons/fi';

import {applyState} from 'utils/redux';
import effs from 'modules/employees/employeePage/effects';
import sels from 'modules/employees/employeePage/selectors';

import {Content, PageHeader, Card, CardHeader, List, ListItem} from 'components';
import SitesTable from './components/sites-table';
import TimeEntryFilters from './components/timeEntries-filter';
import TimeEntriesTable from './components/timeEntries-table';
import {encodeQuery, createReferrerUrl} from 'utils/url';
import EmployeeModal from './components/employee-modal';
import timeEntrySels from 'modules/timeEntries/selectors';
import timeEntryEffs from 'modules/timeEntries/effects';
import {TimeEntriesModal} from 'views/timeEntries/timeEntriesModal';
import WorkPermitTable from './components/work-permit-table';
import WorkPermitModal from './components/work-permit-modal';

const Employee = ({
  match: {
    params: {employeeId},
  },
  processing,
  loading,
  entriesLoading,
  permitDefaultValues,
  employee,
  sites,
  dateRange,
  workPermits,
  siteEntries,
  siteIdFilter,
  workPermitModalOpen,
  employeeModalOpen,
  employeeDefaultVals,
  history,
  location,
  timeEntriesModalOpen,
  timeEntryDefaultValues,
  selectedWorkPermit,
}) => {
  useEffect(() => {
    effs.initialize(Number(employeeId));

    return () => {
      effs.destroy();
    };
  }, []);

  const referrerUrl = createReferrerUrl(location);

  const openSite = (id) => {
    history.push(`/sites/${id}${encodeQuery({referrerUrl, referrerTitle: 'Työmaat'})}`);
  };

  return (
    <Content>
      <PageHeader activeCrumbName={employee?.name}>
        <Link
          to={{
            pathname: '/reportage',
            search: encodeQuery({
              referrerUrl,
              referrerTitle: employee.name,
              reportageIndex: 1,
              user: JSON.stringify({name: employee?.name}),
            }),
            params: employeeId,
          }}
        >
          <Button
            marginLeft={4}
            onClick={() => {}}
            leftIcon={<FiFileText />}
            colorScheme="blue"
            variant="solid"
          >
            Työaikaraportti
          </Button>
        </Link>
      </PageHeader>

      {loading ? (
        <Center>
          <Spinner size="xl" />
        </Center>
      ) : (
        <SimpleGrid columns={{md: 2}} templateColumns="1fr 2fr" spacing="5">
          <Stack spacing={6}>
            <Card>
              <CardHeader
                title={employee.name}
                isMain
                action={
                  <IconButton
                    onClick={() => effs.openEmployeeModal()}
                    size="sm"
                    icon={<Icon as={FiEdit} />}
                  />
                }
              />
              <List marginBottom="5">
                <ListItem title="Osoite" display="flex" justifyContent="space-between">
                  <Text textAlign="right">{employee.address}</Text>
                </ListItem>
                <ListItem
                  title="Puhelinnumero"
                  display="flex"
                  justifyContent="space-between"
                >
                  <Text>{employee.phone}</Text>
                </ListItem>
                <ListItem
                  title="Sähköposti"
                  display="flex"
                  justifyContent="space-between"
                >
                  <Text>{employee.email}</Text>
                </ListItem>
                <ListItem
                  title="Veronumero"
                  display="flex"
                  justifyContent="space-between"
                >
                  <Text>{employee.vatNumber}</Text>
                </ListItem>
                <ListItem
                  title="Tuntipalkka"
                  display="flex"
                  justifyContent="space-between"
                >
                  <Text>{!employee.baseWage ? '-' : `${employee.baseWage}€/h`}</Text>
                </ListItem>
                <ListItem
                  title="Tehtävänimike"
                  display="flex"
                  justifyContent="space-between"
                >
                  <Text>{employee.jobTitle}</Text>
                </ListItem>
              </List>
            </Card>
          </Stack>
          <Stack spacing={6}>
            <Card>
              <CardHeader
                title={`Työmaat (${sites.length})`}
                action={
                  <IconButton onClick={() => {}} size="sm" icon={<Icon as={FiEdit} />} />
                }
              />
              {loading ? (
                <Center p={6}>
                  <Spinner size="xl" />
                </Center>
              ) : !sites.length ? (
                <Box p={6}>Ei työmaita</Box>
              ) : (
                <SitesTable
                  sites={sites}
                  openSite={openSite}
                  employee={employee}
                  processing={processing}
                  onPageSelect={(page) => effs.updateQuery({page})}
                />
              )}
            </Card>
            <Card>
              <CardHeader
                title={`Työluvat (${workPermits.length})`}
                action={
                  <IconButton
                    onClick={() => effs.openWorkPermitModal()}
                    size="sm"
                    icon={<Icon as={FiEdit} />}
                  />
                }
              />
              {loading ? (
                <Center p={6}>
                  <Spinner size="xl" />
                </Center>
              ) : !sites.length ? (
                <Box p={6}>Ei työlupia</Box>
              ) : (
                <WorkPermitTable
                  workPermits={workPermits}
                  onDelete={effs.removeWorkPermit}
                  editPermit={(id) => effs.openWorkPermitModal(id)}
                />
              )}
            </Card>
            <Card>
              <CardHeader
                title={`Kirjaukset (${siteEntries.length})`}
                action={
                  <IconButton onClick={() => {}} size="sm" icon={<Icon as={FiEdit} />} />
                }
              >
                <TimeEntryFilters
                  employee={employee}
                  sites={sites}
                  dateRange={dateRange}
                  siteIdFilter={siteIdFilter}
                  updateSiteIdFilter={effs.updateSiteIdFilter}
                  updateDateRange={effs.updateDateRange}
                />
              </CardHeader>
              {entriesLoading ? (
                <Center p={6}>
                  <Spinner size="xl" />
                </Center>
              ) : !siteEntries.length ? (
                <Box p={6}>Ei kirjauksia</Box>
              ) : (
                <TimeEntriesTable
                  siteEntries={siteEntries}
                  employee={employee}
                  setEditableEntries={timeEntryEffs.setEditableEntries}
                  editTimeEntries={timeEntryEffs.openTimeEntryModal}
                  processing={processing}
                  onPageSelect={(page) => effs.updateQuery({page})}
                />
              )}
            </Card>
          </Stack>
        </SimpleGrid>
      )}
      {employeeModalOpen && (
        <EmployeeModal
          isOpen={employeeModalOpen}
          isLoading={processing}
          onClose={effs.closeEmployeeModal}
          onSubmit={effs.updateEmployee}
          defaultValues={employeeDefaultVals}
        />
      )}
      {timeEntriesModalOpen && (
        <TimeEntriesModal
          isOpen={timeEntriesModalOpen}
          onSubmit={timeEntryEffs.updateTimeEntries}
          onClose={() => timeEntryEffs.closeTimeEntryModal()}
          defaultValues={timeEntryDefaultValues}
        />
      )}
      {workPermitModalOpen && (
        <WorkPermitModal
          defaultValues={permitDefaultValues}
          onSubmit={selectedWorkPermit ? effs.editWorkPermit : effs.createWorkPermit}
          isOpen={workPermitModalOpen}
          onClose={effs.closeWorkPermitModal}
        />
      )}
    </Content>
  );
};

export default connect(
  applyState({
    ...pick(
      ['timeEntriesModalOpen', 'editableEntries', 'timeEntryDefaultValues'],
      timeEntrySels,
    ),
    ...pick(
      [
        'processing',
        'loading',
        'entriesLoading',
        'employee',
        'sites',
        'siteIdFilter',
        'dateRange',
        'siteEntries',
        'workPermits',
        'employeeModalOpen',
        'employeeDefaultVals',
        'workPermitModalOpen',
        'permitDefaultValues',
        'selectedWorkPermit',
      ],
      sels,
    ),
  }),
)(Employee);
