export const assertionsConfig = {enable: false};

export const assertType = assertionsConfig.enable
  ? (type, value) => type.assert(value)
  : () => {};

const assertTypeAndTransform = (type, value, errorTransformer) => {
  try {
    type.assert(value);
  } catch (e) {
    throw errorTransformer(e);
  }
};
export const assertTypeAndTransformError = assertionsConfig.enable
  ? assertTypeAndTransform
  : () => {};

const _assertTruthy = (val) => {
  if (!val) {
    throw new Error(`Assertion failed, value not truthy: \`${val}\``);
  }
};
export const assertTruthy = assertionsConfig.enable ? _assertTruthy : () => {};
