import {encodeQuery} from 'utils/url';
import {validateResponse} from 'utils/http';

export const request = (method, url, query, conf = {}) =>
  new Request(`${url}${encodeQuery(query)}`, {...conf, method});

export const requestJson = (method, url, query, conf = {}) =>
  request(method, url, query, {
    ...conf,
    ...(conf.body ? {body: JSON.stringify(conf.body)} : {}),
    headers: {'content-type': 'application/json; charset=utf-8', ...(conf.headers || {})},
  });

export const fetch_ = (request) => fetch(request).then(validateResponse);

export const fetchJson = (request) =>
  fetch_(request)
    .then((res) => res.text())
    .then((text) => (text !== '' ? JSON.parse(text) : undefined));

export const http = (...args) => fetch_(request(...args));

export const httpJson = (...args) => fetchJson(requestJson(...args));
