import namespace from './namespace';
import {createEffects} from 'utils/events';
import services from 'services';
import _acts from './boundActions';
import _sels from './boundSelectors';
import {guardHandled} from 'io/errors';
import {P, Record} from 'utils/types';
import {decorateWithNotificationsEff} from 'io/app';
import {getQuery, replaceQuery} from 'io/history';
import {getSiteEntries, getUserEntries, generateCsv} from './io';
import {parseUrlQuery} from './utils';
let acts;
_acts.then((x) => (acts = x));
let sels;
_sels.then((x) => (sels = x));

let effects = {};
let types = {};

const fetchSiteEntriesEff = async () => {
  const query = sels.siteQueryFetchable();

  acts.setLoading(true);
  try {
    const siteEntries = await decorateWithNotificationsEff(
      {id: 'site-entries', failureStyle: 'warning'},
      getSiteEntries(query),
    );
    acts.setSiteEntries(siteEntries);
    acts.setLoading(false);
  } catch (e) {
    acts.setLoading(false);
    throw e;
  }
};

const fetchUserEntriesEff = async () => {
  const query = sels.userQueryFetchable();

  acts.setLoading(true);
  try {
    const userEntries = await decorateWithNotificationsEff(
      {id: 'site-entries', failureStyle: 'error'},
      getUserEntries(query),
    );
    acts.setUserEntries(userEntries);
    acts.setLoading(false);
  } catch (e) {
    acts.setLoading(false);
    throw e;
  }
};

effects.initialize = guardHandled(async () => {
  // initialize date range "lazily" instead of in the component, so that info flow is one-way.
  const date = new Date();
  const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
  const lastDayOfMonth = new Date();

  acts.updateDateRange({startDate: firstDayOfMonth, endDate: lastDayOfMonth});

  acts.setInitialized(true);

  const query = parseUrlQuery(getQuery());

  if (query.user) {
    acts.updateUserQuery(query.user);
  }
  if (query.site) {
    acts.updateSiteQuery(query.site);
  }
  if (query.dateRange) {
    acts.updateDateRange(query.dateRange);
  }
  if (query.reportageIndex != null) {
    acts.setReportageIndex(query.reportageIndex);
  }
  if (query.referrerUrl != null) {
    acts.setReferrerUrlBoolean(true);
  }

  const index = sels.reportageIndex();
  if (index === 0) {
    await fetchSiteEntriesEff();
  } else if (index === 1) {
    await fetchUserEntriesEff();
  }
});

effects.updateDateRange = guardHandled(async ({startDate, endDate}) => {
  acts.updateDateRange({startDate: startDate, endDate: endDate});

  replaceQuery((q) => ({...q, dateRange: sels.dateRangeQueryUrlFormatted()}));

  const index = sels.reportageIndex();
  if (index === 0) {
    await fetchSiteEntriesEff();
  } else if (index === 1) {
    await fetchUserEntriesEff();
  }
});
types.updateDateRange = Record({startDate: P.Date, endDate: P.Date});

effects.changeTab = async (tabIndex) => {
  acts.setReportageIndex(tabIndex);
  replaceQuery((q) => ({...q, reportageIndex: tabIndex}));

  if (tabIndex === 0) {
    await fetchSiteEntriesEff();
  } else if (tabIndex === 1) {
    await fetchUserEntriesEff();
  }
};
types.changeTab = P.Number;

effects.updateUserQuery = guardHandled(async (query) => {
  acts.updateUserQuery(query);
  replaceQuery((q) => ({...q, user: sels.userQueryUrlFormatted()}));

  await fetchUserEntriesEff();
});
types.updateUserQuery = P.Object;

effects.updateSiteQuery = guardHandled(async (query) => {
  acts.updateSiteQuery(query);
  replaceQuery((q) => ({...q, site: sels.siteQueryUrlFormatted()}));

  await fetchSiteEntriesEff();
});
types.updateSiteQuery = P.Object;

effects.generateReportageModalOpen = (value) => {
  acts.setGenerateReportageModalOpen(value);
};
types.generateReportageModalOpen = P.Boolean;

effects.generateEmployeeCsv = guardHandled(async (checked) => {
  const entries = await sels.userEntries();
  const includeBreaksAndPickups = checked;
  const dateRange = await sels.dateRange();

  const strippedEntries = entries.map((e) => ({
    site: e.site,
    totalTime: e.totalTime.total,
    breakTime: e.totalTime.break,
    id: e.user.id,
    name: e.user.name,
    entries: e.items,
    organizationId: e.user.organizationId,
    organizationName: e.user.organizationName,
  }));

  acts.setUserReportageLoading(true);

  const data = await decorateWithNotificationsEff(
    {
      id: 'generateEmployeeCsv',
      failureStyle: 'error',
      success: 'Raportti luotu ladattavaksi',
    },
    generateCsv({
      employees: strippedEntries,
      dateRange: dateRange,
      includeBreaksAndPickups,
    }),
  );

  acts.setUserReportage(data.url);

  acts.setUserReportageLoading(false);
  acts.setGenerateReportageModalOpen(false);
});
types.generateEmployeeCsv = P.Object;

effects.generateSiteCsv = guardHandled(async (checked) => {
  const entries = sels.siteEntries();
  const dateRange = sels.dateRange();
  const includeBreaksAndPickups = checked;

  acts.setSiteReportageLoading(true);
  const data = await decorateWithNotificationsEff(
    {
      id: 'generateSiteCsv',
      failureStyle: 'error',
      success: 'Raportti luotu ladattavaksi',
    },
    generateCsv({sites: entries, dateRange: dateRange, includeBreaksAndPickups}),
  );

  acts.setSiteReportage(data.url);
  acts.setSiteReportageLoading(false);
  acts.setGenerateReportageModalOpen(false);
});
types.generateSiteCsv = P.Object;

effects.updateIncludeBreaksAndPickups = (value) => {
  acts.setIncludeBreaksAndPickups(value);
};
types.updateIncludeBreaksAndPickups = P.Boolean;

effects.destroy = async () => {
  acts.reset();
};

export default createEffects(namespace, services.get('channel').dispatch, types, effects);
