import React, {useMemo} from 'react';
import {Link} from 'react-router-dom';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalHeader,
  ModalCloseButton,
  ModalFooter,
  Button,
  Flex,
  HStack,
  Box,
  Text,
  Center,
  Spinner,
} from '@chakra-ui/react';
import {sum} from 'ramda';
import {sToTime} from 'utils/time';
import EmployeeNoticeTable from './employee-notice-table';
import EmployeeNoticeFilters from './employee-notice-filters';

const EmployeeNoticeModal = ({
  isOpen,
  employeeNoticeUrl,
  isLoading,
  onClose = () => {},
  onSubmit = () => {},
  employeeRows = [],
  employeeRowsLoading,
  selection = [],
  selectEmployees = () => {},
  clearSelection = () => {},
  updateQuery = () => {},
  query,
  organizations = [],
}) => {
  const totalEmployees = employeeRows.length;

  // sum of unique time entry dates (ignoring hours) each employee has
  const totalDays = useMemo(
    () =>
      sum(
        employeeRows.map(
          (e) =>
            e.timeEntries
              .map((te) => te.startDate.split('T')[0])
              .filter((s, i, a) => a.indexOf(s) === i).length,
        ),
      ),
    [employeeRows],
  );

  const totalHours = useMemo(
    () => sToTime(sum(employeeRows.map((e) => e.totalTime.total))),
    [employeeRows],
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} size="5xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Työntekijäilmoitus</ModalHeader>
        <ModalCloseButton />
        <ModalBody p={0}>
          <Flex
            px={6}
            py={4}
            bg="gray.50"
            alignItems="center"
            justifyContent="space-between"
          >
            <EmployeeNoticeFilters
              updateQuery={updateQuery}
              query={query}
              organizations={organizations}
            />
            <HStack spacing={8}>
              <Box>
                <Text textStyle="label" color="gray.600">
                  Henkilöt
                </Text>
                <Text fontWeight="bold">{totalEmployees}</Text>
              </Box>
              <Box>
                <Text textStyle="label" color="gray.600">
                  Työpäivät
                </Text>
                <Text fontWeight="bold">{totalDays}</Text>
              </Box>
              <Box>
                <Text textStyle="label" color="gray.600">
                  Tunnit
                </Text>
                <Text fontWeight="bold">{totalHours}</Text>
              </Box>
            </HStack>
          </Flex>
          {employeeRowsLoading ? (
            <Center pt={6}>
              <Spinner size="lg" />
            </Center>
          ) : !employeeRows.length ? (
            <Box pt={6} px={6}>
              Ei kirjauksia
            </Box>
          ) : (
            <EmployeeNoticeTable
              employees={employeeRows}
              selection={selection}
              selectEmployees={selectEmployees}
              clearSelection={clearSelection}
            />
          )}
        </ModalBody>
        <ModalFooter borderTopWidth={0}>
          <Button onClick={onClose} mr={3}>
            Peruuta
          </Button>
          {!employeeNoticeUrl ? (
            <Button
              onClick={onSubmit}
              variant="primary"
              isLoading={isLoading}
              disabled={!selection.length}
            >
              <span>Luo ilmoitus</span>
            </Button>
          ) : (
            <Link to={{pathname: employeeNoticeUrl}} download target="_top">
              <Button variant="primary">
                <span>Lataa tiedosto</span>
              </Button>
            </Link>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EmployeeNoticeModal;
