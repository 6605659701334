const Modal = {
  parts: ['header', 'footer', 'body'],
  baseStyle: {
    header: {
      fontSize: 'sm',
      fontWeight: 'bold',
      textTransform: 'uppercase',
      letterSpacing: 'wide',
      color: 'gray.600',
      borderColor: 'gray.100',
      borderBottomWidth: '1px',
    },
    footer: {
      borderColor: 'gray.100',
      borderTopWidth: '1px',
    },
    body: {
      px: 6,
      py: 6,
    },
  },
};

export default Modal;
