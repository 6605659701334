const Button = {
  // The styles all button have in common
  baseStyle: {
    fontWeight: 'bold',
  },
  // Two sizes: sm and md
  sizes: {
    sm: {
      fontSize: 'sm',
      borderRadius: 'md',
    },
    lg: {
      fontSize: 'md',
      borderRadius: 'lg',
      lineHeight: 4,
      h: 12,
    },
  },
  // Two variants: outline and solid
  variants: {
    solid: {
      bg: 'blue.100',
      color: 'blue.600',
      boxShadow: 'md',
      _hover: {
        bg: 'blue.200',
        color: 'blue.600',
      },
      _active: {
        bg: 'blue.300',
        color: 'blue.600',
      },
      _focus: {
        bg: 'blue.200',
        color: 'blue.600',
      },
    },

    primary: {
      bg: 'blue.500',
      color: 'white',
      boxShadow: 'base',
      _hover: {
        bg: 'blue.600',
        color: 'white',
        _disabled: {
          bg: 'blue.600',
        },
      },
      _active: {
        bg: 'blue.700',
        color: 'white',
      },
      _focus: {
        bg: 'blue.600',
        color: 'white',
      },
    },

    outline: {
      color: 'gray.600',
      border: '1px solid',
      borderColor: 'gray.600',
      _hover: {
        bg: 'gray.300',
      },
      _active: {
        bg: 'gray.400',
      },
      _focus: {
        bg: 'gray.300',
      },
    },

    danger: {
      bg: 'red.500',
      color: 'white',
      boxShadow: 'base',
      _hover: {
        bg: 'red.600',
        color: 'white',
      },
      _active: {
        bg: 'red.700',
        color: 'white',
      },
    },

    'primary-link': {
      color: 'blue.600',
      p: 0,
      h: 'auto',
      _hover: {
        color: 'blue.600',
        textDecoration: 'underline',
      },
      _active: {
        color: 'blue.700',
      },
    },

    pagination: {
      bg: 'gray.50',
      color: 'gray.600',
      border: '1px solid',
      borderColor: 'gray.300',
      _hover: {
        borderColor: 'blue.500',
        color: 'blue.500',
      },
      _active: {
        bg: 'blue.500',
        borderColor: 'blue.500',
        color: 'white',
      },
    },
  },
  // The default size and variant values
  defaultProps: {
    size: 'md',
  },
};

export default Button;
