import {pipe, keys, map, join, split, filter, fromPairs} from 'ramda';

export const encodeQuery = (obj) =>
  pipe(
    keys,
    map((k) => `${k}=${obj[k] != null ? encodeURIComponent(obj[k]) : ''}`),
    join('&'),
    (str) => (str.length ? `?${str}` : str),
  )(obj);

export const decodeQuery = pipe(
  (str) => (str.length ? str.substring(1) : str),
  split('&'),
  filter((x) => x !== ''),
  map(pipe(split('='), map(decodeURIComponent))),
  fromPairs,
);

export const createReferrerUrl = (location) => {
  const {pathname, search, hash} = location;
  return `${pathname}${search}${hash}`;
};

export const getReferrerUrl = (query) => {
  const {referrerUrl} = decodeQuery(query);
  if (!referrerUrl) {
    return null;
  }
  return referrerUrl;
};

export const getReferrer = (query) => {
  const {referrer} = decodeQuery(query);
  if (!referrer) {
    return null;
  }
  return referrer;
};

export const getReferrerTitle = (query) => {
  const {referrerTitle} = decodeQuery(query);
  if (!referrerTitle) {
    return null;
  }
  return referrerTitle;
};

export const getActiveCrumb = (query) => {
  const {activeCrumb} = decodeQuery(query);
  if (!activeCrumb) {
    return null;
  }
  return activeCrumb;
};
