import namespace from './namespace';
import {scopedSelectors} from 'utils/redux';
import {createSelector} from 'reselect';
import {formatTimeEntryDefaultVals} from './utils';

let sels = {};

sels.timeEntriesModalOpen = (st) => st.timeEntriesModalOpen;

sels.editableEntries = (st) => st.editableEntries;

sels.timeEntryDefaultValues = createSelector([sels.editableEntries], (editableEntries) =>
  formatTimeEntryDefaultVals(editableEntries),
);

export default scopedSelectors(namespace, sels);
