import {mergeLeft} from 'ramda';
import namespace from './namespace';
import {createEffects} from 'utils/events';
import services from 'services';
import {setPageTitleMessage, decorateWithNotificationsEff} from 'io/app';
import _acts from './boundActions';
import _sels from './boundSelectors';
import {P} from 'utils/types';
import {guardHandled} from 'io/errors';
import {pushQuery, getQuery} from 'io/history';
import {getEmployees, postEmployee, deleteEmployee, getActiveUsers} from './io';
import msgs from 'dicts/messages';
import confirmerEffs from 'modules/confirmer/effects';
import commonEffs from 'modules/common/effects';
import _commonSels from 'modules/common/boundSelectors';
import {parseUrlQuery} from './utils';

let acts, sels, commonSels;
_acts.then((x) => (acts = x));
_sels.then((x) => (sels = x));
_commonSels.then((x) => (commonSels = x));

let effects = {};
let types = {};

const fetchEmployees = (notifyOpts = {}) => {
  acts.setLoading(true);

  return decorateWithNotificationsEff(
    {id: 'get-employees', failureStyle: 'warning', ...notifyOpts},
    getEmployees(sels.queryFetchable()),
  ).then(({data, meta}) => {
    acts.setEmployees({data, pagination: meta});
  });
};

const getActiveEmployees = (notifyOpts = {}) => {
  const organizationId = commonSels.organizationId();
  acts.setLoading(true);

  return decorateWithNotificationsEff(
    {id: 'get-activeEmployees', failureStyle: 'warning', ...notifyOpts},
    getActiveUsers(),
  ).then((data) => {
    const activeUsers = data.filter(
      (user) =>
        user.organizationId === organizationId ||
        user.site.organization_id === organizationId,
    );
    acts.setActiveEmployees(activeUsers);
    acts.setLoading(false);
    //acts.setActiveEmployees({data});
  });
};

effects.initialize = guardHandled(async () => {
  setPageTitleMessage('Työntekijät');

  const query = parseUrlQuery(getQuery());
  acts.updateQuery(query);

  await fetchEmployees();
  await getActiveEmployees();
  await commonEffs.fetchOrganizations();
});

effects.openEmployeeModal = () => {
  acts.openEmployeeModal();
};

effects.closeEmployeeModal = () => {
  acts.closeEmployeeModal();
};

effects.createEmployee = guardHandled(async (formData) => {
  try {
    acts.setProcessing(true);
    await decorateWithNotificationsEff(
      {
        id: 'addEmployee-form',
        failureStyle: 'error',
        success: 'Tallennettu',
      },
      postEmployee(formData),
    );
    acts.closeEmployeeModal();
    acts.setProcessing(false);
  } catch (e) {
    acts.setProcessing(false);
    throw e;
  }

  await fetchEmployees();
});

types.createEmployee = P.Object;

effects.removeEmployee = (employee) => {
  const onConfirm = guardHandled(async () => {
    try {
      acts.setProcessing(true);
      await decorateWithNotificationsEff(
        {
          id: 'remove-employee',
          failureStyle: 'error',
          loading: msgs.deleting,
          success: 'Työntekijä poistettu',
        },
        deleteEmployee(employee.id),
      );
      acts.setProcessing(false);
    } catch (e) {
      acts.setProcessing(false);
      throw e;
    }

    await fetchEmployees();
  });

  confirmerEffs.show({
    message: `Poistetaanko työntekijä ${employee.name}?`,
    okText: 'Poista',
    okStyle: 'danger',
    cancelText: msgs.cancel,
    onCancel: () => {},
    onOk: onConfirm,
  });
};
types.removeEmployee = P.Object;

effects.updateQuery = guardHandled(async (query) => {
  acts.updateQuery(query);
  pushQuery(mergeLeft(sels.query()));

  await fetchEmployees();
});
types.updateQuery = P.Object;

effects.destroy = async () => {
  acts.reset();
};

export default createEffects(namespace, services.get('channel').dispatch, types, effects);
