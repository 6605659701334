import React from 'react';
import {Controller} from 'react-hook-form';
import ReactSelect from 'react-select';
import AsyncSelect from 'react-select/async';
import CreatableSelect from 'react-select/creatable';
import {Tag, Wrap, WrapItem, IconButton, Icon} from '@chakra-ui/react';
import {FiX} from 'react-icons/fi';
import colors from 'theme/foundations/color';
import shadows from 'theme/foundations/shadows';

const getSelectStyles = (hasError, renderMultiOutside, withLabel) => ({
  control: (base, state) => ({
    ...base,
    minHeight: withLabel ? '3rem' : '0',
    alignItems: withLabel ? 'flex-end' : 'center',
    backgroundColor: state.isFocused ? colors.gray['50'] : '#fff',
    fontSize: '0.875rem',
    // prettier-ignore
    boxShadow: hasError ? '0 0 0 1px #ef4444'
      : state.isFocused ? shadows.md
      : 0,
    // prettier-ignore
    borderColor: hasError ? '#ef4444'
      : state.isFocused ? colors.gray['600']
      : colors.gray['300'],
    // prettier-ignore
    '&:hover': {
      borderColor: hasError ? '#ef4444'
      : state.isFocused ? colors.gray['600']
      : colors.gray['400'],
    },
  }),
  valueContainer: (base, state) => ({
    ...base,
    paddingLeft: withLabel ? '13px' : '8px',
    paddingTop: withLabel ? 0 : '2px',
    paddingBottom: withLabel ? 0 : '2px',
    marginTop:
      state.isMulti && state.hasValue && !renderMultiOutside && withLabel ? '1.5rem' : 0,
    marginBottom:
      state.isMulti && state.hasValue && !renderMultiOutside && withLabel ? '0.5rem' : 0,
  }),
  option: (base) => ({
    ...base,
    color: colors.gray['800'],
  }),
  placeholder: (base) => ({
    ...base,
    color: colors.gray['800'],
  }),
  menu: (base) => ({
    ...base,
    zIndex: 20,
    fontSize: '0.875rem',
  }),
  menuPortal: (base) => ({
    ...base,
    zIndex: 9999,
  }),
  multiValue: (base) => ({
    ...base,
    display: renderMultiOutside ? 'none' : 'flex',
    backgroundColor: colors.gray['200'],
    fontWeight: 800,
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: colors.gray['600'],
    // prettier-ignore
    '&:hover': {
      color: colors.gray['700'],
    },
  }),
  clearIndicator: (base) => ({
    ...base,
    color: colors.gray['600'],
    // prettier-ignore
    '&:hover': {
      color: colors.gray['700'],
    },
  }),
  indicatorSeparator: (base) => ({
    ...base,
    backgroundColor: colors.gray['300'],
  }),
});

const getRsValue = ({value, options = [], getOptionValue}) => {
  const rsifyVal = (v) =>
    options.find((o) => getOptionValue(o) === v) || {label: v, value: v};
  // prettier-ignore
  return value === '' ? null
    : value == null ? value
    : Array.isArray(value) ? value.map(rsifyVal)
    : rsifyVal(value);
};

const SelectComp = ({async, creatable, innerRef, ...props}) =>
  async ? (
    <AsyncSelect ref={innerRef} {...props} />
  ) : creatable ? (
    <CreatableSelect ref={innerRef} {...props} />
  ) : (
    <ReactSelect ref={innerRef} {...props} />
  );

const Select = ({
  value,
  onChange = () => {},
  options,
  async,
  useOptionsAsValues,
  hasError,
  getOptionValue = (opt) => opt.value,
  getOptionLabel = (opt) => opt.label,
  noOptionsMessage = ({inputValue}) =>
    async && inputValue === '' ? 'Kirjoita hakeaksesi...' : 'Ei valintoja',
  placeholder = 'Valitse...',
  loadingMessage = () => 'Ladataan...',
  creatable,
  renderMultiOutside,
  innerRef,
  withLabel = true,
  ...rest
}) => {
  let rsValue = value;
  let rsOnChange = onChange;

  // conversions between option objects and values
  if (!(async || useOptionsAsValues)) {
    rsValue = getRsValue({
      value,
      options,
      getOptionValue,
    });
    rsOnChange = (val, ...rest) => {
      // prettier-ignore
      const outVal = val == null ? val
        : Array.isArray(val) ? val.map(getOptionValue)
        : getOptionValue(val)
      onChange(outVal, ...rest);
    };
  }

  return (
    <div>
      <SelectComp
        async={async}
        creatable={creatable}
        value={useOptionsAsValues ? value : rsValue}
        onChange={useOptionsAsValues ? onChange : rsOnChange}
        options={options}
        placeholder={placeholder}
        styles={getSelectStyles(hasError, renderMultiOutside, withLabel)}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary50: colors.gray['100'],
            primary25: colors.gray['100'],
            primary: colors.gray['100'],
          },
        })}
        noOptionsMessage={noOptionsMessage}
        loadingMessage={loadingMessage}
        getOptionValue={getOptionValue}
        getOptionLabel={getOptionLabel}
        innerRef={innerRef}
        {...rest}
      />
      {renderMultiOutside && Array.isArray(value) && (
        <Wrap spacing={2} mt={2}>
          {value.map((v) => {
            const opt = getRsValue({
              value: v,
              options,
              getOptionValue,
            });
            return (
              <WrapItem key={opt.value}>
                <Tag size="sm" pr={0}>
                  {opt.label}
                  <IconButton
                    onClick={() => onChange(value.filter((x) => x !== opt.value))}
                    size="xs"
                    boxShadow="none"
                    borderRadius={0}
                    ml={1}
                    icon={<Icon as={FiX} />}
                  />
                </Tag>
              </WrapItem>
            );
          })}
        </Wrap>
      )}
    </div>
  );
};

// react-hook-form compatible react select
const SelectContainer = ({name, control, rules, standalone = !control, ...rest}) => {
  // use standalone prop when using select outside react-hook-form
  if (standalone) {
    return <Select {...rest} />;
  }

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({field: {onChange, onBlur, value}}) => (
        <Select {...rest} value={value} onChange={onChange} onBlur={onBlur} />
      )}
    />
  );
};

export default SelectContainer;
