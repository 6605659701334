import {prop} from 'ramda';
import services from 'services';
import {describeThrow} from 'utils/errors';
import msgs from 'dicts/messages';

let httpJson = null;
services.waitFor('api').then((x) => (httpJson = x.httpJson));

export const getSite = (id) =>
  httpJson('get', `/sites/${id}`, {
    include: 'users,organization,subContractors',
  })
    .catch(describeThrow(msgs.fetchFailed))
    .then(prop('data'));

export const getExtraCosts = ({siteId, dateRange}) =>
  httpJson(
    'get',
    '/extraCosts',
    {
      sort: 'created_at',
      include: 'user',
      'filter[site_id]': siteId,
      'filter[startDateBetween]': dateRange,
    },
    {},
  )
    .catch(describeThrow(msgs.fetchFailed))
    .then(prop('data'));

export const putSite = (site) =>
  httpJson('put', `/sites/${site.id}`, {include: 'subContractors'}, {body: site})
    .catch(describeThrow(msgs.saveFailed))
    .then(prop('data'));

export const getAddableEmployees = (siteId) =>
  httpJson(
    'get',
    '/users',
    {
      'filter[site_addable_users]': siteId,
      withoutCurrentOrgCheck: 'withoutCurrentOrgCheck',
    },
    {},
  )
    .catch(describeThrow(msgs.fetchFailed))
    .then(prop('data'));

export const setSiteEmployees = ({id, employees}) =>
  httpJson('put', `/sites/${id}`, {}, {body: {users: employees}})
    .catch(describeThrow(msgs.saveFailed))
    .then(prop('data'));

export const setSiteSubContractors = ({id, subContractors}) =>
  httpJson('put', `/sites/${id}`, {}, {body: {organizations: subContractors}})
    .catch(describeThrow(msgs.saveFailed))
    .then(prop('data'));

export const getExpenses = (query) =>
  httpJson('get', '/expenses', {
    include: 'expenseItems',
    ...query,
  })
    .catch(describeThrow(msgs.fetchFailed))
    .then(prop('data'));

export const postExpense = (body) =>
  httpJson('post', '/expenses', {}, {body})
    .catch(describeThrow(msgs.saveFailed))
    .then(prop('data'));

export const putExpense = (expense) =>
  httpJson('put', `/expenses/${expense.id}`, {}, {body: expense})
    .catch(describeThrow(msgs.saveFailed))
    .then(prop('data'));

export const deleteExpense = (id) =>
  httpJson('delete', `/expenses/${id}`).catch(describeThrow(msgs.deleteFailed));

export const postExpenseItem = (body) =>
  httpJson('post', '/expenseItems', {}, {body})
    .catch(describeThrow(msgs.saveFailed))
    .then(prop('data'));

export const putExpenseItem = (item) =>
  httpJson('put', `/expenseItems/${item.id}`, {}, {body: item})
    .catch(describeThrow(msgs.saveFailed))
    .then(prop('data'));

export const deleteExpenseItem = (id) =>
  httpJson('delete', `/expenseItems/${id}`).catch(describeThrow(msgs.deleteFailed));

export const getDocuments = (query) =>
  httpJson('get', '/documents', {sort: '-updated_at', ...query})
    .catch(describeThrow(msgs.fetchFailed))
    .then(prop('data'));

export const postDocument = (body) =>
  httpJson('post', '/documents', {}, {body})
    .catch(describeThrow(msgs.saveFailed))
    .then(prop('data'));

export const putDocument = (doc) =>
  httpJson('put', `/documents/${doc.id}`, {}, {body: doc})
    .catch(describeThrow(msgs.saveFailed))
    .then(prop('data'));

export const deleteDocument = (id) =>
  httpJson('delete', `/documents/${id}`).catch(describeThrow(msgs.deleteFailed));

export const getTimeEntries = (query) =>
  httpJson('get', '/timeEntries', query)
    .catch(describeThrow(msgs.fetchFailed))
    .then(prop('data'));

export const sendEmployeeNotice = ({id, data}) =>
  httpJson(
    'post',
    `/sites/${id}/sendEmployeeNotice`,
    {},
    {
      body: data,
    },
  )
    .catch(describeThrow(msgs.opSuccess))
    .then(prop('data'));

export const sendContractNotice = ({id, data}) =>
  httpJson(
    'post',
    `/sites/${id}/sendContractNotice`,
    {},
    {
      body: data,
    },
  )
    .catch(describeThrow(msgs.opSuccess))
    .then(prop('data'));
