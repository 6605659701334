import namespace from './namespace';
import {scopedStore} from 'utils/redux';

export const initialState = {
  initialized: false,
  loading: false,
  processing: false,
  reportageIndex: 0,
  siteQuery: {name: '', siteId: null},
  userQuery: {name: '', siteId: null, organizationId: null},
  dateRange: {startDate: null, endDate: null},
  referrerUrlBoolean: null,
  siteEntries: [],
  userEntries: [],
  userReportage: null,
  userReportageLoading: false,
  siteReportage: null,
  siteReportageLoading: false,
  generateReportageModalOpen: false,
  includeBreaksAndPickups: false,
};

export const cases = {
  setLoading: (st, all, value) => {
    return {...st, loading: value};
  },
  setReferrerUrlBoolean: (st, all) => {
    return {...st, referrerUrlBoolean: true};
  },

  setIncludeBreaksAndPickups: (st, all, value) => {
    return {...st, setIncludeBreaksAndPickups: value};
  },

  setGenerateReportageModalOpen: (st, all, value) => {
    return {...st, generateReportageModalOpen: value};
  },

  setReportageIndex: (st, all, value) => {
    return {...st, reportageIndex: value};
  },

  updateSiteQuery: (st, all, payload) => {
    return {...st, siteQuery: {...st.siteQuery, ...payload}, loading: true};
  },

  updateUserQuery: (st, all, payload) => {
    return {...st, userQuery: {...st.userQuery, ...payload}, loading: true};
  },

  updateDateRange: (st, all, range) => {
    return {...st, dateRange: {startDate: range.startDate, endDate: range.endDate}};
  },

  setInitialized: (st, all, val) => {
    return {...st, initialized: val};
  },

  setProcessing: (st, all, value) => {
    return {...st, processing: value};
  },

  setSiteEntries: (st, all, siteEntries) => {
    return {...st, siteEntries};
  },

  setUserEntries: (st, all, userEntries) => {
    return {...st, userEntries};
  },

  setUserReportage: (st, all, data) => {
    return {...st, userReportage: data};
  },
  setUserReportageLoading: (st, all, value) => {
    return {...st, userReportageLoading: value};
  },
  setSiteReportage: (st, all, data) => {
    return {...st, siteReportage: data};
  },
  setSiteReportageLoading: (st, all, value) => {
    return {...st, siteReportageLoading: value};
  },

  reset: (st) => {
    return initialState;
  },
};

export const {actions, reducer} = scopedStore(namespace, cases, initialState);
