import namespace from './namespace';
import {createEffects} from 'utils/events';
import services from 'services';
import {pipe} from 'ramda';
import _acts from './boundActions';
import {P, Record} from 'utils/types';
import {setPageTitleMessage, decorateWithNotificationsEff} from 'io/app';
import {warnEff, guardNonFatalEff, guardHandled} from 'io/errors';
import {getQuery} from 'io/history';
import commonEffs from 'modules/common/effects';
import nEffs from 'modules/notifications/effects';
import {longerDur} from 'constants/notifications';
import {describeError} from 'utils/errors';
import {sendResetLink, postResetPassword} from './io';

let acts;
_acts.then((x) => (acts = x));

let httpJson = null;
services.waitFor('api').then((x) => (httpJson = x.httpJson));

const history = services.get('history');

let effects = {};
let types = {};

effects.initialize = async () => {
  setPageTitleMessage('Kirjaudu sisään');
};

effects.login = guardNonFatalEff(async (credentials) => {
  acts.setLoading(true);

  let token;
  try {
    const res = await httpJson('post', '/login', {}, {body: credentials});
    token = res.token;
  } catch (e) {
    acts.setLoading(false);

    const msg =
      e.response && [401, 422].includes(e.response.status)
        ? 'Kirjautuminen epäonnistui - virheellinen sähköpostiosoite tai salasana'
        : 'Kirjautuminen epäonnistui';
    const eNew = describeError(msg, e);
    warnEff(nEffs.warning, {id: 'login-fail', duration: longerDur}, eNew);

    throw eNew;
  }

  acts.setLoading(false);

  commonEffs.afterLogin(token);
});
types.login = Record({email: P.String, password: P.String});

effects.destroy = async () => {
  acts.reset();
};

effects.forgotPassword = guardHandled(async (data) => {
  acts.setProcessing(true);

  try {
    await decorateWithNotificationsEff(
      {
        id: 'forgot-password',
        failureStyle: 'error',
        success: 'Salasanan palautuslinkki on lähetetty sähköpostiisi',
        successDuration: longerDur,
      },
      sendResetLink(data),
    );
    history.replace('/');
    acts.setProcessing(false);
  } catch (e) {
    acts.setProcessing(false);
    throw e;
  }
});
types.forgotPassword = Record({email: P.String});

effects.resetPassword = guardHandled(async (formData) => {
  acts.setProcessing(true);
  const {token} = getQuery();

  try {
    await decorateWithNotificationsEff(
      {
        id: 'reset-password',
        failureStyle: 'error',
        success: 'Salasana asetettu. Voit nyt kirjautua uudella salasanallasi.',
        successDuration: longerDur,
      },
      postResetPassword({token, ...formData}),
    );
    history.replace('/');
    acts.setProcessing(false);
  } catch (e) {
    acts.setProcessing(false);
    throw e;
  }
});
types.resetPassword = Record({
  email: P.String,
  password: P.String,
  password_confirmation: P.String,
});

export default pipe(createEffects(namespace, services.get('channel').dispatch, types))(
  effects,
);
