import {formatEventWithTime} from 'utils/events';

// NOTE: could make this into an rxjs observable to enable fancier operations on events, instead of the post-event callbacks. the pre-event functions still need to be provided by the channel, so that they can be used for mapping and filtering events before they happen.
export default (conf = {}) => {
  const {handlers = [], listeners = []} = conf;

  const dispatch = (evt) => {
    let e = evt;
    for (const func of handlers) {
      e = func(e);
      if (!e) {
        return;
      }
    }
    e.effect(e.payload);
    listeners.forEach((func) => func(e));
  };

  const addHandler = (f) => {
    handlers.push(f);
  };

  const addListener = (f) => {
    listeners.push(f);
  };

  return {dispatch, addHandler, addListener};
};

// TODO: move the stuff below elsewhere!

export const injectStack = (evt) => {
  return {...evt, '@@stack': new Error().stack.split('\n').slice(1)};
};

export const createEventLog = (conf = {}) => {
  const {maxEntries = 1000} = conf;

  let events = [];

  const logEvent = (evt) => {
    // we mutate the event directly to spare unnecessary performance draw
    evt.time = new Date();
    events.push(evt);
    // we keep a buffer of 100 extra events for performance reasons, because continuously shifting the array on every event by 1 once the event limit is exceeded probably isn't super performant in JS.
    if (maxEntries && events.length > maxEntries + 100) {
      events.shift(101);
    }
    return evt;
  };

  return {logEvent, events};
};

export const formatLatestEvents = (events, count = 20) => {
  const latest = events.slice(-count).reverse();
  return latest.map(formatEventWithTime).join('\n');
};

export const printLatestEvents = (events, count) => {
  // eslint-disable-next-line no-console
  console.info(formatLatestEvents(events, count));
};
