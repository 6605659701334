import React from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  IconButton,
  Menu,
  Portal,
  MenuButton,
  MenuList,
  MenuItem,
  Text,
  Icon,
} from '@chakra-ui/react';
import {FiMoreHorizontal, FiChevronDown} from 'react-icons/fi';
import {sToTime} from 'utils/time';

const TimeEntryRow = ({entry, onRowClick, onEditClick, setEditableEntries}) => {
  return (
    <Tr>
      <Td textStyle="small">
        <strong>{entry.day}</strong>
      </Td>
      <Td textStyle="small">{entry.siteName}</Td>
      <Td textStyle="small">{sToTime(entry.totalTime.total)}</Td>

      <Td textAlign="right">
        <Menu>
          <MenuButton
            as={IconButton}
            variant="ghost"
            size="md"
            aria-label="more"
            icon={<FiMoreHorizontal />}
            onClick={(e) => e.stopPropagation()}
          />
          <Portal>
            <MenuList>
              <MenuItem
                onClick={(e) => {
                  e.stopPropagation();
                  setEditableEntries(entry.timeEntries);
                  onEditClick();
                }}
              >
                <Text fontSize="sm" fontWeight="bold">
                  Muokkaa
                </Text>
              </MenuItem>
            </MenuList>
          </Portal>
        </Menu>
      </Td>
    </Tr>
  );
};

const TimeEntriesTable = ({siteEntries, editTimeEntries, setEditableEntries}) => {
  return (
    <>
      <Table overflow="auto">
        <Thead>
          <Tr>
            <Th>
              Päivämäärä
              <IconButton
                variant="ghost"
                colorScheme="blue"
                onClick={() => {}}
                size="sm"
                icon={<Icon as={FiChevronDown} />}
              />
            </Th>
            <Th>
              Työmaa
              <IconButton
                variant="ghost"
                colorScheme="blue"
                onClick={() => {}}
                size="sm"
                icon={<Icon as={FiChevronDown} />}
              />
            </Th>
            <Th>
              Aika
              <IconButton
                colorScheme="blue"
                variant="ghost"
                onClick={() => {}}
                size="sm"
                icon={<Icon as={FiChevronDown} />}
              />
            </Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {siteEntries.map((e, index) => (
            <TimeEntryRow
              key={index}
              entry={e}
              onEditClick={editTimeEntries}
              setEditableEntries={setEditableEntries}
            />
          ))}
        </Tbody>
      </Table>
    </>
  );
};

export default TimeEntriesTable;
