import {curry} from 'ramda';

export const describeError = curry((description, error) => {
  error.description = description;
  return error;
});

export const describeThrow = curry((description, error) => {
  throw describeError(description, error);
});

// useful for managing control flow with promises.
export const handledError = (msg) => {
  let error = new Error(msg);
  error.wasLogged = true;
  // non-described errors are considered unexpected, so provide a default description for situations where there's no need to show the error's description to the user (can still use "describeError" additionally)
  error.description = '';
  return error;
};
