import {parseISO, parse, startOfDay, endOfDay} from 'date-fns';

const trim = (s) => (s ? s.trim() : '');

export const fullAddress = (site) => {
  const street = trim(site.address) || '';

  const city = [site.postcode, site.city]
    .map(trim)
    .filter((s) => !!s)
    .join(' ');
  return [street, city].filter((s) => !!s).join(', ');
};

export const formatSiteDefaultVals = (site) => {
  if (!site) return {};

  return {
    name: site.name,
    address: site.address,
    postcode: site.postcode,
    city: site.city,
    location:
      site.latitude && site.longitude ? `${site.latitude}, ${site.longitude}` : '',
    startDate: site.startDate ? parseISO(site.startDate) : '',
    endDate: site.endDate ? parseISO(site.endDate) : '',
    budget: site.budget ? `${site.budget}` : '',
    checkLocation: site.checkLocation,
    allowedRadius: site.allowedRadius,
    employeeCosts: site.employeeCosts ? `${site.employeeCosts}` : '',
    state: site.state,
  };
};

export const formatEmployeeDefaultVals = (employee, organization) => {
  if (!employee) return {};

  const {
    employmentStart,
    employmentEnd,
    hourPrice,
    baseWage,
    wageMultiplier,
    sitePermission,
  } = employee;

  const organizationId = organization.id;

  return {
    employmentStart: employmentStart
      ? parse(employmentStart, 'yyyy-MM-dd', new Date())
      : '',
    employmentEnd: employmentEnd ? parse(employmentEnd, 'yyyy-MM-dd', new Date()) : '',
    hourPrice:
      employee.organizationId === organizationId ? baseWage * wageMultiplier : hourPrice,
    sitePermission,
  };
};

export const formatExpenseDefaultVals = (expense) => {
  if (!expense) {
    return {
      title: '',
      totalBudget: '',
      expenseItems: [{_id: '', date: '', sum: '', description: ''}],
    };
  }

  return {
    title: expense.title,
    class: expense.class,
    totalBudget: expense.totalBudget,
    expenseItems:
      expense.expenseItems?.map((item) => ({
        _id: item.id,
        date: item.date ? parseISO(item.date) : '',
        sum: item.sum,
        description: item.description,
      })) || [],
  };
};

export const formatFetchableEmployeeNoticesQuery = (query, site) => {
  return {
    groupByUser: true,
    'filter[siteId]': site?.id,
    'filter[startDateBetween]': [
      query.startDate ? startOfDay(query.startDate).toISOString() : null,
      query.endDate ? endOfDay(query.endDate).toISOString() : null,
    ],
    'filter[processed]': 0,
    'filter[organizationId]': query.organizationId,
  };
};

export const formatContractNoticeQuery = (dateRange, site) => {
  return {
    groupByOrganization: true,
    siteId: site.id,
    'filter[startDateBetween]': [
      dateRange.startDate ? startOfDay(dateRange.startDate).toISOString() : null,
      dateRange.endDate ? endOfDay(dateRange.endDate).toISOString() : null,
    ],
  };
};
