import React, {useState} from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalHeader,
  ModalCloseButton,
  ModalFooter,
  Button,
  HStack,
  Box,
  Text,
  Switch,
  VStack,
} from '@chakra-ui/react';

const GenerateCsvModal = ({
  isOpen,
  onClose = () => {},
  generateSiteCsv,
  generateEmployeeCsv,
  reportageIndex,
  includeBreaksAndPickups,
}) => {
  const [checked, setChecked] = useState(includeBreaksAndPickups);

  const onSubmit = () => {
    if (reportageIndex === 1) {
      generateEmployeeCsv(checked);
    }

    if (reportageIndex === 0) {
      generateSiteCsv(checked);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} size="md">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Raportin Luonti</ModalHeader>
        <ModalCloseButton />
        <ModalBody px={6}>
          <VStack display="flex">
            <Box mr={6} pb={1}>
              Näytä raportilla myös tauot ja noudot
            </Box>
            <HStack alignContent={'center'}>
              <Text>Ei</Text>
              <Switch
                isChecked={checked}
                defaultChecked={checked}
                onChange={() => {
                  setChecked(!checked);
                }}
              />
              <Text>Kyllä</Text>
            </HStack>
          </VStack>
        </ModalBody>
        <ModalFooter borderTopWidth={0}>
          <Button colorScheme="blue" variant="solid" mr="6" onClick={onSubmit}>
            Luo raportti
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default GenerateCsvModal;
