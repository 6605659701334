// https://github.com/chakra-ui/chakra-ui/blob/52230c5ac62301993ae6ac47ad658987e5822581/packages/chakra-ui/src/theme/theme.js#L8
import {extendTheme} from '@chakra-ui/react';

import global from './global';

import colors from './foundations/color';
import shadows from './foundations/shadows';
import typography from './foundations/typography';
import radii from './foundations/radius';
import spacing from './foundations/spacing';

import textStyles from './styles/textStyles';
import layerStyles from './styles/layerStyles';

import Button from './components/button';
import Tabs from './components/tabs';
import Input from './components/input';
import Badge from './components/badge';
import Table from './components/table';
import FormLabel from './components/form-label';
import Menu from './components/menu';
import Modal from './components/modal';
import Select from './components/select';

const theme = extendTheme({
  colors,
  shadows,
  ...typography,
  radii,
  spacing,

  styles: {
    global: {
      ...global,
    },
  },
  textStyles: {
    ...textStyles,
  },
  layerStyles: {
    ...layerStyles,
  },
  components: {
    Button,
    Input,
    Badge,
    Table,
    FormLabel,
    Menu,
    Modal,
    Tabs,
    Select,
  },
});

export default theme;
