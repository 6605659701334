export default {
  baseStyle: {
    groupTitle: {
      fontSize: 'xs',
      fontWeight: 'normal',
      color: 'gray.600',
      marginTop: '0.8rem',
      marginLeft: '0.8rem',
      marginRight: '0.8rem',
    },
    list: {
      zIndex: 999,
    },
  },
};
