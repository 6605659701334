import {curry, pipe, prop} from 'ramda';
import {over} from 'utils/lenses';
import {spreadProp} from 'utils/objects';

export const getResponseData = curry((transf, res) => pipe(prop('data'), transf)(res));

export const mapResponseData = curry((transf, res) =>
  pipe(over(['data'], transf), spreadProp('meta'))(res),
);

export const isApiTokenError = (error) =>
  !!error.response && error.response.status === 401;
