export const createFormData = (obj) => {
  const formData = new FormData();
  Object.keys(obj).forEach((k) => formData.append(k, obj[k]));
  return formData;
};

export const validateResponse = (res) => {
  if (res.ok) return Promise.resolve(res);
  const e = new Error(`Request failed: ${res.url}`);
  e.response = res;
  return Promise.reject(e);
};
