import React from 'react';
import {Box, ChakraProvider, CSSReset, PortalManager} from '@chakra-ui/react';
import 'focus-visible/dist/focus-visible'; // disable border for non-keyboard interactions
import {Switch, Route, Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import {pick} from 'ramda';

import {applyState} from 'utils/redux';
import commonSels from 'modules/common/selectors';
import theme from '../theme/theme';

import {Header, PageLoader} from 'components';
import Login from './login';
import Notifications from './notifications';
import Confirmer from './confirmer';
import Styles from './Styles';
import Sites from './sites/sites';
import Site from './sites/site';
import Employees from './employees/employees';
import Employee from './employees/employee';
import Organizations from './organizations/organizations';
import Organization from './organizations/organization';
import Reportage from './reportages';

const App = ({initialized, loggedIn, user, organizationId}) => {
  const orgID = [5, 4];

  const showOrganizationTab = orgID.includes(organizationId);
  return (
    <ChakraProvider theme={theme}>
      <CSSReset />
      <Notifications />
      <Confirmer />
      <PortalManager>
        {!initialized ? null : !loggedIn ? (
          <Switch>
            <Route component={Login} />
          </Switch>
        ) : !user ? (
          <PageLoader />
        ) : (
          <Box bg="gray.100" display="flex">
            <Box flex="1">
              <Header user={user} orgID={orgID} organizationId={organizationId} />
              {showOrganizationTab ? (
                <Switch>
                  <Redirect exact path="/" to="/sites" />
                  <Route path="/sites" exact component={Sites} />
                  <Route path="/sites/:siteId" exact component={Site} />
                  <Route path="/employees" exact component={Employees} />
                  <Route path="/employees/:employeeId" exact component={Employee} />
                  <Route path="/organizations" exact component={Organizations} />
                  <Route
                    path="/organizations/:organizationId"
                    exact
                    component={Organization}
                  />
                  <Route path="/reportage" exact component={Reportage} />
                  <Route path="/styles" exact component={Styles} />
                </Switch>
              ) : (
                <Switch>
                  <Redirect exact path="/" to="/sites" />
                  <Route path="/sites" exact component={Sites} />
                  <Route path="/sites/:siteId" exact component={Site} />
                  <Route path="/employees" exact component={Employees} />
                  <Route path="/employees/:employeeId" exact component={Employee} />
                  <Route
                    path="/organizations/:organizationId"
                    exact
                    component={Organization}
                  />
                  <Route path="/reportage" exact component={Reportage} />
                  <Route path="/styles" exact component={Styles} />
                </Switch>
              )}
            </Box>
          </Box>
        )}
      </PortalManager>
    </ChakraProvider>
  );
};

export default connect(
  applyState({
    ...pick(['initialized', 'loggedIn', 'user', 'organizationId'], commonSels),
  }),
)(App);
