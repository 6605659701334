import React from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  IconButton,
  Badge,
  Menu,
  Portal,
  MenuButton,
  MenuList,
  MenuItem,
  Text,
  Icon,
  HStack,
  Spacer,
} from '@chakra-ui/react';

import {FiMoreHorizontal, FiChevronDown} from 'react-icons/fi';
import _commonSels from 'modules/common/boundSelectors';

import {emptyPagination} from 'constants/pagination';
import {Paginator} from 'components';

let commonSels;
_commonSels.then((x) => (commonSels = x));

const EmployeeRow = ({employee, onRowClick, onDelete, referrerUrl}) => {
  const jobStatus = (id) => {
    const organizationId = commonSels.organization().id;

    if (id === organizationId) {
      return <Badge>Palkkasuhde</Badge>;
    } else {
      return <Badge colorScheme="green">Alihankinta</Badge>;
    }
  };

  return (
    <Tr
      onClick={() => onRowClick(employee.id)}
      _hover={{bg: 'gray.50', cursor: 'pointer'}}
    >
      <Td>
        <strong>{employee.name}</strong>
      </Td>
      <Td textStyle="small">{employee.address}</Td>
      <Td textStyle="small">{employee.organization.name}</Td>
      <Td>{jobStatus(employee.organizationId)}</Td>

      <Td textAlign="right">
        <Menu>
          <MenuButton
            as={IconButton}
            variant="ghost"
            size="md"
            aria-label="more"
            icon={<FiMoreHorizontal />}
            onClick={(e) => e.stopPropagation()}
          />
          <Portal>
            <MenuList>
              <MenuItem
                onClick={(e) => {
                  e.stopPropagation();
                  onDelete(employee);
                }}
              >
                <Text fontSize="sm" fontWeight="bold" color="red.600">
                  Poista
                </Text>
              </MenuItem>
            </MenuList>
          </Portal>
        </Menu>
      </Td>
    </Tr>
  );
};

const EmployeesTable = ({
  employees,
  onEmployeeClick,
  removeEmployee,
  processing,
  pagination = emptyPagination,
  onPageSelect = () => {},
  openEmployee = () => {},
}) => {
  return (
    <>
      <Table overflow="auto">
        <Thead>
          <Tr>
            <Th>
              Nimi
              <IconButton
                variant="ghost"
                colorScheme="blue"
                onClick={() => {}}
                size="sm"
                icon={<Icon as={FiChevronDown} />}
              />
            </Th>
            <Th>
              Osoite
              <IconButton
                colorScheme="blue"
                variant="ghost"
                onClick={() => {}}
                size="sm"
                icon={<Icon as={FiChevronDown} />}
              />
            </Th>
            <Th>
              Yritys
              <IconButton
                variant="ghost"
                colorScheme="blue"
                onClick={() => {}}
                size="sm"
              />
            </Th>
            <Th>
              Työsuhde
              <IconButton
                variant="ghost"
                colorScheme="blue"
                onClick={() => {}}
                size="sm"
                icon={<Icon as={FiChevronDown} />}
              />
            </Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {employees.map((e) => (
            <EmployeeRow
              key={e.id}
              employee={e}
              onRowClick={openEmployee}
              onDelete={removeEmployee}
            />
          ))}
        </Tbody>
      </Table>
      <HStack p={4}>
        <Spacer />
        <Paginator
          totalPages={pagination.last_page}
          currentPage={pagination.current_page}
          perPage={pagination.per_page}
          onPageSelect={onPageSelect}
          disabled={processing}
        />
      </HStack>
    </>
  );
};

export default EmployeesTable;
