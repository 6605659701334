import namespace from './namespace';
import {scopedStore} from 'utils/redux';

export const initialState = {
  loading: false,
  processing: false,
};

export const cases = {
  setLoading: (st, all, loading) => {
    return {...st, loading};
  },
  setProcessing: (st, all, processing) => {
    return {...st, processing};
  },
  reset: (st) => {
    return initialState;
  },
};

export const {actions, reducer} = scopedStore(namespace, cases, initialState);
