import React from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  HStack,
  Spacer,
  IconButton,
  Icon,
  Menu,
  Portal,
  MenuButton,
  MenuList,
  MenuItem,
  Text,
} from '@chakra-ui/react';
import {FiMoreHorizontal, FiChevronDown} from 'react-icons/fi';
import {emptyPagination} from 'constants/pagination';
import {Paginator} from 'components';

const OrganizationsRow = ({
  organization = {},
  onRowClick = () => {},
  onDelete = () => {},
  referrerUrl,
}) => {
  const sitesAmount = organization.sites.length;
  const contractSitesAmount = organization.contractSites.length;

  return (
    <Tr
      onClick={() => onRowClick(organization.id)}
      _hover={{bg: 'gray.50', cursor: 'pointer'}}
    >
      <Td>
        <strong>{organization.name}</strong>
      </Td>
      <Td>{organization.address}</Td>
      <Td>{organization.contact}</Td>
      <Td>{!organization.sites.length ? contractSitesAmount : sitesAmount}</Td>
      <Td textAlign="right">
        <Menu>
          <MenuButton
            as={IconButton}
            variant="ghost"
            size="md"
            aria-label="more"
            icon={<FiMoreHorizontal />}
            onClick={(e) => e.stopPropagation()}
          />
          <Portal>
            <MenuList>
              <MenuItem
                onClick={(e) => {
                  e.stopPropagation();
                  onDelete(organization);
                }}
              >
                <Text fontSize="sm" fontWeight="bold" color="red.600">
                  Poista
                </Text>
              </MenuItem>
            </MenuList>
          </Portal>
        </Menu>
      </Td>
    </Tr>
  );
};

const OrganizationsTable = ({
  processing,
  organizations = [],
  removeOrganization,
  pagination = emptyPagination,
  referrerUrl,
  onPageSelect = () => {},
  openOrganization = () => {},
}) => {
  return (
    <>
      <Table overflow="auto">
        <Thead>
          <Tr>
            <Th>
              Nimi
              <IconButton
                variant="ghost"
                colorScheme="blue"
                onClick={() => {}}
                size="sm"
                icon={<Icon as={FiChevronDown} />}
              />
            </Th>
            <Th>
              Osoite
              <IconButton
                variant="ghost"
                colorScheme="blue"
                onClick={() => {}}
                size="sm"
                icon={<Icon as={FiChevronDown} />}
              />
            </Th>
            <Th>Yhteyshenkilö</Th>
            <Th>
              Työmaat
              <IconButton
                variant="ghost"
                colorScheme="blue"
                onClick={() => {}}
                size="sm"
                icon={<Icon as={FiChevronDown} />}
              />
            </Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {organizations.map((o) => (
            <OrganizationsRow
              key={o.id}
              organization={o}
              onRowClick={openOrganization}
              onDelete={removeOrganization}
              referrerUrl={referrerUrl}
            />
          ))}
        </Tbody>
      </Table>
      <HStack p={4}>
        <Spacer />
        <Paginator
          totalPages={pagination.last_page}
          currentPage={pagination.current_page}
          perPage={pagination.per_page}
          onPageSelect={onPageSelect}
          disabled={processing}
        />
      </HStack>
    </>
  );
};

export default OrganizationsTable;
