import services from 'services';
import {describeThrow} from 'utils/errors';
import {prop} from 'ramda';
import msgs from 'dicts/messages';

let httpJson = null;
services.waitFor('api').then((x) => (httpJson = x.httpJson));

export const getOrganizations = (query = {}) =>
  httpJson('get', '/organizations', query, {}).catch(describeThrow(msgs.fetchFailed));

export const postOrganization = (body) =>
  httpJson('post', '/organizations', {}, {body})
    .catch(describeThrow(msgs.saveFailed))
    .then(prop('data'));

export const deleteOrganization = (id) =>
  httpJson('delete', `/organizations/${id}`).catch(describeThrow(msgs.saveFailed));
