import {prop} from 'ramda';
import services from 'services';
import {describeThrow} from 'utils/errors';
import msgs from 'dicts/messages';

let httpJson = null;
services.waitFor('api').then((x) => (httpJson = x.httpJson));

export const getUser = () =>
  httpJson('get', '/users/me', {include: 'organization.sites'}, {})
    .catch(describeThrow(msgs.fetchFailed))
    .then(prop('data'));

export const logout = () =>
  httpJson('post', '/logout', {}).catch(describeThrow(msgs.opFailed));

export const getOrganizations = () =>
  httpJson('get', '/organizations', {}, {}).catch(describeThrow(msgs.fetchFailed));
