import React from 'react';
import {
  Box,
  Stack,
  Alert,
  AlertDescription,
  AlertIcon,
  CloseButton,
  Spinner,
} from '@chakra-ui/react';
import {connect} from 'react-redux';
import {pick} from 'ramda';
import {applyState} from 'utils/redux';
import sels from 'modules/notifications/selectors';
import effs from 'modules/notifications/effects';

const typeToAlertStatus = {
  info: 'info',
  success: 'success',
  warning: 'warning',
  error: 'error',
  loading: 'info',
};

class Notifications extends React.Component {
  render() {
    const {notifications} = this.props;

    return (
      <Box position="fixed" top="0.5rem" zIndex="1500" width="100%" pointerEvents="none">
        <Stack spacing={3} margin="0 auto" maxWidth="700px" pointerEvents="auto">
          {notifications.map(({id, type, message}) => (
            <Alert key={id} status={typeToAlertStatus[type]} variant="left-accent">
              {type === 'loading' ? (
                <Spinner size="sm" color="blue.500" mr={3} />
              ) : (
                <AlertIcon />
              )}
              <AlertDescription>{message}</AlertDescription>
              <CloseButton
                onClick={() => effs.remove(id)}
                position="absolute"
                right="8px"
                top="8px"
              />
            </Alert>
          ))}
        </Stack>
      </Box>
    );
  }
}

export default connect(applyState(pick(['notifications'], sels)))(Notifications);
