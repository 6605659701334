export const parseUrlQuery = (query) => {
  let dateRange = null;
  if (query.dateRange) {
    dateRange = JSON.parse(query.dateRange);
    // startDate / endDate is never null since they're set in init
    dateRange.startDate = new Date(dateRange.startDate);
    dateRange.endDate = new Date(dateRange.endDate);
  }
  let user = null;
  if (query.user) {
    user = JSON.parse(query.user);
  }
  let site = null;
  if (query.site) {
    site = JSON.parse(query.site);
  }
  let referrerUrl = null;
  if (query.referrerUrl) {
    referrerUrl = query.referrerUrl;
  }
  return {
    referrerUrl: referrerUrl,
    user: user,
    site: site,
    dateRange: dateRange,
    reportageIndex: query.reportageIndex ? Number(query.reportageIndex) : null,
  };
};

export const formatSiteQueryFetchable = ({siteQuery, dateRange}) => {
  const startDate = dateRange.startDate.toISOString();
  const endDate = dateRange.endDate.toISOString();
  return {
    'filter[endDateBetween]': `${startDate},${endDate}`,
    // TODO: not currently supported by backend! add later!!
    //'filter[name]': siteQuery.name,
    'filter[siteId]': siteQuery.siteId,
    'filter[organizationId]': siteQuery.organizationId,
    groupBySite: 'true',
  };
};

export const formatUserQueryFetchable = ({userQuery, dateRange}) => {
  const startDate = dateRange.startDate.toISOString();
  const endDate = dateRange.endDate.toISOString();
  return {
    'filter[endDateBetween]': `${startDate},${endDate}`,
    'filter[user.name]': userQuery.name,
    'filter[siteId]': userQuery.siteId,
    'filter[organizationId]': userQuery.organizationId,
  };
};
