import {prop} from 'ramda';
import services from 'services';
import {describeThrow} from 'utils/errors';
import msgs from 'dicts/messages';

let httpJson = null;
services.waitFor('api').then((x) => (httpJson = x.httpJson));

export const getEmployee = (id) =>
  httpJson('get', `/users/${id}`, {include: 'sites,timeEntries, workPermits'}).catch(
    describeThrow(msgs.fetchFailed),
  );

export const getDayEntries = (query) =>
  httpJson('get', '/timeEntries', query, {})
    .catch(describeThrow(msgs.saveFailed))
    .then(prop('data'));

export const updateEmployee = (employee) =>
  httpJson('put', `/users/${employee.id}`, {}, {body: employee})
    .catch(describeThrow(msgs.saveFailed))
    .then(prop('data'));

export const createWorkPermit = (data) =>
  httpJson('post', '/workPermits', {}, {body: data})
    .catch(describeThrow(msgs.saveFailed))
    .then(prop('data'));

export const deleteWorkPermit = (id) =>
  httpJson('delete', `/workPermits/${id}`).catch(describeThrow(msgs.deleteFailed));

export const updateWorkPermit = ({permitId, permitData}) =>
  httpJson('put', `/workPermits/${permitId}`, {}, {body: permitData});
