import services from 'services';
import {describeThrow} from 'utils/errors';
import {prop} from 'ramda';
import msgs from 'dicts/messages';

let httpJson = null;
services.waitFor('api').then((x) => (httpJson = x.httpJson));

export const getEmployees = (query = {}) =>
  httpJson('get', '/users', query).catch(describeThrow(msgs.fetchFailed));

export const postEmployee = (body) =>
  httpJson('post', '/users', {}, {body})
    .catch(describeThrow(msgs.saveFailed))
    .then(prop('data'));

export const getActiveUsers = () =>
  httpJson('get', '/users/getActiveSiteUsersToday', {}).catch(
    describeThrow(msgs.fetchFailed),
  );

export const deleteEmployee = (id) =>
  httpJson('delete', `/users/${id}`).catch(describeThrow(msgs.deleteFailed));
