import React from 'react';
import {
  Wrap,
  WrapItem,
  Box,
  useRadio,
  useRadioGroup,
  useMediaQuery,
} from '@chakra-ui/react';
import {Controller} from 'react-hook-form';

const RadioButton = ({size, children, ...props}) => {
  const {getInputProps, getCheckboxProps} = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as="label" flex={1}>
      <input {...input} />
      <Box
        {...checkbox}
        cursor="pointer"
        borderRadius="base"
        boxShadow="base"
        bg="gray.200"
        color="gray.600"
        fontSize="sm"
        fontWeight="extrabold"
        _hover={{
          color: 'gray.900',
        }}
        _checked={{
          bg: 'blue.500',
          color: 'white',
        }}
        px={size === 'sm' ? 3 : 6}
        py={1}
        textAlign="center"
        whiteSpace="nowrap"
      >
        {children}
      </Box>
    </Box>
  );
};

const RadioButtonGroup = ({
  name,
  options,
  value,
  defaultValue,
  onChange,
  size,
  disabled,
  direction = 'row',
  ...rest
}) => {
  const {getRootProps, getRadioProps} = useRadioGroup({
    name: name,
    value: value,
    defaultValue: defaultValue,
    onChange: onChange,
  });

  const group = getRootProps();

  const [isXs] = useMediaQuery('(max-width: 30em)');

  return (
    <Wrap direction={direction} {...group} {...rest}>
      {options.map((o) => {
        const radio = getRadioProps({value: o.value});
        return (
          <WrapItem key={o.value} flex={isXs ? '1' : '0 1 auto'}>
            <RadioButton size={size} disabled={disabled} {...radio}>
              {o.label}
            </RadioButton>
          </WrapItem>
        );
      })}
    </Wrap>
  );
};

const RadioButtonGroupContainer = ({
  name,
  control,
  rules,
  defaultValue,
  standalone,
  ...props
}) => {
  // use standalone prop when using outside react-hook-form
  if (standalone) {
    return <RadioButtonGroup {...props} />;
  }

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={({field: {onChange, value}}) => (
        <RadioButtonGroup {...props} value={value} onChange={onChange} />
      )}
    />
  );
};

export default RadioButtonGroupContainer;
