import namespace from './namespace';
import {scopedStore} from 'utils/redux';

export const initialState = {
  employee: [],
  sites: [],
  workPermits: [],
  openedPermitId: [],
  processing: false,
  loading: false,
  entriesLoading: false,
  employeeModalOpen: false,
  workPermitModalOpen: false,
  siteEntries: [],
  dateRange: {startDate: null, endDate: null},
  siteIdFilter: '',
};

export const cases = {
  updateSiteIdFilter: (st, all, id) => {
    return {...st, siteIdFilter: id};
  },

  updateDateRange: (st, all, range) => {
    return {...st, dateRange: {startDate: range.startDate, endDate: range.endDate}};
  },

  setEmployee: (st, all, payload) => {
    return {
      ...st,
      employee: payload.data,
      loading: false,
      sites: payload.data.sites,
      workPermits: payload.data.workPermits,
    };
  },

  setWorkPermits: (st, all, payload) => {
    return {...st, workPermits: payload};
  },

  updateEmployee: (st, all, payload) => {
    return {
      ...st,
      employee: {...st.employee, ...payload},
    };
  },

  setLoading: (st, all, loading) => {
    return {...st, loading};
  },

  setEntriesLoading: (st, all, entriesLoading) => {
    return {...st, entriesLoading};
  },

  setProcessing: (st, all, processing) => {
    return {...st, processing};
  },

  openWorkPermitModal: (st, all, id) => {
    return {...st, workPermitModalOpen: true, openedPermitId: id};
  },

  closeWorkPermitModal: (st, all) => {
    return {...st, workPermitModalOpen: false, openedPermitId: null};
  },

  openEmployeeModal: (st, all) => {
    return {...st, employeeModalOpen: true};
  },

  closeEmployeeModal: (st, all) => {
    return {...st, employeeModalOpen: false};
  },

  setSiteEntries: (st, all, entries) => {
    return {...st, siteEntries: entries, setLoading: false};
  },

  reset: (st) => {
    return initialState;
  },
};

export const {actions, reducer} = scopedStore(namespace, cases, initialState);
