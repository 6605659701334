import {createBrowserHistory} from 'history';

export default () => {
  let history = createBrowserHistory();

  // nice hack - since the "history" package doesn't allow listening to pre-transition location, we keep a log of the previous value _after_ every transition!
  let currentLocation = history.location;
  history.prevLocation = null;
  history.listen((location) => {
    history.prevLocation = currentLocation;
    currentLocation = location;
  });

  return history;
};
