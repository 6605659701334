import React, {useEffect, useState, useRef} from 'react';
import {HStack, FormControl, FormLabel} from '@chakra-ui/react';
import {ReactDatepicker} from 'components';
import effs from 'modules/sites/sitePage/effects';

const ExtraCostsFilters = ({query = {}, updateQuery = () => {}}) => {
  const date = new Date();
  const [endDate, setEndDate] = useState(date);
  const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
  const [startDate, setStartDate] = useState(firstDayOfMonth);

  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else if (!(startDate && endDate)) {
      return;
    }
    effs.fetchExtraCosts({startDate, endDate});
  }, [startDate, endDate]);

  return (
    <HStack>
      <FormControl>
        <FormLabel>Alkaen</FormLabel>
        <ReactDatepicker
          name="startDate"
          standalone
          selected={startDate}
          maxDate={endDate}
          onChange={(date) => setStartDate(date)}
        />
      </FormControl>
      <FormControl>
        <FormLabel>Päättyen</FormLabel>
        <ReactDatepicker
          name="endDate"
          standalone
          selected={endDate}
          minDate={startDate}
          onChange={(date) => setEndDate(date)}
        />
      </FormControl>
    </HStack>
  );
};

export default ExtraCostsFilters;
