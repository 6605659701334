import {mergeLeft} from 'ramda';
import services from 'services';
import {P} from 'utils/types';
import {createEffects} from 'utils/events';
import {getQuery, pushQuery} from 'io/history';
import {guardHandled} from 'io/errors';
import {setPageTitleMessage, decorateWithNotificationsEff} from 'io/app';
import msgs from 'dicts/messages';
import _commonSels from 'modules/common/boundSelectors';
import confirmerEffs from 'modules/confirmer/effects';
import namespace from './namespace';
import _acts from './boundActions';
import _sels from './boundSelectors';
import {parseUrlQuery} from './utils';
import {
  getSites,
  postSite,
  deleteSite,
  getContractSites,
  sendContractNotice,
  sendEmployeeNotice,
} from './io';

let acts, sels, commonSels;
_acts.then((x) => (acts = x));
_sels.then((x) => (sels = x));
_commonSels.then((x) => (commonSels = x));

let effects = {};
let types = {};

const fetchSites = (notifyOpts = {}) => {
  acts.setLoading(true);

  return decorateWithNotificationsEff(
    {id: 'get-sites', failureStyle: 'warning', ...notifyOpts},
    getSites(sels.queryFetchable()),
  ).then(({data, meta}) => {
    acts.setSites({data, pagination: meta});
  });
};

const fetchContractSites = (notifyOpts = {}) => {
  acts.setContractSitesLoading(true);

  return decorateWithNotificationsEff(
    {id: 'get-contractSites', failureStyle: 'warning', ...notifyOpts},
    getContractSites(sels.contractSitesQueryFetchable()),
  ).then(({data, meta}) => {
    acts.setContractSites({data, contractSitesPagination: meta});
  });
};

effects.initialize = guardHandled(async () => {
  setPageTitleMessage('Työmaat');

  const query = parseUrlQuery(getQuery());
  acts.updateQuery(query);
  acts.updateContractSitesQuery(query);

  await fetchSites();
  await fetchContractSites();
});

effects.destroy = async () => {
  acts.reset();
};

effects.updateQuery = guardHandled(async (query) => {
  acts.updateQuery(query);
  pushQuery(mergeLeft(sels.query()));

  await fetchSites();
});
types.updateQuery = P.Object;

effects.updateContractSitesQuery = guardHandled(async (contractSitesQuery) => {
  acts.updateContractSitesQuery(contractSitesQuery);
  pushQuery(mergeLeft(sels.contractSitesQuery()));

  await fetchContractSites();
});
types.updateContractSitesQuery = P.Object;

effects.createSite = guardHandled(async (formData) => {
  const locationCoords = formData?.location;
  const splittedCoords = locationCoords ? locationCoords.split(',') : 0;
  const allowedRadius = formData.allowedRadius ? formData.allowedRadius : 0;
  const checkLocation = formData.checkLocation ? formData.checkLocation : 0;

  const {location, ...data} = formData;

  try {
    acts.setProcessing(true);
    await decorateWithNotificationsEff(
      {
        id: 'create-site',
        failureStyle: 'error',
        success: 'Tallennettu',
      },
      postSite({
        ...data,
        checkLocation: checkLocation,
        allowedRadius: allowedRadius,
        latitude: parseFloat(splittedCoords[0]),
        longitude: parseFloat(splittedCoords[1]),
        organizationId: commonSels.organizationId(),
      }),
    );
    acts.toggleSiteModal();
    acts.setProcessing(false);
  } catch (e) {
    acts.setProcessing(false);
    throw e;
  }

  await fetchSites();
});
types.createSite = P.Object;

effects.removeSite = (site) => {
  const onConfirm = guardHandled(async () => {
    try {
      acts.setProcessing(true);
      await decorateWithNotificationsEff(
        {
          id: 'remove-site',
          failureStyle: 'error',
          loading: msgs.deleting,
          success: 'Työmaa poistettu',
        },
        deleteSite(site.id),
      );
      acts.setProcessing(false);
    } catch (e) {
      acts.setProcessing(false);
      throw e;
    }

    await fetchSites();
  });

  confirmerEffs.show({
    message: `Poistetaanko työmaa ${site.name}?`,
    okText: 'Poista',
    okStyle: 'danger',
    cancelText: msgs.cancel,
    onCancel: () => {},
    onOk: onConfirm,
  });
};
types.removeSite = P.Object;

effects.toggleSiteModal = () => {
  acts.toggleSiteModal();
};

effects.selectSites = (sites) => {
  acts.selectSites(sites);
};
types.selectSites = P.Array;

effects.clearSubContractorSelection = () => {
  acts.clearSiteSelection();
};

effects.openContractNoticeModal = guardHandled(async () => {
  acts.openContractNoticeModal();
  //await fetchContractNoticeRows();
});

effects.closeContractNoticeModal = guardHandled(async () => {
  acts.closeContractNoticeModal();
});

effects.updateContractNoticeDateRange = guardHandled(async (dateRange) => {
  acts.updateContractNoticeDateRange(dateRange);
  //await fetchContractNoticeRows();
});
types.updateContractNoticeDateRange = P.Object;

effects.sendContractNotice = async () => {
  const sitesToSend = sels.selectedSitesToSend();

  const organizationId = commonSels.organizationId();
  const {startDate, endDate} = sels.contractNoticeDateRange();
  const data = {
    organizationId,
    siteIds: sitesToSend,
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
  };

  acts.setProcessing(true);
  const file = await decorateWithNotificationsEff(
    {
      id: 'send-employeenotice',
      failureStyle: 'warning',
      success: 'Urakkailmoitus luotu ladattavaksi',
    },
    sendContractNotice({data}),
  );

  acts.setContractNoticeUrl(file.url);
  acts.setProcessing(false);
};

effects.selectSubContractors = (sites) => {
  acts.selectSites(sites);
};
types.selectSubContractors = P.Array;

effects.openEmployeeNoticeModal = guardHandled(async () => {
  acts.openEmployeeNoticeModal();
});

effects.closeEmployeeNoticeModal = () => {
  acts.closeEmployeeNoticeModal();
};

effects.sendEmployeeNotice = async () => {
  const sitesToSend = sels.selectedEmployeeNoticeSites();

  const organizationId = commonSels.organizationId();

  const {startDate, endDate} = sels.employeeNoticeQuery();
  const data = {
    organizationId,
    siteIds: sitesToSend,
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
  };

  acts.setProcessing(true);
  const file = await decorateWithNotificationsEff(
    {
      id: 'send-employeenotice',
      failureStyle: 'warning',
      success: 'Tiedosto luotu ladattavaksi',
    },
    sendEmployeeNotice({data}),
  );

  acts.setEmployeeNoticeUrl(file.url);
  acts.setProcessing(false);
};

effects.selectEmployees = (employees) => {
  acts.selectEmployees(employees);
};
types.selectEmployees = P.Array;

effects.clearEmployeeSelection = () => {
  acts.clearEmployeeSelection();
};

effects.updateEmployeeNoticeQuery = guardHandled(async (query) => {
  acts.updateEmployeeNoticeQuery(query);
  //await fetchEmployeeNoticeRows();
});
types.updateEmployeeNoticeQuery = P.Object;

export default createEffects(namespace, services.get('channel').dispatch, types, effects);
