import React from 'react';
import {HStack, Box} from '@chakra-ui/react';
import Breadcrumbs from './breadcrumbs';

const PageHeader = ({activeCrumbName, children}) => {
  return (
    <HStack pb={5} px={{base: 2, md: 0}} spacing={2}>
      <Box flex={1}>
        <Breadcrumbs activeCrumbName={activeCrumbName} />
      </Box>
      <Box>{children}</Box>
    </HStack>
  );
};

export default PageHeader;
