import React from 'react';
import {Table, Thead, Tbody, Tr, Th, Td, IconButton, Badge, Icon} from '@chakra-ui/react';

import {FiChevronDown} from 'react-icons/fi';
import {activityStatuses} from 'dicts/data';
import {sToTime} from 'utils/time';

const PresentEmployeeRow = ({employee, onRowClick, referrerUrl}) => {
  const ActivityStatusBadge = (activityStatus) => {
    if (activityStatus === 'Poissa') {
      return <Badge variant="muted">Poissa</Badge>;
    } else if (activityStatus === 'Paikalla') {
      return <Badge variant="primary">Paikalla</Badge>;
    } else if (activityStatus === 'Tauko') {
      return <Badge variant="danger">Tauko</Badge>;
    } else if (activityStatus === 'Nouto') {
      return <Badge variant="warning">Nouto</Badge>;
    } else if (activityStatus === 'Siirtymä') {
      return <Badge variant="success">Siirtymä</Badge>;
    }
  };

  return (
    <Tr
      onClick={() => onRowClick(employee.id)}
      _hover={{bg: 'gray.50', cursor: 'pointer'}}
    >
      <Td>
        <strong>{employee.name}</strong>
      </Td>
      <Td textStyle="small">{employee.site.name}</Td>
      <Td>{sToTime(employee.timeEntries)}</Td>
      <Td>{ActivityStatusBadge(activityStatuses[employee.activityStatus])}</Td>
    </Tr>
  );
};

const PresentEmployeesTable = ({
  employees,
  onEmployeeClick,
  processing,
  openEmployee = () => {},
}) => {
  return (
    <>
      <Table overflow="auto">
        <Thead>
          <Tr>
            <Th>
              Nimi
              <IconButton
                variant="ghost"
                colorScheme="blue"
                onClick={() => {}}
                size="sm"
                icon={<Icon as={FiChevronDown} />}
              />
            </Th>
            <Th>
              Työmaa
              <IconButton
                colorScheme="blue"
                variant="ghost"
                onClick={() => {}}
                size="sm"
                icon={<Icon as={FiChevronDown} />}
              />
            </Th>
            <Th>
              Päivän tunnit
              <IconButton
                variant="ghost"
                colorScheme="blue"
                onClick={() => {}}
                size="sm"
                icon={<Icon as={FiChevronDown} />}
              />
            </Th>
            <Th>
              Tila
              <IconButton
                variant="ghost"
                colorScheme="blue"
                onClick={() => {}}
                size="sm"
                icon={<Icon as={FiChevronDown} />}
              />
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {employees.map((e) => (
            <PresentEmployeeRow key={e.id} employee={e} onRowClick={openEmployee} />
          ))}
        </Tbody>
      </Table>
    </>
  );
};

export default PresentEmployeesTable;
