import namespace from './namespace';
import {scopedSelectors} from 'utils/redux';
import {createSelector} from 'reselect';
import {formatFetchableQuery} from './utils';

let sels = {};

sels.processing = (st) => st.processing;

sels.loading = (st) => st.loading;

sels.employeeModalOpen = (st) => st.employeeModalOpen;

sels.query = (st) => st.query;

sels.queryFetchable = createSelector(sels.query, (q) => formatFetchableQuery(q));

sels.employees = (st) => st.employees;

sels.activeEmployees = (st) => st.activeEmployees;

sels.pagination = (st) => st.pagination;

export default scopedSelectors(namespace, sels);
