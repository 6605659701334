import React from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  IconButton,
  Icon,
  Box,
  Center,
  Spinner,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Text,
} from '@chakra-ui/react';
import {FiChevronDown, FiMoreHorizontal} from 'react-icons/fi';
import {sToTime} from 'utils/time';

const SitesRow = ({site = {}, onRowClick, onEditClick, setEditableEntries}) => {
  return (
    <Tr
      onClick={(e) => {
        e.stopPropagation();
        setEditableEntries(site.timeEntries);
        onRowClick();
      }}
      _hover={{bg: 'gray.50', cursor: 'pointer'}}
    >
      <Td px={6} py={6}>
        <strong>{site.siteName}</strong>
      </Td>
      <Td>{site.siteAddress}</Td>
      <Td>{site.userCount.length}</Td>
      <Td>{sToTime(site.totalTime.total)}</Td>
      <Td textAlign="right">
        <Menu>
          <MenuButton
            as={IconButton}
            variant="ghost"
            size="md"
            aria-label="more"
            icon={<FiMoreHorizontal />}
            onClick={(e) => e.stopPropagation()}
          />
          <MenuList>
            <MenuItem
              onClick={(e) => {
                e.stopPropagation();
                onEditClick(site.siteId);
              }}
            >
              <Text fontSize="sm" fontWeight="bold">
                Siirry työmaalle
              </Text>
            </MenuItem>
          </MenuList>
        </Menu>
      </Td>
    </Tr>
  );
};

const SitesTab = ({
  siteEntries = [],
  loading,
  onSiteClick,
  editTimeEntries,
  setEditableEntries,
}) => {
  return (
    <Box py={4}>
      <Table overflow="auto">
        <Thead>
          <Tr>
            <Th>
              Työmaa
              <IconButton
                variant="ghost"
                colorScheme="blue"
                onClick={() => {}}
                size="sm"
                icon={<Icon as={FiChevronDown} />}
              />
            </Th>
            <Th>
              Osoite
              <IconButton
                variant="ghost"
                colorScheme="blue"
                onClick={() => {}}
                size="sm"
                icon={<Icon as={FiChevronDown} />}
              />
            </Th>
            <Th>
              Työntekijät
              <IconButton
                variant="ghost"
                colorScheme="blue"
                onClick={() => {}}
                size="sm"
                icon={<Icon as={FiChevronDown} />}
              />
            </Th>
            <Th>
              Työtunnit
              <IconButton
                variant="ghost"
                colorScheme="blue"
                onClick={() => {}}
                size="sm"
                icon={<Icon as={FiChevronDown} />}
              />
            </Th>
            <Th></Th>
          </Tr>
        </Thead>
        {loading ? null : (
          <Tbody>
            {siteEntries.map((s) => (
              <SitesRow
                key={s.siteId}
                site={s}
                onEditClick={onSiteClick}
                onRowClick={editTimeEntries}
                setEditableEntries={setEditableEntries}
              />
            ))}
          </Tbody>
        )}
      </Table>
      {loading ? (
        <Center p={6}>
          <Spinner size="xl" />
        </Center>
      ) : !siteEntries.length ? (
        <Box p={6}>Ei kirjauksia</Box>
      ) : null}
    </Box>
  );
};

export default SitesTab;
