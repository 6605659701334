import React from 'react';
import {Box} from '@chakra-ui/react';

const Card = ({children, ...props}) => {
  return (
    <Box bg="white" borderRadius={{base: 0, md: 'xl'}} boxShadow="xl" {...props}>
      {children}
    </Box>
  );
};

export default Card;
