import namespace from './namespace';
import {createEffects} from 'utils/events';
import services from 'services';
import _acts from './boundActions';
import _sels from './boundSelectors';
import {P} from 'utils/types';
//import {guardHandled} from 'io/errors';
import {setPageTitleMessage, decorateWithNotificationsEff} from 'io/app';
import {guardHandled} from 'io/errors';
import {getOrganization, updateOrganization} from './io';

let acts, sels;
_acts.then((x) => (acts = x));
_sels.then((x) => (sels = x));

let effects = {};
let types = {};

const fetchOrganizationEff = async (id) => {
  const organization = await decorateWithNotificationsEff(
    {id: 'get-organization', failureStyle: 'warning'},
    getOrganization(id),
  );
  acts.setOrganization(organization);
};

effects.updateOrganization = guardHandled(async (formData) => {
  try {
    acts.setProcessing(true);
    const organization = await decorateWithNotificationsEff(
      {
        id: 'update-organization',
        failureStyle: 'error',
        success: 'Tiedot päivitetty',
      },

      updateOrganization({...formData, id: sels.organization().id}),
    );
    acts.updateOrganization(organization);
    acts.closeOrganizationModal();
    acts.setProcessing(false);
  } catch (e) {
    acts.setProcessing(false);
    throw e;
  }
});
types.updateOrganization = P.Object;

effects.initialize = async (id) => {
  await fetchOrganizationEff(id);
  setPageTitleMessage(sels.organization().name);
};
types.initialize = P.Number;

effects.openOrganizationModal = () => {
  acts.openOrganizationModal();
};

effects.closeOrganizationModal = () => {
  acts.closeOrganizationModal();
};

effects.destroy = async () => {
  acts.reset();
};

export default createEffects(namespace, services.get('channel').dispatch, types, effects);
