import {createSelector} from 'reselect';
import namespace from './namespace';
import {scopedSelectors} from 'utils/redux';

let sels = {};

sels.initialized = (st) => st.initialized;

sels.apiToken = (st) => st.apiToken;

sels.loggedIn = (st, all) => !!sels.apiToken(st, all);

sels.user = (st) => st.user;

sels.organization = (st) => st.organization;

sels.organizationId = (st) => st.organization.id;

sels.organizations = (st) => st.organizations;

sels.pagination = (st) => st.pagination;

sels.organizationId = createSelector(sels.user, (user) =>
  user ? user.organizationId : null,
);

sels.organizationSites = (st) => st.organizationSites;

export default scopedSelectors(namespace, sels);
