import services from 'services';
import {includes} from 'ramda';
import {createEffects} from 'utils/events';
import {P, Nullable, Record} from 'utils/types';
import {guardHandled, logInfo} from 'io/errors';
import {setPageTitleMessage, decorateWithNotificationsEff} from 'io/app';
import namespace from './namespace';
import _acts from './boundActions';
import _sels from './boundSelectors';
import msgs from 'dicts/messages';
import confirmerEffs from 'modules/confirmer/effects';
import _commonSels from 'modules/common/boundSelectors';
import {
  getSite,
  putSite,
  getAddableEmployees,
  setSiteEmployees,
  getExpenses,
  postExpense,
  putExpense,
  deleteExpense,
  postExpenseItem,
  putExpenseItem,
  deleteExpenseItem,
  getDocuments,
  postDocument,
  putDocument,
  deleteDocument,
  getTimeEntries,
  setSiteSubContractors,
  getExtraCosts,
  sendEmployeeNotice,
  sendContractNotice,
} from './io';
import {formatDate} from 'utils/time';
import {uploadFiles as postUploadFiles} from 'io/files';

let acts, sels, commonSels;
_acts.then((x) => (acts = x));
_sels.then((x) => (sels = x));
_commonSels.then((x) => (commonSels = x));

let effects = {};
let types = {};

effects.initialize = guardHandled(async (id) => {
  setPageTitleMessage('Työmaa');

  await decorateWithNotificationsEff(
    {id: 'get-site', failureStyle: 'warning'},
    Promise.all([
      getSite(id).then((site) => acts.setSite(site)),
      getExtraCosts(id).then((extraCosts) => acts.setExtraCosts(extraCosts)),
      getDocuments({'filter[siteId]': id}).then((docs) => acts.setDocuments(docs)),
      getExpenses({...sels.expensesQueryFetchable(), 'filter[siteId]': id}).then(
        (expenses) => acts.setExpenses(expenses),
      ),
    ]),
  );
});
types.initialize = P.Number;

effects.fetchExtraCosts = async ({startDate, endDate}) => {
  const siteId = sels.site().id;
  const dateRange = `${startDate.toISOString()},${endDate.toISOString()}`;

  try {
    acts.setExtraCostsLoading(true);
    const siteExtraCosts = await getExtraCosts({siteId, dateRange});
    await acts.setExtraCosts(siteExtraCosts);
  } catch (e) {
    logInfo(e);
  }
};
types.fetchExtraCosts = Record({
  startDate: P.Date,
  endDate: P.Date,
});

const fetchAddableEmployeesEff = async ({notifyOpts = {}}) => {
  return decorateWithNotificationsEff(
    {
      id: 'get-employees',
      failureStyle: 'warning',
      loading: msgs.loading,
      ...notifyOpts,
    },
    getAddableEmployees(sels.site().id),
  ).then((data) => {
    acts.setAddableEmployees(data);
  });
};

const updateSiteUsers = guardHandled(async (id) => {
  const site = await decorateWithNotificationsEff(
    {id: 'get-site', failureStyle: 'warning', loading: msgs.loading},
    getSite(id),
  );
  acts.setSiteUsers(site.users);
});

const fetchExpenses = () => {
  return decorateWithNotificationsEff(
    {id: 'get-expenses', failureStyle: 'warning'},
    getExpenses(sels.expensesQueryFetchable()),
  ).then((expenses) => {
    acts.setExpenses(expenses);
  });
};

const fetchDocuments = () => {
  return decorateWithNotificationsEff(
    {id: 'get-documents', failureStyle: 'warning'},
    getDocuments({'filter[siteId]': sels.site().id}),
  ).then((docs) => {
    acts.setDocuments(docs);
  });
};

const fetchEmployeeNoticeRows = () => {
  return decorateWithNotificationsEff(
    {id: 'get-time-entries', failureStyle: 'warning'},
    getTimeEntries(sels.employeeNoticeQueryFetchable()),
  ).then((rows) => acts.setEmployeeNoticeRows(rows));
};

const fetchContractNoticeRows = () => {
  return decorateWithNotificationsEff(
    {id: 'get-time-entries', failureStyle: 'warning'},
    getTimeEntries(sels.contractNoticeQueryFetchable()),
  ).then((rows) => acts.setContractNoticeRows(rows));
};

effects.updateEmployeesQuery = (query) => {
  acts.updateEmployeesQuery(query);
};
types.updateEmployeesQuery = P.Object;

effects.toggleSiteModal = () => {
  acts.toggleSiteModal();
};

effects.openEmployeeModal = async (employeeId) => {
  acts.setProcessing(true);
  if (!employeeId) {
    await fetchAddableEmployeesEff({});
  }
  acts.openEmployeeModal(employeeId);
  acts.setProcessing(false);
};
types.openEmployeeModal = P.Number;

effects.closeEmployeeModal = () => {
  acts.closeEmployeeModal();
};

effects.setSelectedEmployeeId = async (employeeId) => {
  await acts.setSelectedEmployeeId(employeeId);
};
types.setSelectedEmployeeId = P.Number;

effects.openDropzoneModal = () => {
  acts.openFileUploaderModal();
};
types.openDropzoneModal = P.Object;

effects.closeDropzoneModal = () => {
  acts.closeFileUploaderModal();
};
types.closeDropzoneModal = P.Object;

effects.removeEmployee = (employee) => {
  const onConfirm = guardHandled(async () => {
    const siteId = sels.site().id;
    const siteEmployees = sels.users();
    const employees = siteEmployees.filter(
      (siteEmployee) => siteEmployee.id !== employee.id,
    );

    try {
      acts.setProcessing(true);

      await decorateWithNotificationsEff(
        {
          id: 'remove-siteEmployee',
          failureStyle: 'error',
          loading: msgs.deleting,
          success: 'Työntekijä poistettu',
        },
        setSiteEmployees({id: siteId, employees}),
      );

      acts.setProcessing(false);
    } catch (e) {
      acts.setProcessing(false);
      throw e;
    }

    await updateSiteUsers(siteId);
  });

  confirmerEffs.show({
    message: `Poistetaanko työntekijä ${employee.name}?`,
    okText: 'Poista',
    okStyle: 'danger',
    cancelText: msgs.cancel,
    onCancel: () => {},
    onOk: onConfirm,
  });
};
types.removeEmployee = P.Object;

effects.addEmployeeToSite = guardHandled(async (formData) => {
  const siteId = sels.site().id;
  const siteOrganizationId = sels.organization().id;
  const selectedEmployee = await sels.selectedEmployee();
  const organizationId = sels
    .addableEmployees()
    .find((e) => e.id === formData.id).organizationId;

  const hourPrice =
    siteOrganizationId !== selectedEmployee.organizationId
      ? selectedEmployee.baseWage
      : null;

  try {
    acts.setProcessing(true);

    const userData = {
      ...formData,
      hourPrice: !formData.hourPrice ? hourPrice : formData.hourPrice,
      organizationId,
      employmentStart: formatDate(formData.employmentStart),
      employmentEnd: formatDate(formData.employmentEnd),
    };
    const newUsers = [...sels.users(), userData];

    await decorateWithNotificationsEff(
      {
        id: 'employee-form',
        failureStyle: 'error',
        success: msgs.saveSuccess,
      },
      setSiteEmployees({employees: newUsers, id: sels.site().id}),
    );

    await updateSiteUsers(siteId);

    acts.closeEmployeeModal();
    acts.setProcessing(false);
  } catch (e) {
    acts.setProcessing(false);
    throw e;
  }
});
types.addEmployeeToSite = P.Object;

effects.updateEmployeeToSite = guardHandled(async (formData) => {
  const siteId = sels.site().id;
  const organizationId = sels.organization().id;
  const openedEmployeeId = sels.openedEmployeeId();
  const users = sels.users();
  const userToUpdate = users.find((u) => u.id === openedEmployeeId);
  const wageMultiplier = userToUpdate.wageMultiplier;

  try {
    acts.setProcessing(true);

    const userData = {
      ...userToUpdate,
      ...formData,
      hourPrice:
        userToUpdate.organizationId === organizationId
          ? parseInt(userToUpdate.baseWage) * wageMultiplier
          : parseInt(formData.hourPrice),
      employmentStart: formatDate(formData.employmentStart),
      employmentEnd: formatDate(formData.employmentEnd),
    };

    const newUsers = [...users.filter((x) => x.id !== openedEmployeeId), userData];

    await decorateWithNotificationsEff(
      {
        id: 'employee-form',
        failureStyle: 'error',
        success: msgs.saveSuccess,
      },
      setSiteEmployees({employees: newUsers, id: siteId}),
    );

    await updateSiteUsers(siteId);

    acts.closeEmployeeModal();
    acts.setProcessing(false);
  } catch (e) {
    acts.setProcessing(false);
    throw e;
  }
});
types.updateEmployeeToSite = P.Object;

effects.updateSite = guardHandled(async (formData) => {
  const locationCoords = formData.location;
  const splittedCoords = locationCoords.split(',');

  const {location, ...data} = formData;

  try {
    acts.setProcessing(true);

    const site = await decorateWithNotificationsEff(
      {
        id: 'update-site',
        failureStyle: 'error',
        success: 'Tallennettu',
      },

      putSite({
        ...data,
        latitude: parseFloat(splittedCoords[0]),
        longitude: parseFloat(splittedCoords[1]),
        id: sels.site().id,
      }),
    );

    acts.updateSite(site);
    acts.toggleSiteModal();
    acts.setProcessing(false);
  } catch (e) {
    acts.setProcessing(false);
    throw e;
  }
});
types.updateSite = P.Object;

effects.openExpenseModal = (expenseId) => {
  acts.openExpenseModal(expenseId);
};
types.openExpenseModal = Nullable(P.Number);

effects.closeExpenseModal = () => {
  acts.closeExpenseModal();
};

effects.saveExpense = guardHandled(async (formData) => {
  const {expenseItems, ...data} = formData;
  const activeExpense = sels.activeExpense();

  try {
    acts.setProcessing(true);
    await decorateWithNotificationsEff(
      {
        id: 'save-expense',
        failureStyle: 'error',
        success: 'Tallennettu',
      },
      activeExpense
        ? putExpense({...data, id: activeExpense.id}).then((expense) => {
            const deletedItems = activeExpense.expenseItems.filter(
              (item) => !expenseItems.find((x) => x._id === item.id),
            );
            return Promise.all([
              ...expenseItems.map((item) =>
                item._id
                  ? putExpenseItem({...item, id: item._id})
                  : postExpenseItem({...item, expenseId: expense.id}),
              ),
              ...deletedItems.map((item) => deleteExpenseItem(item.id)),
            ]);
          })
        : postExpense({...data, siteId: sels.site().id}).then((expense) => {
            return Promise.all(
              expenseItems.map((item) =>
                postExpenseItem({...item, expenseId: expense.id}),
              ),
            );
          }),
    );

    acts.closeExpenseModal();
    acts.setProcessing(false);
    await fetchExpenses();
  } catch (e) {
    acts.setProcessing(false);
    throw e;
  }
});
types.saveExpense = P.Object;

effects.removeExpense = (expense) => {
  const onConfirm = guardHandled(async () => {
    try {
      acts.setProcessing(true);
      await decorateWithNotificationsEff(
        {
          id: 'remove-expense',
          failureStyle: 'error',
          loading: msgs.deleting,
          success: 'Poistettu',
        },
        deleteExpense(expense.id),
      );
      acts.setProcessing(false);
      await fetchExpenses();
    } catch (e) {
      acts.setProcessing(false);
      throw e;
    }
  });

  confirmerEffs.show({
    message: `Poistetaanko kulu ${expense.title}?`,
    okText: 'Poista',
    okStyle: 'danger',
    cancelText: msgs.cancel,
    onCancel: () => {},
    onOk: onConfirm,
  });
};
types.removeExpense = P.Object;

effects.updateExpensesQuery = guardHandled(async (query) => {
  acts.updateExpensesQuery(query);
  await fetchExpenses();
});
types.updateExpensesQuery = P.Object;

effects.uploadFiles = guardHandled(async ({files, name}) => {
  try {
    acts.setProcessing(true);

    if (files.length) {
      const uploadedFiles = await decorateWithNotificationsEff(
        {
          id: 'upload-files',
          failureStyle: 'error',
        },
        postUploadFiles(files),
      );
      await decorateWithNotificationsEff(
        {
          id: 'post-documents',
          failureStyle: 'error',
          success: 'Tiedosto tallennettu',
        },
        postDocument({
          path: uploadedFiles.map((f) => f.path),
          name,
          siteId: sels.site().id,
        }),
      );
    }

    acts.setProcessing(false);
    acts.closeFileUploaderModal();
    await fetchDocuments();
  } catch (e) {
    acts.setProcessing(false);
    throw e;
  }
});
types.uploadFiles = P.Object;

effects.removeDocument = (id) => {
  const onConfirm = guardHandled(async () => {
    try {
      acts.setProcessing(true);
      await decorateWithNotificationsEff(
        {
          id: 'remove-document',
          failureStyle: 'error',
          loading: msgs.deleting,
          success: 'Dokumentti poistettu',
        },
        deleteDocument(id),
      );
      acts.removeDocument(id);
      acts.setProcessing(false);
    } catch (e) {
      acts.setProcessing(false);
      throw e;
    }
  });

  confirmerEffs.show({
    message: 'Poistetaanko dokumentti?',
    okText: 'Poista',
    okStyle: 'danger',
    cancelText: msgs.cancel,
    onCancel: () => {},
    onOk: onConfirm,
  });
};
types.removeDocument = P.Number;

effects.updateDocument = guardHandled(async (data) => {
  try {
    acts.setProcessing(true);
    const doc = await decorateWithNotificationsEff(
      {
        id: 'update-document',
        failureStyle: 'error',
        success: 'Dokumentti tallennettu',
      },
      putDocument({...data, id: sels.activeDocumentId()}),
    );
    acts.updateDocument(doc);
    acts.setProcessing(false);
    acts.closeDocumentEditor();
  } catch (e) {
    acts.setProcessing(false);
    throw e;
  }
});
types.updateDocument = P.Object;

effects.openDocumentEditor = (documentId) => {
  acts.openDocumentEditor(documentId);
};
types.openDocumentEditor = Nullable(P.Number);

effects.closeDocumentEditor = () => {
  acts.closeDocumentEditor();
};

effects.selectSubContractors = (subContractors) => {
  acts.selectsubContractors(subContractors);
};
types.selectSubContractors = P.Array;

effects.clearSubContractorSelection = () => {
  acts.clearSubContractorSelection();
};

effects.openContractNoticeModal = guardHandled(async () => {
  acts.openContractNoticeModal();
  await fetchContractNoticeRows();
});

effects.closeContractNoticeModal = guardHandled(async () => {
  acts.closeContractNoticeModal();
});

effects.updateContractNoticeQuery = guardHandled(async (query) => {
  acts.updateContractNoticeQuery(query);
  await fetchContractNoticeRows();
});
types.updateContractNoticeQuery = P.Object;

effects.sendContractNotice = async () => {
  const selection = sels.subContractorSelection();
  const rowsToSend = sels.contractNoticeRows().filter((x) => includes(x.id, selection));

  // TODO: Send selected rows to API
  const organizationIds = rowsToSend.map((o) => o.id);
  const organizationId = commonSels.organizationId();
  const id = sels.site().id;
  const {startDate, endDate} = sels.contractNoticeQuery();
  const data = {
    organizationId,
    organizationIds,
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
  };

  acts.setProcessing(true);
  const file = await decorateWithNotificationsEff(
    {
      id: 'send-employeenotice',
      failureStyle: 'warning',
      success: 'Urakkailmoitus luotu ladattavaksi',
    },
    sendContractNotice({id, data}),
  );

  acts.setContractNoticeUrl(file.url);
  acts.setProcessing(false);
};

effects.openEmployeeNoticeModal = guardHandled(async () => {
  acts.openEmployeeNoticeModal();
  await fetchEmployeeNoticeRows();
});

effects.closeEmployeeNoticeModal = () => {
  acts.closeEmployeeNoticeModal();
};

effects.sendEmployeeNotice = async () => {
  const selection = sels.employeeSelection();
  const rowsToSend = sels.employeeNoticeRows().filter((x) => includes(x.id, selection));

  // TODO: Send selected rows to API
  const userIds = rowsToSend.map((e) => e.id);
  const organizationId = commonSels.organizationId();
  const id = sels.site().id;
  const {startDate, endDate} = sels.employeeNoticeQuery();
  const data = {
    organizationId,
    userIds,
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
  };

  acts.setProcessing(true);
  const file = await decorateWithNotificationsEff(
    {
      id: 'send-employeenotice',
      failureStyle: 'warning',
      success: 'Tiedosto luotu ladattavaksi',
    },
    sendEmployeeNotice({id, data}),
  );

  acts.setEmployeeNoticeUrl(file.url);
  acts.setProcessing(false);
};

effects.selectEmployees = (employees) => {
  acts.selectEmployees(employees);
};
types.selectEmployees = P.Array;

effects.clearEmployeeSelection = () => {
  acts.clearEmployeeSelection();
};

effects.updateEmployeeNoticeQuery = guardHandled(async (query) => {
  acts.updateEmployeeNoticeQuery(query);
  await fetchEmployeeNoticeRows();
});
types.updateEmployeeNoticeQuery = P.Object;

effects.destroy = async () => {
  acts.reset();
};

effects.addOrganizationToSite = guardHandled(async (formData) => {
  const organizations = commonSels.organizations();

  try {
    acts.setProcessing(true);

    const newSubContractor = {id: formData.subcontractor};

    const siteOrganizations = [...organizations, newSubContractor];

    await decorateWithNotificationsEff(
      {
        id: 'site-subcontractors',
        failureStyle: 'error',
        success: msgs.saveSuccess,
      },
      setSiteSubContractors({organizations: siteOrganizations, id: sels.site().id}),
    );

    acts.toggleSiteModal();
    acts.setProcessing(false);
  } catch (e) {
    acts.setProcessing(false);
    throw e;
  }
});
types.addOrganizationToSite = P.Object;

export default createEffects(namespace, services.get('channel').dispatch, types, effects);
