export const formatOrganizationDefaultVals = (organization) => {
  if (!organization) return {};

  return {
    name: organization.name,
    address: organization.address,
    postcode: organization.postcode,
    city: organization.city,
    businessId: organization.businessId,
    email: organization.email,
    phone: organization.phone,
    contact: organization.contact,
    industry: organization.industry,
  };
};
