import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Box,
  Input,
  Icon,
  Text,
  FormLabel,
  FormControl,
  List,
  ListItem,
  ListIcon,
  FormErrorMessage,
} from '@chakra-ui/react';
import {useDropzone} from 'react-dropzone';
import {useForm} from 'react-hook-form';
import {FiFolderPlus, FiCheckCircle} from 'react-icons/fi';

const DropzoneModal = ({isOpen, isLoading, onClose, onSubmit, defaultValues}) => {
  const {
    handleSubmit,
    register,
    formState: {errors},
  } = useForm({defaultValues});

  const {getRootProps, getInputProps, open, acceptedFiles} = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    // Disable multi file upload
    multiple: false,
  });

  const submit = (data) => {
    onSubmit({...data, files: acceptedFiles});
  };

  const files = acceptedFiles.map((file) => (
    <List paddingTop={8} spacing={3} key={file.path}>
      <ListItem display="flex" flexDirection="row" alignItems="center">
        <ListIcon as={FiCheckCircle} color="green.500" />
        {file.path}
      </ListItem>
    </List>
  ));

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg" closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Tiedosto</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form onSubmit={handleSubmit(submit)} id="file-uploader-form">
            <FormControl isInvalid={errors.name}>
              <FormLabel>Nimi</FormLabel>
              <Input
                placeholder="Lupa-asiakirja"
                {...register('name', {required: 'Pakollinen kenttä'})}
              />
              <FormErrorMessage>{errors.name && errors.name.message}</FormErrorMessage>
            </FormControl>
            <Box
              bg="#EEF0F1"
              borderRadius={6}
              mt={4}
              {...getRootProps({className: 'dropzone'})}
            >
              <Button
                height={200}
                width="100%"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                variant="solid"
                bg="#EEF0F1"
                type="button"
                onClick={open}
              >
                <Input {...getInputProps()} />
                <Icon as={FiFolderPlus} w={8} h={8} />
                <Text fontStyle="bold" color="black">
                  Lisää tiedosto
                </Text>
                <Text fontSize="xs" color="grey">
                  Pudota tiedosto tai valitse koneelta
                </Text>
              </Button>
            </Box>
            <aside>
              <ul>{files}</ul>
            </aside>
          </form>
        </ModalBody>

        <ModalFooter>
          <Button onClick={onClose} mr={3}>
            Peruuta
          </Button>
          <Button
            isLoading={isLoading}
            type="submit"
            form="file-uploader-form"
            variant="primary"
          >
            <span>Tallenna</span>
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DropzoneModal;
