import React from 'react';
import {
  Box,
  Text,
  HStack,
  Spacer,
  Button,
  Icon,
  FormControl,
  FormLabel,
  Input,
  IconButton,
  Badge,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Wrap,
  WrapItem,
  Stack,
  FormErrorMessage,
} from '@chakra-ui/react';
import {
  FiPlusCircle,
  FiMinusCircle,
  FiChevronDown,
  FiChevronLeft,
  FiChevronRight,
  FiChevronUp,
  FiEdit,
  FiSearch,
  FiFile,
  FiCalendar,
  FiMoreHorizontal,
  FiX,
} from 'react-icons/fi';
import {useForm} from 'react-hook-form';
import {
  Content,
  ReactDatepicker,
  ReactSelect,
  RadioButtonGroup,
  Paginator,
} from 'components';

const Styles = () => {
  const {
    handleSubmit,
    register,
    formState: {errors},
    control,
  } = useForm();

  const onSubmit = (form) => console.log(form);

  return (
    <Content>
      <Box>
        <Wrap spacing={6}>
          <WrapItem layerStyle="box" p={6} flexDir="column" flex={1}>
            <Text textStyle={`subheading`} mb={4} color="gray.600">
              Textstyles
            </Text>

            <Text textStyle={`h1`} mb={2}>
              Heading 1
            </Text>
            <Text textStyle={`h2`} mb={2}>
              Heading 2
            </Text>
            <Text textStyle={`h3`} mb={2}>
              Heading 3
            </Text>
            <Text textStyle={`subheading`} mb={2}>
              Subheading
            </Text>
            <Text textStyle={`body`} mb={2}>
              Body
            </Text>
            <Text textStyle={`small`} mb={2}>
              Small
            </Text>
            <Text textStyle={`caption`} mb={2}>
              Caption
            </Text>

            <Text textStyle={`label`} mb={2}>
              Label
            </Text>
          </WrapItem>

          <WrapItem layerStyle="box" p={6} flexDir="column" flex={1}>
            <Text textStyle={`subheading`} mb={4} color="gray.600">
              Buttons
            </Text>

            <Text textStyle={`caption`} mb={4} color="gray.600">
              Base
            </Text>

            <HStack spacing={3}>
              <Button>Button</Button>
              <Button leftIcon={<FiPlusCircle />}>w/icon</Button>
              <IconButton aria-label="Add" icon={<FiPlusCircle />} />
            </HStack>

            <Text textStyle={`caption`} my={4} color="gray.600">
              Primary
            </Text>

            <HStack spacing={3}>
              <Button variant="primary">Button</Button>
              <Button variant="primary" leftIcon={<FiPlusCircle />}>
                w/icon
              </Button>
              <IconButton variant="primary" aria-label="Add" icon={<FiPlusCircle />} />
            </HStack>

            <Text textStyle={`caption`} my={4} color="gray.600">
              Button sizes
            </Text>

            <HStack spacing={3}>
              <Button size="lg">Button lg</Button>
              <Button>Button md (base)</Button>
              <Button size="sm">Button sm</Button>
            </HStack>
          </WrapItem>

          <WrapItem layerStyle="box" p={6} flexDir="column" flex={1}>
            <Text textStyle={`subheading`} mb={4} color="gray.600">
              Badge
            </Text>

            <HStack spacing={2}>
              <Badge>Base</Badge>
              <Badge variant="warning">Warning</Badge>
              <Badge variant="danger">Danger</Badge>
              <Badge variant="success">Success</Badge>
              <Badge variant="muted">Muted</Badge>
            </HStack>
          </WrapItem>
        </Wrap>

        <HStack spacing={6} mt={12} alignItems="stretch">
          <Box layerStyle="box" p={6} flex={1}>
            <Text textStyle={`subheading`} mb={4} color="gray.600">
              Forms
            </Text>

            <form onSubmit={handleSubmit(onSubmit)} id="form">
              <Stack spacing={4}>
                <FormControl isInvalid={errors.email}>
                  <FormLabel>Käyttäjätunnus</FormLabel>
                  <Input
                    type="email"
                    {...register('email', {required: 'Pakollinen kenttä'})}
                  />
                  <FormErrorMessage>
                    {errors.email && errors.email.message}
                  </FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={errors.password}>
                  <FormLabel>Salasana</FormLabel>
                  <Input
                    type="password"
                    {...register('password', {required: 'Pakollinen kenttä'})}
                  />
                  <FormErrorMessage>
                    {errors.password && errors.password.message}
                  </FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={errors.date}>
                  <FormLabel>Ajankohta</FormLabel>
                  <ReactDatepicker
                    name="date"
                    control={control}
                    dateFormat="dd.MM.yyyy"
                    rules={{required: 'Pakollinen kenttä'}}
                  />
                  <FormErrorMessage>
                    {errors.date && errors.date.message}
                  </FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={errors.select}>
                  <FormLabel>React Select</FormLabel>
                  <ReactSelect
                    name="select"
                    isMulti
                    isClearable
                    control={control}
                    options={[
                      {value: 1, label: 'Vaihtoehto 1'},
                      {value: 2, label: 'Vaihtoehto 2'},
                      {value: 3, label: 'Vaihtoehto 3'},
                    ]}
                    rules={{required: 'Pakollinen kenttä'}}
                    hasError={!!errors.select}
                  />
                  <FormErrorMessage>
                    {errors.select && errors.select.message}
                  </FormErrorMessage>
                </FormControl>
                <FormControl>
                  <Text mb={2} textStyle={`label`}>
                    RadioButtonGroup
                  </Text>
                  <RadioButtonGroup
                    name="radio"
                    control={control}
                    defaultValue="choice1"
                    options={[
                      {
                        value: 'choice1',
                        label: 'Valinta 1',
                      },
                      {
                        value: 'choice2',
                        label: 'Valinta 2',
                      },
                      {
                        value: 'choice3',
                        label: 'Valinta 3',
                      },
                    ]}
                  />
                </FormControl>
                <Button type="submit" form="form" variant="primary">
                  <span>Lähetä</span>
                </Button>
              </Stack>
            </form>
          </Box>

          <Box layerStyle="box" p={6} flex={2}>
            <Text textStyle={`subheading`} mb={4} color="gray.600">
              UI Icons
            </Text>

            <HStack spacing={2} my={6}>
              <Icon as={FiPlusCircle} w={8} h={8} color="blue.500" />
              <Icon as={FiMinusCircle} w={8} h={8} color="blue.500" />
              <Icon as={FiChevronDown} w={8} h={8} color="blue.500" />
              <Icon as={FiChevronLeft} w={8} h={8} color="blue.500" />
              <Icon as={FiChevronRight} w={8} h={8} color="blue.500" />
              <Icon as={FiChevronUp} w={8} h={8} color="blue.500" />
              <Icon as={FiEdit} w={8} h={8} color="blue.500" />
              <Icon as={FiSearch} w={8} h={8} color="blue.500" />
              <Icon as={FiFile} w={8} h={8} color="blue.500" />
              <Icon as={FiCalendar} w={8} h={8} color="blue.500" />
              <Icon as={FiMoreHorizontal} w={8} h={8} color="blue.500" />
              <Icon as={FiX} w={8} h={8} color="blue.500" />
            </HStack>

            <Text textStyle={`label`}>Feather icons - More icons</Text>
            <Text textStyle={`caption`}>
              https://react-icons.github.io/react-icons/icons?name=fi
            </Text>
          </Box>
        </HStack>

        <HStack spacing={6} mt={12}>
          <Box layerStyle="box" w="full">
            <HStack px={6} py={8} verticalAlign="space-between">
              <Text textStyle={`h1`}>Table</Text>
              <Spacer />
              <Button leftIcon={<FiPlusCircle />}>Add new</Button>
            </HStack>

            <HStack bg="gray.50" p={4}>
              <FormControl id="text" w={1 / 4}>
                <FormLabel
                  pos="absolute"
                  top="2"
                  left="4"
                  textStyle={`label`}
                  color="gray.500"
                >
                  Large input
                </FormLabel>
                <Input type="text" placeholder="Placeholder text" />
              </FormControl>
              <FormControl id="text" w={1 / 4}>
                <FormLabel
                  pos="absolute"
                  top="2"
                  left="4"
                  textStyle={`label`}
                  color="gray.500"
                >
                  Large input
                </FormLabel>
                <Input type="text" placeholder="Placeholder text" />
              </FormControl>
            </HStack>

            <Table>
              <Thead>
                <Tr>
                  <Th>Name</Th>
                  <Th>Address</Th>
                  <Th>City</Th>
                  <Th>Status</Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td>
                    <strong>Kohteen nimi</strong>
                  </Td>
                  <Td>Kaukokatu 12 C 12</Td>
                  <Td>Helsinki</Td>
                  <Td>
                    <Badge>Base</Badge>
                  </Td>
                  <Td textAlign="right">
                    <IconButton
                      variant="ghost"
                      size="md"
                      aria-label="more"
                      icon={<FiMoreHorizontal />}
                    />
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <strong>Kohteen nimi</strong>
                  </Td>
                  <Td>Kaukokatu 12 C 12</Td>
                  <Td>Helsinki</Td>
                  <Td>
                    <Badge>Base</Badge>
                  </Td>
                  <Td textAlign="right">
                    <IconButton
                      variant="ghost"
                      size="md"
                      aria-label="more"
                      icon={<FiMoreHorizontal />}
                    />
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <strong>Kohteen nimi</strong>
                  </Td>
                  <Td>Kaukokatu 12 C 12</Td>
                  <Td>Helsinki</Td>
                  <Td>
                    <Badge>Base</Badge>
                  </Td>
                  <Td textAlign="right">
                    <IconButton
                      variant="ghost"
                      size="md"
                      aria-label="more"
                      icon={<FiMoreHorizontal />}
                    />
                  </Td>
                </Tr>
              </Tbody>
            </Table>
            <HStack p={4}>
              <Spacer />
              <Paginator
                totalPages={10}
                currentPage={1}
                perPage={15}
                onPageSelect={(pageNum) => console.log(pageNum)}
              />
            </HStack>
          </Box>
        </HStack>
      </Box>
    </Content>
  );
};

export default Styles;
