import {parseISO} from 'date-fns';
export const formatTimeEntryDefaultVals = (timeEntries) => {
  if (!timeEntries) {
    return {
      type: '',
      startDate: '',
      endDate: '',
      description: '',
      deleted: false,
    };
  }

  return {
    timeEntries:
      timeEntries?.map((entry) => ({
        id: entry.id,
        startDate: entry.startDate ? parseISO(entry.startDate) : '',
        endDate: entry.endDate ? parseISO(entry.endDate) : '',
        description: entry.description,
        type: entry.type,
        delete: false,
      })) || [],
  };
};
