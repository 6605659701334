import React from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  IconButton,
  Menu,
  Portal,
  MenuButton,
  MenuList,
  MenuItem,
  Text,
  Icon,
  Progress,
  Grid,
  MenuDivider,
} from '@chakra-ui/react';
import {sum} from 'ramda';
import {FiMoreHorizontal, FiChevronDown} from 'react-icons/fi';
import {formatNumber} from 'utils/numbers';

const CostCalculationRow = ({expense, onRowClick, onDelete}) => {
  const budgetUsed = expense.expenseItems
    ? sum(expense.expenseItems.map((item) => item.sum || 0))
    : 0;
  const totalBudget = expense.totalBudget;

  return (
    <Tr
      _hover={{bg: 'gray.50', cursor: 'pointer'}}
      onClick={() => onRowClick(expense.id)}
    >
      <Td textStyle="small">
        <strong>{expense.title}</strong>
      </Td>
      <Td></Td>
      <Td textStyle="small">
        <Grid
          gridAutoFlow="column"
          templateColumns="50px 200px 50px"
          alignItems="center"
          justifyContent="center"
          gridColumnGap={1}
        >
          <Text
            color={budgetUsed > totalBudget ? 'red.600' : 'blue.600'}
          >{`${formatNumber(budgetUsed)}€`}</Text>
          <Progress
            size="lg"
            value={budgetUsed}
            max={totalBudget}
            borderRadius="full"
            colorScheme={budgetUsed > totalBudget ? 'orange' : 'blue'}
          />
          <Text textAlign="right">{`${formatNumber(totalBudget)}€`}</Text>
        </Grid>
      </Td>
      <Td textAlign="right">
        <Menu>
          <MenuButton
            as={IconButton}
            variant="ghost"
            size="md"
            aria-label="more"
            icon={<FiMoreHorizontal />}
            onClick={(e) => e.stopPropagation()}
          />
          <Portal>
            <MenuList>
              <MenuItem
                onClick={(e) => {
                  e.stopPropagation();
                  onRowClick(expense.id);
                }}
              >
                <Text fontSize="sm" fontWeight="bold">
                  Muokkaa
                </Text>
              </MenuItem>
              <MenuDivider />
              <MenuItem
                onClick={(e) => {
                  e.stopPropagation();
                  onDelete(expense);
                }}
              >
                <Text fontSize="sm" fontWeight="bold" color="red.600">
                  Poista
                </Text>
              </MenuItem>
            </MenuList>
          </Portal>
        </Menu>
      </Td>
    </Tr>
  );
};

const CostCalculationTable = ({
  expenses,
  openExpense = () => {},
  removeExpense = () => {},
}) => {
  const totalBudget = sum(expenses.map((e) => e.totalBudget || 0));
  const totalBudgetUsed = sum(
    expenses.map((e) => sum(e.expenseItems.map((item) => item.sum || 0))),
  );

  return (
    <>
      <Table overflow="auto">
        <Thead>
          <Tr>
            <Th flex={1}>
              Kulun nimi
              <IconButton
                variant="ghost"
                colorScheme="blue"
                onClick={() => {}}
                size="sm"
                icon={<Icon as={FiChevronDown} />}
              />
            </Th>
            <Th></Th>
            <Th textAlign="center">Budjetti</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {expenses.map((e) => (
            <CostCalculationRow
              key={e.id}
              expense={e}
              onRowClick={openExpense}
              onDelete={removeExpense}
            />
          ))}
          <Tr>
            <Td />
            <Td textAlign="right" textStyle="small" fontWeight="bold" px={0}>
              Yhteensä
            </Td>
            <Td textStyle="small">
              <Grid
                gridAutoFlow="column"
                templateColumns="50px 200px 50px"
                alignItems="center"
                justifyContent="center"
                gridColumnGap={1}
              >
                <Text color="blue.600">{`${formatNumber(totalBudgetUsed)}€`}</Text>
                <Progress
                  size="lg"
                  value={totalBudgetUsed}
                  max={totalBudget}
                  borderRadius="full"
                />
                <Text textAlign="right">{`${formatNumber(totalBudget)}€`}</Text>
              </Grid>
            </Td>
            <Td />
          </Tr>
        </Tbody>
      </Table>
    </>
  );
};

export default CostCalculationTable;
