import React from 'react';
import {includes, all} from 'ramda';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  HStack,
  Spacer,
  IconButton,
  Badge,
  Menu,
  Portal,
  MenuButton,
  MenuList,
  MenuItem,
  Checkbox,
  Text,
  Link,
} from '@chakra-ui/react';
import {FiMoreHorizontal} from 'react-icons/fi';
import {Link as RouterLink} from 'react-router-dom';

import {encodeQuery} from 'utils/url';
import {emptyPagination} from 'constants/pagination';
import {Paginator} from 'components';
import {siteStatuses} from 'dicts/data';

const SiteRow = ({
  site = {},
  onRowClick = () => {},
  onDelete = () => {},
  isSelected,
  referrerUrl,
  select,
}) => {
  return (
    <Tr>
      <Td>
        <Checkbox
          isChecked={isSelected}
          onClick={(e) => e.stopPropagation()}
          onChange={(e) => select([{id: site.id, selected: e.target.checked}])}
        />
      </Td>
      <Td>
        <Link
          onClick={(e) => e.stopPropagation()}
          as={RouterLink}
          to={`/sites/${site.id}${encodeQuery({
            referrerUrl,
            referrerTitle: 'Työmaa',
          })}`}
        >
          <strong>{site.name}</strong>
        </Link>
      </Td>
      <Td>{site.address}</Td>
      <Td>{site.city}</Td>
      <Td>{site.postcode}</Td>
      <Td>
        <Badge>{siteStatuses[site.state]}</Badge>
      </Td>
      <Td textAlign="right">
        <Menu>
          <MenuButton
            as={IconButton}
            variant="ghost"
            size="md"
            aria-label="more"
            icon={<FiMoreHorizontal />}
            onClick={(e) => e.stopPropagation()}
          />
          <Portal>
            <MenuList>
              <MenuItem
                onClick={(e) => {
                  e.stopPropagation();
                  onDelete(site);
                }}
              >
                <Text fontSize="sm" fontWeight="bold" color="red.600">
                  Poista
                </Text>
              </MenuItem>
            </MenuList>
          </Portal>
        </Menu>
      </Td>
    </Tr>
  );
};

const SitesTable = ({
  processing,
  sites = [],
  selection,
  pagination = emptyPagination,
  referrerUrl,
  onPageSelect = () => {},
  openSite = () => {},
  removeSite = () => {},
  selectSites,
  clearSelection = () => {},
}) => {
  const handleSelectAll = (selected) => {
    if (selected) {
      selectSites(sites.map((s) => ({id: s.id, selected})));
    } else {
      clearSelection();
    }
  };
  return (
    <>
      <Table overflow="auto">
        <Thead>
          <Tr>
            <Th>
              <Checkbox
                isChecked={
                  !!sites.length && all((site) => includes(site.id, selection), sites)
                }
                onClick={(e) => e.stopPropagation()}
                onChange={(e) => handleSelectAll(e.target.checked)}
              />
            </Th>
            <Th>Nimi</Th>
            <Th>Osoite</Th>
            <Th>Kaupunki</Th>
            <Th>Postinumero</Th>
            <Th>Tila</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {sites.map((s) => (
            <SiteRow
              key={s.id}
              site={s}
              onRowClick={openSite}
              onDelete={removeSite}
              referrerUrl={referrerUrl}
              select={selectSites}
              isSelected={includes(s.id, selection)}
            />
          ))}
        </Tbody>
      </Table>
      <HStack p={4}>
        <Spacer />
        <Paginator
          totalPages={pagination.last_page}
          currentPage={pagination.current_page}
          perPage={pagination.per_page}
          onPageSelect={onPageSelect}
          disabled={processing}
        />
      </HStack>
    </>
  );
};

export default SitesTable;
