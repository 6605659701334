import namespace from './namespace';
import {createSelector} from 'reselect';
import {scopedSelectors} from 'utils/redux';
import {
  formatEmployeeDefaultVals,
  timeEntryQueryFetchable,
  formatWorkPermitDefaultVals,
} from './utils';

let sels = {};

sels.processing = (st) => st.processing;

sels.loading = (st) => st.loading;

sels.entriesLoading = (st) => st.entriesLoading;

sels.employee = (st) => st.employee;

sels.employeeId = (st) => st.employee.id;

sels.siteIdFilter = (st) => st.siteIdFilter;

sels.employeeModalOpen = (st) => st.employeeModalOpen;

sels.employeeDefaultVals = createSelector(sels.employee, (employee) =>
  formatEmployeeDefaultVals(employee),
);

sels.workPermits = (st) => st.workPermits;

sels.openedPermitId = (st) => st.openedPermitId;

sels.selectedWorkPermit = createSelector(
  [sels.openedPermitId, sels.workPermits],
  (permitId, workPermits) => workPermits.find((permit) => permit.id === permitId),
);

sels.permitDefaultValues = createSelector(
  [sels.selectedWorkPermit],
  (selectedWorkPermit) => formatWorkPermitDefaultVals(selectedWorkPermit),
);

sels.workPermitModalOpen = (st) => st.workPermitModalOpen;

sels.dateRange = (st) => st.dateRange;

sels.timeEntryQueryFetchable = createSelector(
  [sels.dateRange, sels.siteIdFilter, sels.employeeId],
  (dateRange, siteIdFilter, employeeId) =>
    timeEntryQueryFetchable({dateRange, siteIdFilter, employeeId}),
);

sels.sites = (st) => st.sites;

sels.siteEntries = (st) => st.siteEntries;

export default scopedSelectors(namespace, sels);
