import {prop} from 'ramda';
import services from 'services';
import {describeThrow} from 'utils/errors';
import msgs from 'dicts/messages';

let httpJson = null;
services.waitFor('api').then((x) => (httpJson = x.httpJson));

export const getSiteEntries = (query) =>
  httpJson('get', '/timeEntries', {...query, groupBySite: 'true'}, {})
    .catch(describeThrow(msgs.fetchFailed))
    .then(prop('data'));

export const getUserEntries = (query) =>
  httpJson('get', '/timeEntries', {...query, groupByUserAndSite: 'true'}, {})
    .catch(describeThrow(msgs.fetchFailed))
    .then(prop('data'));

export const generateCsv = (body) =>
  httpJson('post', '/timeEntries/generateTimeEntryCsv', {}, {body})
    .catch(describeThrow(msgs.fetchFailed))
    .then(prop('data'));
