import React, {useState} from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalHeader,
  ModalCloseButton,
  ModalFooter,
  Stack,
  Input,
  FormControl,
  FormLabel,
  Button,
  InputRightElement,
  IconButton,
  InputGroup,
  FormErrorMessage,
  HStack,
  Divider,
  Text,
} from '@chakra-ui/react';
import {useForm} from 'react-hook-form';
import {FiEye, FiEyeOff} from 'react-icons/fi';

const EmployeeModal = ({
  isOpen,
  isLoading,
  onClose = () => {},
  onSubmit = () => {},
  defaultValues = {},
  isNew = true,
}) => {
  const {
    handleSubmit,
    formState: {errors},
    register,
  } = useForm({defaultValues});

  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Työntekijä</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form onSubmit={handleSubmit(onSubmit)} id="editEmployee-form">
            <Stack spacing={4}>
              <HStack>
                <FormControl isInvalid={errors.name} isRequired>
                  <FormLabel>Nimi</FormLabel>
                  <Input
                    type="text"
                    {...register('name', {required: 'Pakollinen kenttä'})}
                  />
                  <FormErrorMessage>
                    {errors.name && errors.name.message}
                  </FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={errors.phone} isRequired>
                  <FormLabel>Puhelin</FormLabel>
                  <Input
                    type="text"
                    {...register('phone', {required: 'Pakollinen kenttä'})}
                  />
                  <FormErrorMessage>
                    {errors.phone && errors.phone.message}
                  </FormErrorMessage>
                </FormControl>
              </HStack>
              <FormControl isInvalid={errors.address} isRequired>
                <FormLabel>Osoite</FormLabel>
                <Input
                  type="text"
                  {...register('address', {required: 'Pakollinen kenttä'})}
                />
                <FormErrorMessage>
                  {errors.address && errors.address.message}
                </FormErrorMessage>
              </FormControl>
              <HStack spacing={3} align="flex-start">
                <FormControl isInvalid={errors.email} isRequired>
                  <FormLabel>Sähköposti</FormLabel>
                  <Input
                    type="text"
                    {...register('email', {required: 'Pakollinen kenttä'})}
                  />
                  <FormErrorMessage>
                    {errors.email && errors.email.message}
                  </FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={errors.ssn} isRequired>
                  <FormLabel>Sosiaaliturvatunnus</FormLabel>
                  <Input
                    type="text"
                    {...register('ssn', {required: 'Pakollinen kenttä'})}
                  />
                  <FormErrorMessage>{errors.ssn && errors.ssn.message}</FormErrorMessage>
                </FormControl>
              </HStack>
              <HStack spacing={3} align="flex-start">
                <FormControl isInvalid={errors.birthday} isRequired>
                  <FormLabel>Syntymäaika</FormLabel>
                  <Input
                    type="text"
                    placeholder="esim. 01.01.1990"
                    {...register('birthday', {
                      required: 'Pakollinen kenttä',
                      pattern: {
                        value:
                          /(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d/,
                        message: 'Tarkista syntymäajan muoto',
                      },
                    })}
                  />
                  <FormErrorMessage>
                    {errors.birthday && errors.birthday.message}
                  </FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={errors.vatNumber} isRequired>
                  <FormLabel>Veronumero</FormLabel>
                  <Input
                    type="number"
                    {...register('vatNumber', {
                      required: 'Pakollinen kenttä',
                      minLength: {
                        value: 12,
                        message: 'Veronumeron pituus tulee olla 12 numeroa pitkä.',
                      },
                    })}
                  />
                  <FormErrorMessage>
                    {errors.vatNumber && errors.vatNumber.message}
                  </FormErrorMessage>
                </FormControl>
              </HStack>
              <HStack spacing={3} align="flex-start">
                <FormControl isInvalid={errors.jobTitle}>
                  <FormLabel>Tehtävänimike</FormLabel>
                  <Input size="lg" {...register('jobTitle')}></Input>
                  <FormErrorMessage>
                    {errors.jobTitle && errors.jobTitle.message}
                  </FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={errors.baseWage}>
                  <FormLabel>Tuntipalkka</FormLabel>
                  <Input type="number" {...register('baseWage')} />
                  <FormErrorMessage>
                    {errors.baseWage && errors.baseWage.message}
                  </FormErrorMessage>
                </FormControl>
              </HStack>
              <Divider />
              <Text fontSize="sm">
                Huom! Jos työntekijälle ei haluta antaa pääsyä hallintapaneeliin, niin
                jätä salasana - kenttä tyhjäksi.
              </Text>
              <FormControl isInvalid={errors.password}>
                <FormLabel>Salasana</FormLabel>
                <InputGroup>
                  <Input
                    size="lg"
                    type={show ? 'text' : 'password'}
                    placeholder="Ei muutosta..."
                    {...register('password')}
                  />
                  <InputRightElement>
                    <IconButton
                      h="1.75rem"
                      size="sm"
                      onClick={handleClick}
                      icon={show ? <FiEyeOff /> : <FiEye />}
                    />
                  </InputRightElement>
                </InputGroup>
                <FormErrorMessage>
                  {errors.password && errors.password.message}
                </FormErrorMessage>
              </FormControl>
            </Stack>
          </form>
        </ModalBody>

        <ModalFooter>
          <Button onClick={onClose} mr={3}>
            Peruuta
          </Button>
          <Button
            type="submit"
            form="editEmployee-form"
            variant="primary"
            isLoading={isLoading}
          >
            <span>Tallenna</span>
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EmployeeModal;
