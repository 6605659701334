import {describeThrow} from 'utils/errors';
import {apiUrl} from 'constants/app';
import _sels from 'modules/common/boundSelectors';

let sels;
_sels.then((x) => (sels = x));

export const uploadFiles = (files) =>
  new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open('POST', `${apiUrl}/uploads`);
    xhr.setRequestHeader('Authorization', `Bearer ${sels.apiToken()}`);
    xhr.setRequestHeader('Accept', 'application/json');
    const data = new FormData();
    files.forEach((f) => data.append('file[]', f));
    xhr.send(data);
    xhr.addEventListener('load', () => {
      if (xhr.status < 400) {
        const response = JSON.parse(xhr.responseText);
        resolve(response);
      } else {
        reject(new Error('Upload failed'));
      }
    });
    xhr.addEventListener('error', () => {
      const error = JSON.parse(xhr.responseText);
      reject(error);
    });
  }).catch(describeThrow('Tiedoston lähetys epäonnistui'));
