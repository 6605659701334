import React from 'react';
import {
  Avatar,
  Box,
  Spacer,
  IconButton,
  Icon,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  Portal,
  Link,
  Flex,
} from '@chakra-ui/react';
import {FiMenu} from 'react-icons/fi';
import {NavLink as RouterNavLink, Link as RouterLink} from 'react-router-dom';
import {ReactComponent as HLogo} from 'assets/logo-horizontal.svg';

import shadows from 'theme/foundations/shadows';
import {headerHeight, headerHeightMobile} from 'constants/styles';
import commonEffs from 'modules/common/effects';

const navLinkStyles = {
  px: 4,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: '#fff',
  fontWeight: 'bold',
  position: 'relative',
  _hover: {color: '#fff'},
  _active: {color: '#fff'},
  _focus: {color: '#fff', boxShadow: shadows.outline},
};

const Logo = () => <HLogo style={{height: '50px'}} />;

const NavLink = ({to, exact = false, children}) => (
  <Link
    to={to}
    exact={exact}
    as={RouterNavLink}
    {...navLinkStyles}
    activeClassName="nav-link-active"
  >
    {children}
  </Link>
);

const Header = ({user, orgID, organizationId, openDrawer = () => {}}) => {
  const showOrganizationTab = orgID.includes(organizationId);
  return (
    <Flex
      as="header"
      bg="blue.600"
      height={{base: headerHeightMobile, md: headerHeight}}
      width="100%"
      position="sticky"
      boxShadow="md"
      top={0}
      left={0}
      right={0}
      zIndex={11}
    >
      <Link
        to="/"
        as={RouterLink}
        {...navLinkStyles}
        display={{base: 'none', md: 'flex'}}
        bgColor="blue.600"
        px={10}
        borderRightColor="blue.700"
        borderRightWidth="1px"
      >
        <Logo />
      </Link>

      <Box px={6} display={{base: 'none', md: 'flex'}}>
        <NavLink to="/sites">Työmaat</NavLink>
        <NavLink to="/employees">Työntekijät</NavLink>
        {showOrganizationTab ? (
          <NavLink to="/organizations">Organisaatiot</NavLink>
        ) : null}
        <NavLink to="/reportage">Raportointi</NavLink>
      </Box>

      <IconButton
        display={{md: 'none'}}
        onClick={openDrawer}
        icon={<Icon as={FiMenu} color="white" boxSize="6" />}
        size="lg"
        variant="unstyled"
        height={headerHeightMobile}
        paddingLeft={6}
        paddingRight={6}
      />

      <Spacer />

      <Menu>
        <MenuButton px={6} borderLeftColor="blue.700" borderLeftWidth="1px">
          <Flex direction="row" alignItems="center">
            <Avatar name={user.name} mr={4} size="md" />
            <Flex direction="column" alignItems="flex-start">
              <Text color="white" fontWeight="bold" whiteSpace="nowrap">
                {user.name}
              </Text>
              <Text color="white" textStyle="caption" whiteSpace="nowrap">
                Omat tiedot
              </Text>
            </Flex>
          </Flex>
        </MenuButton>
        <Portal>
          <MenuList>
            <MenuItem as={RouterLink} to={`/employees/${user.id}`}>
              <Text fontSize="sm" fontWeight="bold">
                Omat tiedot
              </Text>
            </MenuItem>
            <MenuDivider />
            <MenuItem as={RouterLink} to={`/organizations/${organizationId}`}>
              <Text fontSize="sm" fontWeight="bold">
                Oma yritys
              </Text>
            </MenuItem>
            <MenuDivider />
            <MenuItem onClick={() => commonEffs.logout()}>
              <Text fontSize="sm" fontWeight="bold">
                Kirjaudu ulos
              </Text>
            </MenuItem>
          </MenuList>
        </Portal>
      </Menu>
    </Flex>
  );
};

export default Header;
