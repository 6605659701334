import React from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  IconButton,
  Badge,
  Menu,
  Portal,
  MenuButton,
  MenuList,
  MenuItem,
  Text,
  Icon,
} from '@chakra-ui/react';
import {FiMoreHorizontal, FiChevronDown} from 'react-icons/fi';
import {activityStatuses} from 'dicts/data';

const SiteRow = ({site, onRowClick, onDelete}) => {
  const ActivityStatusBadge = (activityStatus) => {
    if (activityStatus === 'Poissa') {
      return <Badge variant="muted">Poissa</Badge>;
    } else if (activityStatus === 'Paikalla') {
      return <Badge variant="primary">Paikalla</Badge>;
    } else if (activityStatus === 'Tauko') {
      return <Badge variant="danger">Tauko</Badge>;
    } else if (activityStatus === 'Nouto') {
      return <Badge variant="warning">Nouto</Badge>;
    } else if (activityStatus === 'Siirtymä') {
      return <Badge variant="success">Siirtymä</Badge>;
    }
  };
  return (
    <Tr
      _hover={{bg: 'gray.50', cursor: 'pointer'}}
      onClick={() => {
        const id = site.id;
        onRowClick(id);
      }}
    >
      <Td textStyle="small">
        <strong>{site.name}</strong>
      </Td>
      <Td textStyle="small">{site.address}</Td>
      <Td textStyle="small">{!site.hourPrice ? '-' : `${site.hourPrice}€`}</Td>

      <Td>{ActivityStatusBadge(activityStatuses[site.activityStatus])}</Td>
      <Td textAlign="right">
        <Menu>
          <MenuButton
            as={IconButton}
            variant="ghost"
            size="md"
            aria-label="more"
            icon={<FiMoreHorizontal />}
            onClick={(e) => e.stopPropagation()}
          />
          <Portal>
            <MenuList>
              <MenuItem
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <Text fontSize="sm" fontWeight="bold" color="red.600">
                  Poista
                </Text>
              </MenuItem>
            </MenuList>
          </Portal>
        </Menu>
      </Td>
    </Tr>
  );
};

const SitesTable = ({sites, employee, openSite}) => {
  return (
    <>
      <Table overflow="auto">
        <Thead>
          <Tr>
            <Th>
              Nimi
              <IconButton
                variant="ghost"
                colorScheme="blue"
                onClick={() => {}}
                size="sm"
                icon={<Icon as={FiChevronDown} />}
              />
            </Th>
            <Th>
              Osoite
              <IconButton
                variant="ghost"
                colorScheme="blue"
                onClick={() => {}}
                size="sm"
                icon={<Icon as={FiChevronDown} />}
              />
            </Th>
            <Th>
              Tuntipalkka
              <IconButton
                colorScheme="blue"
                variant="ghost"
                onClick={() => {}}
                size="sm"
                icon={<Icon as={FiChevronDown} />}
              />
            </Th>
            <Th>
              Tila
              <IconButton
                variant="ghost"
                colorScheme="blue"
                onClick={() => {}}
                size="sm"
                icon={<Icon as={FiChevronDown} />}
              />
            </Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {sites.map((s) => (
            <SiteRow key={s.id} site={s} onRowClick={openSite} />
          ))}
        </Tbody>
      </Table>
    </>
  );
};

export default SitesTable;
