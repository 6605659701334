export default {
  variants: {
    withLabel: {
      field: {
        pt: 4,
        bgColor: 'white',
        color: 'gray.800',
        fontSize: 'sm',
        border: '1px solid',
        borderColor: 'gray.200',
        _hover: {
          borderColor: 'gray.500',
        },
        _focus: {
          borderColor: 'gray.600',
          bgColor: 'gray.50',
          boxShadow: 'md',
        },
        _invalid: {
          borderColor: 'red.500',
          boxShadow: '0 0 0 1px #ef4444',
        },
      },
    },
  },
};
