import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalHeader,
  ModalCloseButton,
  ModalFooter,
  Stack,
  Input,
  FormControl,
  FormLabel,
  Button,
  FormErrorMessage,
  IconButton,
  Icon,
  HStack,
} from '@chakra-ui/react';
import {useForm, useFieldArray} from 'react-hook-form';
import {FiPlusCircle, FiTrash} from 'react-icons/fi';
import {ModalSubHeader, ReactDatepicker} from 'components';

const ExpenseModal = ({
  isOpen,
  isLoading,
  onClose = () => {},
  onSubmit = () => {},
  defaultValues = {},
}) => {
  const {
    handleSubmit,
    formState: {errors},
    register,
    control,
  } = useForm({defaultValues});

  const {fields, append, remove} = useFieldArray({control, name: 'expenseItems'});

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Kustannus</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form onSubmit={handleSubmit(onSubmit)} id="expense-form">
            <Stack spacing={4}>
              <FormControl isInvalid={errors.title}>
                <FormLabel>Kustannus</FormLabel>
                <Input
                  type="text"
                  {...register('title', {required: 'Pakollinen kenttä'})}
                />
                <FormErrorMessage>
                  {errors.title && errors.title.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors.totalBudget}>
                <FormLabel>Kokonaisbudjetti (€)</FormLabel>
                <Input
                  type="number"
                  {...register('totalBudget', {required: 'Pakollinen kenttä'})}
                />
                <FormErrorMessage>
                  {errors.totalBudget && errors.totalBudget.message}
                </FormErrorMessage>
              </FormControl>
            </Stack>
            <ModalSubHeader
              title="Hankinnat"
              action={
                <IconButton
                  onClick={() => append({_id: '', date: '', sum: ''})}
                  size="sm"
                  icon={<Icon as={FiPlusCircle} />}
                />
              }
            />
            {fields.map((item, index) => (
              <HStack key={item.id} spacing={4} my={4}>
                <FormControl isInvalid={errors.expenseItems?.[index]?.date}>
                  <FormLabel>Päivämäärä</FormLabel>
                  <ReactDatepicker
                    name={`expenseItems.${index}.date`}
                    control={control}
                    rules={{required: 'Pakollinen kenttä'}}
                    defaultValue={item.date}
                  />
                </FormControl>
                <FormControl isInvalid={errors.description}>
                  <FormLabel>Kuvaus</FormLabel>
                  <Input
                    type="text"
                    {...register(`expenseItems.${index}.description`)}
                    defaultValue={item.description}
                  />
                  <FormErrorMessage>
                    {errors.description && errors.description.message}
                  </FormErrorMessage>
                </FormControl>
                <FormControl
                  isInvalid={errors.expenseItems?.[index]?.sum}
                  style={{width: '50%'}}
                >
                  <FormLabel>Summa (€)</FormLabel>
                  <Input
                    type="number"
                    {...register(`expenseItems.${index}.sum`, {
                      required: 'Pakollinen kenttä',
                    })}
                    defaultValue={item.sum}
                  />
                </FormControl>
                <input
                  {...register(`expenseItems.${index}._id`)}
                  defaultValue={item._id}
                  hidden
                />
                <IconButton
                  icon={<Icon as={FiTrash} color="gray.600" />}
                  variant="ghost"
                  onClick={() => remove(index)}
                />
              </HStack>
            ))}
          </form>
        </ModalBody>

        <ModalFooter>
          <Button onClick={onClose} mr={3}>
            Peruuta
          </Button>
          <Button
            type="submit"
            form="expense-form"
            variant="primary"
            isLoading={isLoading}
          >
            <span>Tallenna</span>
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ExpenseModal;
