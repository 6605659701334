const Table = {
  parts: ['table', 'thead', 'tbody', 'tr', 'th', 'td', 'caption'],
  baseStyle: {
    th: {
      textTransform: 'normal',
    },
  },
  variants: {
    simple: {
      th: {
        borderColor: 'gray.100',
      },
      td: {
        borderColor: 'gray.100',
      },
    },
  },
  defaultProps: {
    variants: 'simple',
  },
};

export default Table;
