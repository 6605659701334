import namespace from './namespace';
import {createEffects} from 'utils/events';
import services from 'services';
import _acts from './boundActions';
//import _sels from './boundSelectors';
import {P} from 'utils/types';
import {guardHandled} from 'io/errors';
import {decorateWithNotificationsEff} from 'io/app';
import {putTimeEntries} from './io';

let acts;
_acts.then((x) => (acts = x));
//let sels;
//_sels.then((x) => (sels = x));

let effects = {};
let types = {};

// note: lazy, should store in state or something like that
let afterSaveCallback = null;

effects.initialize = async () => {};

effects.openTimeEntryModal = (afterSave = () => {}) => {
  afterSaveCallback = afterSave;
  acts.openTimeEntriesModal();
};
types.openTimeEntryModal = P.Function;

effects.closeTimeEntryModal = () => {
  acts.closeTimeEntriesModal();
};

effects.setEditableEntries = (entries) => {
  acts.setEditableEntries(entries);
};
types.setEditableEntries = P.Array;

effects.updateTimeEntries = guardHandled(async ({data, siteId}) => {
  try {
    acts.setProcessing(true);
    await decorateWithNotificationsEff(
      {
        id: 'timeEntry-form',
        failureStyle: 'error',
        success: 'Tallennettu',
      },
      putTimeEntries(data),
    );
    acts.closeTimeEntriesModal();

    afterSaveCallback();

    acts.setProcessing(false);
  } catch (e) {
    acts.setProcessing(false);
    throw e;
  }
});
types.updateTimeEntries = P.Object;

effects.destroy = async () => {
  acts.reset();
};

export default createEffects(namespace, services.get('channel').dispatch, types, effects);
