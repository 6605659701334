import React from 'react';
import {Table, Thead, Tbody, Tr, Th, Td} from '@chakra-ui/react';

const SiteRow = ({selectedSite}) => {
  return (
    <Tr>
      <Td>
        <strong>{selectedSite.name}</strong>
      </Td>
      <Td>{selectedSite.address}</Td>
      <Td>{selectedSite.postcode}</Td>
      <Td>{selectedSite.city}</Td>
    </Tr>
  );
};

const ContractNoticeTable = ({sites}) => {
  return (
    <>
      <Table overflow="auto" textStyle="small">
        <Thead>
          <Tr>
            <Th>Nimi</Th>
            <Th>Osoite</Th>
            <Th>Postinumero</Th>
            <Th>Kaupunki</Th>
          </Tr>
        </Thead>
        <Tbody>
          {sites.map((s) => (
            <SiteRow key={s.id} selectedSite={s} />
          ))}
        </Tbody>
      </Table>
    </>
  );
};

export default ContractNoticeTable;
