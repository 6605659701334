import namespace from './namespace';
import {scopedSelectors} from 'utils/redux';
import {createSelector} from 'reselect';
import {formatSiteQueryFetchable, formatUserQueryFetchable} from './utils';

let sels = {};

sels.initialized = (st) => st.initialized;

sels.siteEntries = (st) => st.siteEntries;

sels.userEntries = (st) => st.userEntries;

sels.loading = (st) => st.loading;

sels.processing = (st) => st.processing;

sels.userQuery = (st) => st.userQuery;

sels.generateReportageModalOpen = (st) => st.generateReportageModalOpen;

sels.includeBreaksAndPickups = (st) => st.includeBreaksAndPickups;

sels.siteQuery = (st) => st.siteQuery;

sels.dateRange = (st) => st.dateRange;

sels.referrerUrlBoolean = (st) => st.referrerUrlBoolean;

sels.siteQueryFetchable = createSelector(
  [sels.siteQuery, sels.dateRange],
  (siteQuery, dateRange) => formatSiteQueryFetchable({siteQuery, dateRange}),
);

sels.userQueryFetchable = createSelector(
  [sels.userQuery, sels.dateRange],
  (userQuery, dateRange) => formatUserQueryFetchable({userQuery, dateRange}),
);

sels.siteQueryUrlFormatted = createSelector(sels.siteQuery, (siteQuery) => {
  return JSON.stringify(siteQuery);
});
sels.userQueryUrlFormatted = createSelector(sels.userQuery, (userQuery) => {
  return JSON.stringify(userQuery);
});
sels.dateRangeQueryUrlFormatted = createSelector(sels.dateRange, (dateRange) => {
  return JSON.stringify(dateRange);
});

sels.reportageIndex = (st) => st.reportageIndex;

sels.userEntries = (st) => st.userEntries;

sels.siteEntries = (st) => st.siteEntries;

sels.userReportage = (st) => st.userReportage;

sels.userReportageLoading = (st) => st.userReportageLoading;

sels.siteReportage = (st) => st.siteReportage;

sels.siteReportageLoading = (st) => st.siteReportageLoading;

export default scopedSelectors(namespace, sels);
