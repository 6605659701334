import React from 'react';
import {Box} from '@chakra-ui/react';

const Content = ({children, ...props}) => {
  return (
    <Box paddingX={{md: 8, xl: 16}} paddingY={{base: 5, md: 8}} {...props}>
      {children}
    </Box>
  );
};

export default Content;
