import {map} from 'ramda';
import namespace from './namespace';
import {createEffects} from 'utils/events';
import services from 'services';
import _acts from './boundActions';
import {P, Record, Maybe} from 'utils/types';
import cache from './cache';

const ShowArgs = Record(
  map((t) => Maybe(t), {
    onCancel: P.Function,
    onOk: P.Function,
    title: P.String,
    message: P.String,
    cancelText: P.String,
    okText: P.String,
    okStyle: P.String,
    okDisabled: P.Boolean,
    dangerText: P.String,
  }),
);

let acts;
_acts.then((x) => (acts = x));

let effects = {};
let types = {};

const clearCallbacks = () => {
  cache.update((c) => ({...c, onCancel: null, onOk: null}));
};

effects.show = async ({onCancel = null, onOk = null, ...conf}) => {
  cache.update((c) => ({...c, onCancel, onOk}));
  acts.show(conf);
};
types.show = ShowArgs;

effects.ok = async () => {
  acts.reset();
  cache.read().onOk();
  clearCallbacks();
};

effects.cancel = async () => {
  acts.reset();
  cache.read().onCancel();
  clearCallbacks();
};

export default createEffects(namespace, services.get('channel').dispatch, types, effects);
