import React from 'react';
import {HStack, FormControl, FormLabel} from '@chakra-ui/react';
import {ReactDatepicker} from 'components';

const ContractNoticeFilters = ({updateQuery = () => {}, query}) => {
  return (
    <HStack>
      <FormControl>
        <FormLabel>Alkaen</FormLabel>
        <ReactDatepicker
          name="startDate"
          standalone
          selected={query.startDate}
          onChange={(date) => updateQuery({startDate: date})}
        />
      </FormControl>
      <FormControl>
        <FormLabel>Päättyen</FormLabel>
        <ReactDatepicker
          name="endDate"
          standalone
          selected={query.endDate}
          onChange={(date) => updateQuery({endDate: date})}
        />
      </FormControl>
    </HStack>
  );
};

export default ContractNoticeFilters;
