import namespace from './namespace';
import {scopedStore} from 'utils/redux';

export const initialState = {
  timeEntriesModalOpen: false,
  editableEntries: [],
  processing: false,
};

export const cases = {
  closeTimeEntriesModal: (st, all) => {
    return {...st, timeEntriesModalOpen: false};
  },

  openTimeEntriesModal: (st, all) => {
    return {...st, timeEntriesModalOpen: true};
  },

  setEditableEntries: (st, all, entries) => {
    return {...st, editableEntries: entries};
  },

  setProcessing: (st, all, value) => {
    return {...st, processing: value};
  },

  reset: (st) => {
    return initialState;
  },
};

export const {actions, reducer} = scopedStore(namespace, cases, initialState);
