import React, {useState} from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalHeader,
  ModalCloseButton,
  ModalFooter,
  Stack,
  Heading,
  Input,
  FormControl,
  FormLabel,
  Button,
  FormErrorMessage,
  HStack,
  Divider,
  Badge,
  Box,
  Text,
  Avatar,
  IconButton,
  InputRightElement,
  InputGroup,
  OrderedList,
  ListItem,
  Switch,
  Link,
} from '@chakra-ui/react';
import {useForm} from 'react-hook-form';
import {ReactDatepicker, ReactSelect} from 'components';
import {FiX, FiHelpCircle} from 'react-icons/fi';

const SiteModal = ({
  isOpen,
  isLoading,
  onClose = () => {},
  onSubmit = () => {},
  defaultValues = {},
  organizations,
  organizationId,
  addableOrganizations,
  defaultSiteSubContractors,
  isNew = true,
}) => {
  const {
    handleSubmit,
    formState: {errors},
    control,
    watch,
    register,
  } = useForm({defaultValues});

  const [subContractors, setSubContractors] = useState(defaultSiteSubContractors);
  const [showHelp, setShowHelp] = useState(false);
  const [checked, setChecked] = useState(defaultValues.checkLocation);

  const [newSubContractorId, setNewSubContractorId] = useState(null);

  const siteStates = [
    {name: 'active', label: 'Aktiivinen'},
    {name: 'inactive', label: 'Poissa'},
  ];

  const addSubContractor = () => {
    const organization = organizations.find((x) => x.id === newSubContractorId);
    setSubContractors([...subContractors, organization]);
  };

  const removeSubContractor = (siteId) => {
    const newOrganizations = subContractors.filter((x) => x.id !== siteId);
    setSubContractors(newOrganizations);
  };

  const onSubmitActual = (formData) =>
    onSubmit({...formData, checkLocation: checked, subContractors});
  const startDate = watch('startDate');

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{isNew ? 'Lisää työmaa' : 'Muokkaa työmaata'}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form onSubmit={handleSubmit(onSubmitActual)} id="site-form">
            <Stack spacing={4}>
              <FormControl isInvalid={errors.name}>
                <FormLabel>Työmaan nimi</FormLabel>
                <Input
                  type="text"
                  {...register('name', {required: 'Pakollinen kenttä'})}
                />
                <FormErrorMessage>{errors.name && errors.name.message}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.address}>
                <FormLabel>Osoite</FormLabel>
                <Input
                  type="text"
                  {...register('address', {required: 'Pakollinen kenttä'})}
                />
                <FormErrorMessage>
                  {errors.address && errors.address.message}
                </FormErrorMessage>
              </FormControl>

              <HStack spacing={2} align="flex-start">
                <FormControl isInvalid={errors.postcode}>
                  <FormLabel>Postinumero</FormLabel>
                  <Input
                    type="text"
                    {...register('postcode', {required: 'Pakollinen kenttä'})}
                  />
                  <FormErrorMessage>
                    {errors.postcode && errors.postcode.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={errors.city}>
                  <FormLabel>Kaupunki</FormLabel>
                  <Input
                    type="text"
                    {...register('city', {required: 'Pakollinen kenttä'})}
                  />
                  <FormErrorMessage>
                    {errors.city && errors.city.message}
                  </FormErrorMessage>
                </FormControl>
              </HStack>
              <Divider />
              <HStack>
                <FormControl display="flex" justifyContent="space-between">
                  <Text fontSize="sm" fontWeight={'bold'}>
                    Vaadi työntekijän GPS - sijaintitieto:{' '}
                  </Text>
                  <Switch
                    isChecked={checked}
                    defaultChecked={checked}
                    onChange={() => {
                      setChecked(!checked);
                    }}
                  />
                </FormControl>
              </HStack>
              {checked ? (
                <HStack>
                  <FormControl isInvalid={errors.location}>
                    <FormLabel>Koordinaatit</FormLabel>
                    <InputGroup>
                      <Input
                        type="text"
                        {...register('location')}
                        placeholder="Työmaan koordinaatit"
                      />
                      <InputRightElement>
                        <IconButton
                          size="sm"
                          icon={<FiHelpCircle />}
                          onClick={() => {
                            setShowHelp(!showHelp);
                          }}
                        />
                      </InputRightElement>
                    </InputGroup>
                    <FormErrorMessage>
                      {errors.message && errors.location.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={errors.allowedRadius}>
                    <FormLabel>Sijainnin säde (km)</FormLabel>
                    <Input type="number" step="0.01" {...register('allowedRadius')} />
                    <FormErrorMessage>
                      {errors.allowedRadius && errors.allowedRadius.message}
                    </FormErrorMessage>
                  </FormControl>
                </HStack>
              ) : null}
              {showHelp ? (
                <Stack>
                  <Text fontSize="md" textStyle="bold">
                    Koordinaattien selvittäminen:
                  </Text>
                  <OrderedList paddingX={4} fontSize="sm">
                    <ListItem>
                      Mene tietokoneella osoitteeseen{' '}
                      <Link
                        color="teal.500"
                        href="https://www.google.fi/maps"
                        target="_blank"
                      >
                        google.fi/maps
                      </Link>
                    </ListItem>
                    <ListItem>
                      Etsi työmaan sijainti kartalta, ja klikkaa sen keskipisteestä hiiren
                      oikealla painikkeella
                    </ListItem>
                    <ListItem>
                      Kontekstivalikko aukeaa, ja voit kopioida sieltä valitun pisteen
                      koordinaatit
                    </ListItem>
                    <ListItem>Liitä koordinaatit sellaisenaan tähän</ListItem>
                  </OrderedList>
                </Stack>
              ) : null}
              <Divider />
              <HStack spacing={2} align="flex-start">
                <FormControl isInvalid={errors.startDate}>
                  <FormLabel>Työmaa alkaa</FormLabel>
                  <ReactDatepicker name="startDate" control={control} />
                  <FormErrorMessage>
                    {errors.startDate && errors.startDate.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={errors.endDate}>
                  <FormLabel>Työmaa päättyy</FormLabel>
                  <ReactDatepicker
                    name="endDate"
                    control={control}
                    rules={{
                      validate: (val) =>
                        val < startDate ? 'Oltava aloituspäivän jälkeen' : null,
                    }}
                  />
                  <FormErrorMessage>
                    {errors.endDate && errors.endDate.message}
                  </FormErrorMessage>
                </FormControl>
              </HStack>
              <HStack>
                <FormControl isInvalid={errors.budget}>
                  <FormLabel>Kokonaisbudjetti (€)</FormLabel>
                  <Input
                    type="text"
                    {...register('budget', {required: 'Pakollinen kenttä'})}
                  />
                  <FormErrorMessage>
                    {errors.budget && errors.budget.message}
                  </FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={errors.employeeCosts}>
                  <FormLabel>Työntekijäkulut (€)</FormLabel>
                  <Input
                    type="text"
                    {...register('employeeCosts', {required: 'Pakollinen kenttä'})}
                  />
                  <FormErrorMessage>
                    {errors.employeeCosts && errors.employeeCosts.message}
                  </FormErrorMessage>
                </FormControl>
              </HStack>
              <FormControl isInvalid={errors.state}>
                <FormLabel>Tila</FormLabel>
                <ReactSelect
                  name="state"
                  control={control}
                  rules={{
                    // provide explicit validation function, "required" didn't seem to work with react-select
                    validate: (val) => (val == null ? 'Pakollinen kenttä' : null),
                  }}
                  options={siteStates.map((s) => ({value: s.name, label: s.label}))}
                />
                <FormErrorMessage>
                  {errors.state && errors.state.message}
                </FormErrorMessage>
              </FormControl>
              <Divider />
            </Stack>
          </form>
          {!isNew ? (
            <>
              <Heading as="h2" size="sm" py={4}>
                Oikeudet
              </Heading>
              <Divider />
              <Stack py={4}>
                <HStack>
                  <FormControl isInvalid={errors.state}>
                    <FormLabel>Yritys</FormLabel>
                    <ReactSelect
                      name="subcontractor"
                      options={addableOrganizations.map((o) => ({
                        value: o.id,
                        label: `${o.name}, Y-tunnus ${o.businessId}`,
                      }))}
                      onChange={(val) => setNewSubContractorId(val)}
                    />
                    <FormErrorMessage>
                      {errors.state && errors.state.message}
                    </FormErrorMessage>
                  </FormControl>
                  <Button
                    variant="primary"
                    isLoading={isLoading}
                    disabled={!newSubContractorId}
                    onClick={() => addSubContractor()}
                  >
                    Lisää
                  </Button>
                </HStack>
                <Stack py={4}>
                  {subContractors.map((subContractor) => (
                    <Stack key={subContractor.id}>
                      <Stack flexDirection="row" justifyContent="space-between">
                        <HStack>
                          <Avatar size="sm" name={subContractor.contact} />
                          <Box>
                            <Text textStyle={'h3'}>{subContractor.name}</Text>
                            <Text textStyle={'small'}>{subContractor.email}</Text>
                          </Box>
                        </HStack>
                        <Box>
                          <Badge>Alihankkija</Badge>
                          <IconButton
                            variant="ghost"
                            size="sm"
                            icon={<FiX />}
                            marginLeft={2}
                            onClick={(e) => {
                              e.stopPropagation();
                              removeSubContractor(subContractor.id);
                            }}
                          />
                        </Box>
                      </Stack>
                      <Divider />
                    </Stack>
                  ))}
                </Stack>
              </Stack>
            </>
          ) : null}
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose} mr={3}>
            Peruuta
          </Button>
          <Button type="submit" form="site-form" variant="primary" isLoading={isLoading}>
            <span>Tallenna</span>
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default SiteModal;
