import React from 'react';
import {Box, Text, Progress} from '@chakra-ui/react';

const EmployeeCosts = ({site, users}) => {
  const sumTotalEuros = (users) =>
    users.reduce((sum, {siteTotalWages}) => sum + siteTotalWages, 0);

  const employeeCosts = site.employeeCosts;
  const accumulatedWages = Math.floor(sumTotalEuros(users));
  const percentage = (accumulatedWages / employeeCosts) * 100;

  return (
    <Box width="60%" display="flex" paddingLeft={16}>
      <Text>{accumulatedWages}€</Text>
      <Progress
        flex={3}
        value={percentage}
        height="28px"
        borderRadius="full"
        marginLeft={2}
        marginRight={2}
        colorScheme={accumulatedWages > employeeCosts ? 'orange' : 'blue'}
      />
      <Text>{employeeCosts}€</Text>
    </Box>
  );
};

export default EmployeeCosts;
