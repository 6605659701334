import namespace from './namespace';
import {scopedStore} from 'utils/redux';

export const initialState = {
  initialized: false,
  apiToken: null,
  user: null,
  organization: null,
  organizations: [],
  organizationSites: [],
  pagination: null,
  loading: false,
};

export const cases = {
  // the app is marked initialized as soon as the possible apiToken has been read and we know whether to show a login screen or not
  setApiToken: (st, all, apiToken) => {
    return {...st, apiToken, initialized: true};
  },

  setUser: (st, all, user) => {
    return {
      ...st,
      user,
      organization: user.organization,
      organizationSites: user.organization.sites,
    };
  },

  clearLogin: (st) => {
    return {...st, apiToken: null, user: null};
  },

  setOrganizations: (st, all, payload) => {
    return {
      ...st,
      organizations: payload.data,
      pagination: payload.meta,
      loading: false,
    };
  },
};

export const {actions, reducer} = scopedStore(namespace, cases, initialState);
