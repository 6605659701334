import React, {useEffect} from 'react';
import {pick} from 'ramda';
import {connect} from 'react-redux';
//import {Link as RouterLink} from 'react-router-dom';
import {Flex, Divider, Box, Button, Stack, Text, useMediaQuery} from '@chakra-ui/react';

import {applyState} from 'utils/redux';
import effs from 'modules/login/effects';
import sels from 'modules/login/selectors';

import LoginForm from './components/login-form';
import bg from 'assets/login-bg.jpg';
import {ReactComponent as VLogo} from 'assets/Logo-vertical.svg';

const Login = ({loading}) => {
  useEffect(() => {
    effs.initialize();
  }, []);

  const [isMd] = useMediaQuery('(min-width: 48em)');

  return (
    <Flex
      flex="1"
      bgColor="blue.500"
      bgImage={`url(${bg})`}
      bgRepeat="no-repeat"
      bgSize="cover"
      alignItems="center"
      justifyContent="center"
      h="100vh"
      w="100vw"
    >
      <Box pr={28}>
        <VLogo style={{height: '300px'}} />
      </Box>
      <Box layerStyle="box" w="400px">
        <Box my={5} mx={{base: 6, md: 8}}>
          <Text textStyle="h2">Kirjaudu sisään</Text>
        </Box>
        <Divider />
        <Box my={5} mx={{base: 6, md: 8}}>
          <LoginForm onSubmit={effs.login} id="login-form" />
        </Box>
        <Stack
          my={5}
          mx={{base: 6, md: 8}}
          spacing={{base: 6, md: 0}}
          isInline={isMd}
          justify={{base: 'center', md: 'space-between'}}
          align="stretch"
        >
          <Button
            type="submit"
            form="login-form"
            variant="primary"
            isLoading={loading}
            order={{base: 1, md: 2}}
          >
            <span>Kirjaudu sisään</span>
          </Button>
          {/*<Button
            as={RouterLink}
            to="/forgot-password"
            variant="primary-link"
            size="sm"
            order={{base: 2, md: 1}}
          >
            <span>Unohtuiko salasana?</span>
          </Button>*/}
        </Stack>
      </Box>
    </Flex>
  );
};

export default connect(applyState(pick(['loading'], sels)))(Login);
