import namespace from './namespace';
import {mergeLeft} from 'ramda';
import {createEffects} from 'utils/events';
import services from 'services';

import {parseUrlQuery} from './utils';
import {getQuery, pushQuery} from 'io/history';
import msgs from 'dicts/messages';
import _acts from './boundActions';
import {setPageTitleMessage, decorateWithNotificationsEff} from 'io/app';
import {getOrganizations, postOrganization, deleteOrganization} from './io';
import _sels from './boundSelectors';
import {P} from 'utils/types';
import confirmerEffs from 'modules/confirmer/effects';
import {guardHandled} from 'io/errors';

let acts, sels;
_acts.then((x) => (acts = x));
_sels.then((x) => (sels = x));

let effects = {};
let types = {};

const fetchOrganizations = (notifyOpts = {}) => {
  acts.setLoading(true);

  return decorateWithNotificationsEff(
    {id: 'get-organizations', failureStyle: 'warning', ...notifyOpts},
    getOrganizations(sels.queryFetchable()),
  ).then(({data, meta}) => {
    acts.setOrganizations({data, pagination: meta});
  });
};

effects.initialize = async () => {
  setPageTitleMessage('Organisaatiot');

  const query = parseUrlQuery(getQuery());
  acts.updateQuery(query);
  await fetchOrganizations();
};

effects.updateQuery = guardHandled(async (query) => {
  acts.updateQuery(query);
  pushQuery(mergeLeft(sels.query()));

  await fetchOrganizations();
});
types.updateQuery = P.Object;

effects.createOrganization = guardHandled(async (formData) => {
  try {
    acts.setProcessing(true);
    await decorateWithNotificationsEff(
      {
        id: 'create-organization',
        failureStyle: 'error',
        success: 'Tallennettu',
      },
      postOrganization(formData),
    );
    acts.closeOrganizationModal();
    acts.setProcessing(false);
  } catch (e) {
    acts.setProcessing(false);
    throw e;
  }

  await fetchOrganizations();
});
types.createOrganization = P.Object;

effects.removeOrganization = (organization) => {
  const onConfirm = guardHandled(async () => {
    try {
      acts.setProcessing(true);
      await decorateWithNotificationsEff(
        {
          id: 'remove-organization',
          failureStyle: 'error',
          loading: msgs.deleting,
          success: 'Organisaatio poistettu',
        },
        deleteOrganization(organization.id),
      );
      acts.setProcessing(false);
    } catch (e) {
      acts.setProcessing(false);
      throw e;
    }

    await fetchOrganizations();
  });

  confirmerEffs.show({
    message: `Poistetaanko organisaatio ${organization.name}?`,
    okText: 'Poista',
    okStyle: 'danger',
    cancelText: msgs.cancel,
    onCancel: () => {},
    onOk: onConfirm,
  });
};
types.removeOrganization = P.Object;

effects.openOrganizationModal = () => {
  acts.openOrganizationModal();
};

effects.closeOrganizationModal = () => {
  acts.closeOrganizationModal();
};

effects.destroy = async () => {
  acts.reset();
};

export default createEffects(namespace, services.get('channel').dispatch, types, effects);
