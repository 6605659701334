import services from 'services';
import {describeThrow} from 'utils/errors';

let httpJson = null;
services.waitFor('api').then((x) => (httpJson = x.httpJson));

export const sendResetLink = (body) =>
  httpJson('post', '/reset-password', {}, {body}).catch(
    describeThrow('Salasanan palauttaminen epäonnistui'),
  );

export const postResetPassword = ({token, ...data}) =>
  httpJson('post', `/reset-password/${token}`, {}, {body: data}).catch(
    describeThrow('Salasanan asettaminen epäonnistui'),
  );
