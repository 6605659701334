import React from 'react';
import {Box} from '@chakra-ui/react';

// Wrap Chakra's TabList around TabsHorizontalScroll to enable horizontal scroll for long tabs
class TabsHorizontalScroll extends React.Component {
  state = {
    scrollable: false,
    leftBlur: false,
    rightBlur: false,
  };

  scrollableRef = React.createRef();
  scrollableParentRef = React.createRef();

  handleResize = () => {
    const diff =
      this.scrollableRef.current.scrollWidth -
      this.scrollableParentRef.current.scrollWidth;

    if (
      this.scrollableRef.current.scrollWidth >
      this.scrollableParentRef.current.scrollWidth
    ) {
      this.setState({scrollable: true});

      if (this.scrollableRef.current.scrollLeft !== diff) {
        this.setState({rightBlur: true});
      }
    } else this.setState({scrollable: false});
  };

  handleScroll = (e) => {
    const diff = e.target.scrollWidth - this.scrollableParentRef.current.scrollWidth;

    // diff - 1 due android devices not hiding the rightblur correctly
    if (e.target.scrollLeft >= diff - 1) {
      this.setState({rightBlur: false});
    } else this.setState({rightBlur: true});

    if (e.target.scrollLeft === 0) {
      this.setState({leftBlur: false});
    } else this.setState({leftBlur: true});
  };

  componentDidMount() {
    if (
      this.scrollableRef.current.scrollWidth >
      this.scrollableParentRef.current.scrollWidth
    ) {
      this.setState({scrollable: true, rightBlur: true});
    }
    window.addEventListener('resize', this.handleResize);
    this.scrollableRef.current.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    this.scrollableRef.current.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.handleResize);
  }

  render() {
    const {scrollable, leftBlur, rightBlur} = this.state;

    return (
      <Box position="relative" ref={this.scrollableParentRef}>
        {scrollable && leftBlur && (
          <Box
            as="span"
            position="absolute"
            top="0"
            bottom="0"
            left="0"
            width="4rem"
            pointerEvents="none"
            background="linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.55) 50%, rgba(255, 255, 255, 0) 90%)"
          />
        )}
        <Box
          ref={this.scrollableRef}
          overflowX="auto"
          overflowY="hidden"
          paddingBottom={{base: 3, md: 5}}
          marginBottom={{base: -3, md: -5}}
          sx={{
            '::-webkit-scrollbar': {
              width: 0,
              height: 0,
            },
            scrollbarWidth: 'none',
            msOverflowStyle: 'none',
          }}
        >
          {this.props.children}
        </Box>
        {scrollable && rightBlur && (
          <Box
            as="span"
            position="absolute"
            top="0"
            bottom="0"
            right="0"
            width="4rem"
            pointerEvents="none"
            background="linear-gradient(to left, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.55) 50%, rgba(255, 255, 255, 0) 90%)"
          />
        )}
      </Box>
    );
  }
}

export default TabsHorizontalScroll;
