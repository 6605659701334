export const formatEmployeeDefaultVals = (employee) => {
  if (!employee) return {};

  return {
    name: employee.name,
    email: employee.email,
    address: employee.address,
    jobTitle: employee.jobTitle,
    birthday: employee.birthday,
    phone: employee.phone,
    vatNumber: employee.vatNumber,
    ssn: employee.ssn,
  };
};

export const formatWorkPermitDefaultVals = (selectedWorkPermit) => {
  if (!selectedWorkPermit) return {};

  return {
    name: selectedWorkPermit.name,
    expires_at: new Date(selectedWorkPermit.expiresAt),
    description: selectedWorkPermit.description,
    number: selectedWorkPermit.number,
    admitter: selectedWorkPermit.admitter,
  };
};

export const timeEntryQueryFetchable = ({siteIdFilter, dateRange, employeeId}) => {
  const startDate = dateRange.startDate?.toISOString();
  const endDate = dateRange.endDate?.toISOString();

  return {
    'filter[startDateBetween]': `${startDate},${endDate}`,
    'filter[siteId]': siteIdFilter,
    'filter[userId]': employeeId,
    groupByDaysAndSites: 'true',
  };
};
